// THIS COMPONENT USES HOOKS

import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';    // Allows to link to different views?
import { Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {Container, Jumbotron, Form, Button, Row, Col, Card, CardGroup, CardColumns, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import queryString from "query-string";
import fetch from './_AdminCustomFetch'
import FetchFailHandler from './_AdminFetchFailHandler'
import Scrollbar from 'react-scrollbars-custom'
import "../styles/style.css"
import emailValidator from "email-validator";
import { debounce } from 'lodash'

import cogoToast from 'cogo-toast';


export default function ArtworkDisapprovalForm(props){
    // State
    // Don't toss everything into the same state because the order of setState calls messes things up.
    const [disapprovalComment, setDisapprovalComment] = useState(props.disapprovalComment)
    const [fileID, setFileID] = useState(props.file)
    const [inputs, setInputs] = useState({
        newDisapprovalComment: ''
    })
    const [submitText, setSubmitText] = useState('Save and Close')
    const [submitButtonActive, setSubmitButtonActive] = useState(false)
    
    
    // ---------------------------------------- EFFECTS ----------------------------------------------//
    // Use effect hook
    useEffect(() => {
        // Called when props change
        if (props.file) {
            setFileID(props.file)
        }

        if (props.disapprovalComment) {
            setDisapprovalComment(props.disapprovalComment)
        }

    }, [props.file, props.disapprovalComment])


    // Use Effect hook for disapproval comment changing
    useEffect(() => {
        if (inputs.newDisapprovalComment) {
            setSubmitButtonActive(true)
        } else {
            setSubmitButtonActive(false)
        }
        
    }, [inputs.newDisapprovalComment])
    // ---------------------------------------- EFFECTS ----------------------------------------------//
    

    // ---------------------------------------- HANDLERS ----------------------------------------------//
    // Submit handler
    var onSubmit = async (event) => {
        // Perform fetch
        var fetchStatus = 0;
        console.log('Submitting... ' + fileID)
        await fetch('/api/file/modify/' + fileID, {
            method: 'POST',
            body: JSON.stringify({
                approvalComment: inputs.newDisapprovalComment,
                isReviewed: true,
                isPreApproved: false
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            fetchStatus = res.status
            return await res.json();
        }).then (async res => {
            if (fetchStatus === 200){
                cogoToast.success('Successfully saved message to file.')
                console.log('file after save: ' + JSON.stringify(res.file))
                return await props.onComplete();
            } else {
                cogoToast.error('Failed to save file.')
            }
        }).catch (err => {
            cogoToast.error("Couldn't save the file. Please refresh and try again. Error: " + err)
        })
        
    }

    // OnChange handler
    var handleFormInput = async (event) => {
        const { value, name } = event.target;
        // Use setinputs
        return await setInputs({
            ...inputs, 
            [event.target.name]: event.target.value
        })
    }

    // ---------------------------------------- HANDLERS ----------------------------------------------//


    // ---------------------------------------- RENDER ----------------------------------------------//
    var renderForm = () => {
        return (
                <Container fluid className="mx-0">
                    <h1 className="white-text pt-5" style={{fontSize: 4 + 'rem'}}><b>Disapprove Artwork</b></h1>
                    <h3 className="white-text">Give the advertiser a reason for your disapproval.</h3>
                    <Container fluid className ='my-5'>
                        
                        <Form className ='mt-5'>
                            <Form.Row>
                                <Form.Control className = 'inputField text-left square-corners blue-grey-text pr-5 mr-5'
                                    required
                                    type="textarea"
                                    placeholder={disapprovalComment ? disapprovalComment : 'Type in your reason for disapproval (required)'}
                                    name="newDisapprovalComment"
                                    onChange={e => handleFormInput(e)}
                                    value={inputs.newDisapprovalComment || ''}
                                />
                            </Form.Row>

                            <Button className="float-right my-5" disabled={!submitButtonActive} onClick={(e) => {setSubmitButtonActive(false); setSubmitText('Saving...'); onSubmit(e)}}>{submitText} <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} /></Button>
                            
                        
                    </Form>
                    </Container>
                </Container>

        )
    }

    // ---------------------------------------- RENDER ----------------------------------------------//


    // Return statement
    return (renderForm())

}


