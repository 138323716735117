// Modal Popup
// Pass content into this to be rendered inside the modal

// Usage: <ModalPopup content=content />
// Passes all props downstream to modal component

// Imports
// Import dependencies
import React, { Component, findDOMNode } from 'react';
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import fetch from './_AdminCustomFetch'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {Container, Form, Table, Jumbotron, Button, Modal, Row, Col, Card, CardGroup} from 'react-bootstrap';
import Scrollbar from 'react-scrollbars-custom'

import "../styles/style.css"


class ModalPopup extends React.Component {
    render() {
      return (
       
        <Modal 
          {...this.props}
          size=" _85vw"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="darkest-background-transparent _85vw"
        >
          <Container className="table-background-dark">
          <Modal.Header closeButton closeLabel="close" className="white-text no-border">
          </Modal.Header>
          <Modal.Body className="m-5">

            {this.props.content}
            
          </Modal.Body>
          <Modal.Footer className="no-border mb-5 mr-3 text-left">
            
          </Modal.Footer>
          </Container>
        </Modal>
      
      );
    }
  }

  // <Button className="primary-button ml-3 mt-5" onClick={this.props.onHide}>Done</Button>

  export default withRouter(ModalPopup)