// The login & Registration page

// Import dependencies
import React, { Component } from 'react';
import fetch from '../components/_AdminCustomFetch'

import Login from "../components/_AdminLogin";
import { withRouter, Link } from 'react-router-dom';
import {Container, Jumbotron, Button, Row, Col, Card, CardGroup, CardColumns, Table} from 'react-bootstrap';
import "../styles/style.css"

class LoginScreen extends Component {
// Create the required properties to display
    constructor(props) {
        super(props);
        this.state = ({
            loggedin: false,

        })
    }

    componentDidMount() {
    }



    render() {
        //     // return can only return a single element (one div)
        // 
        
        // Check if user is logged in
        if (this.state.loggedin){
            return(
                <div>
                    <h2>You're logged in!</h2>
                </div>
            )
        } else {
            return (
                <Container fluid className="full-height fullscreen-dark darkest-background">
                    <Row className='vertical-center pt-0'>
                        <Container>
                            <Row>
                                <Col>
                                    <h1 className='white-text py-3' style={{fontSize: 10 + 'rem'}}>Hello, Admin.</h1> 
                                    <h3 className='white-text'>Welcome to the CAASie billboard experience.</h3>
                                    <p className='blue-grey-text caption'>Feel free to log in and enjoy me. I do bite, however.</p>

                                    <Login />
                                    
                                </Col>
                            </Row>
                            
                        </Container>
                    </Row>
                
                </Container>
            )
        }
    }
}

export default withRouter(LoginScreen)

const loginRegPage = () => {
    // React knows the port that the backend server is on (Proxy in client package.json)
    return (
        <LoginScreen />
    )
}

//export default Home