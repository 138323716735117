// Imports
// Import dependencies
import React, { Component, PureComponent, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types'
import ReactDOM from "react-dom";
import fetch from './_AdminCustomFetch'
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import selectedOrgContext from '../components/_AdminOrganisationContext'
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from 'react-table'
import DatePicker from "react-datepicker";
import moment from 'moment'
import Cookies from 'js-cookie';
import NavBar from "../components/_AdminNavBar"
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle, faCircle, faExclamationTriangle, faTv } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import FetchFailHandler from '../components/_AdminFetchFailHandler'
import LoadingAnimation from './_AdminLoadingAnimation'
import { Transition, Spring, animated, template } from 'react-spring/renderprops'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


import "../styles/style.css"
import { minWidth } from '@material-ui/system';
import {
    XYPlot,
    XAxis,
    YAxis,
    ArcSeries,
    ChartLabel
  } from 'react-vis';
import { min } from 'date-fns';
  
const isMobile = !window.matchMedia("(min-width: 768px)").matches;

const PI = Math.PI;
  
const MARGIN = {
    left: 1, right: 1, top: 1, bottom: 1
};




// Displays the org name given an ID
export class OrgNameFromID extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            orgID: this.props.orgID,
            orgData: {}
        }
    }

    // On mount
    componentDidMount = async () => {
        this.getOrgName();
    }

    // On update
    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.orgID !== this.props.orgID) {
            this.getOrgName()
        }
    }

    // GET function
    getOrgName = async () => {
        var fetchStatus = 0;
        var org = await fetch('/api/organisation/' + this.props.orgID, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            return res.org
        }).catch(err => {
            console.log('Failed to fetch organisation details: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

        // Now set the state
        this.setState({
            orgData: org
        })
    }


    // Render
    render = () => {
        if (this.state.orgData && this.state.orgData.orgName) {
            return <p>{this.state.orgData.orgName}</p>
        } else if (!this.state.orgData && this.state.orgID) {
            return <LoadingAnimation />
        } else {
            return null
        }
        
    }
}



// Get the team name
export class TeamNameFromID extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamID: this.props.teamID,
            teamData: ''
        }
    }

    // On mount
    componentDidMount = async () => {
        this.getTeam();
    }

    // On update
    componentDidUpdate = async () => {

    }

    // GET function
    getTeam = async () => {
        var fetchStatus = 0;
        var team = await fetch('/api/team/' + this.state.teamID, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            return res.team
        }).catch(err => {
            console.log('Failed to fetch team details: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

        // Now set the state
        this.setState({
            teamData: team
        })
    }


    // Render
    render = () => {
        if (this.state.teamData) {
        return <p>{this.state.teamData.teamName}</p>
        } else if (!this.state.teamData && this.state.teamID) {
            return <LoadingAnimation />
        } else {
            return null
        }
        
    }
}

// Prop Types
OrgNameFromID.propTypes = {
    orgID: PropTypes.string.isRequired
}

TeamNameFromID.propTypes = {
    teamID: PropTypes.string.isRequired
}



// Converts a number to currency format ()
export function toCurrency(number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });

    return formatter.format(number);
}


// Taking an array of promises, returns the first promise that does not get rejected
// https://stackoverflow.com/questions/51160260/clean-way-to-wait-for-first-true-returned-by-promise
export function firstTrue(promises) {
    const newPromises = promises.map(p => new Promise(
        (resolve, reject) => p.then(v => v && resolve(v), reject)
    ));
    newPromises.push(Promise.all(promises).then(() => false));
    return Promise.race(newPromises);
}


// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

// remove duplicate objects from an array using a prop to compare
export async function removeDuplicateObjects(array, propNameToCompare) {
    var seen = {};
    // Remove duplicates... https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
    var noDuplicates = await Promise.all(array.filter(function(item) {
        var k = item[propNameToCompare];
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    }))
    return noDuplicates;
}

// remove duplicate strings from an array
export function removeDuplicateStrings(array) {
    var seen = {};
    // Remove duplicates... https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
    var noDuplicates = array.filter(function(item) {
        var k = item;
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
    return noDuplicates;
}

// Get average of non-zero elements
export function averageOfNonZeroElems(data) {
    const filtered = data.filter(item => item !== 0);
    const sum = filtered.reduce((a, b) => a + b, 0);
    const avg = sum / filtered.length;

    return avg
}


// Helper Function to rotate an array a given number of steps
// https://stackoverflow.com/questions/1985260/javascript-array-rotate/33451102#33451102
export function arrayRotate(arr, count) {
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));
    return arr;
  }

// ------------------------- MISC. --------------------------------------//