// The Home page of the app. Shows recents, quick access, etc.

// Import dependencies
import React, { Component, Suspense } from 'react';
import { Link, Redirect } from 'react-router-dom';    // Allows to link to different views?
import Sidebar from '../components/_AdminSidebar'
import FetchFailHandler from '../components/_AdminFetchFailHandler'
import { withRouter } from 'react-router-dom';
import NavBar from "../components/_AdminNavBar"
import fetch from '../components/_AdminCustomFetch'

import moment from 'moment'
// import CampaignTable from '../components/CampaignTable'
import ReactTable from 'react-table'
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar} from 'react-bootstrap';
import ModalPopup from '../components/_AdminModalPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle, faExclamationTriangle, faTv } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import LoadingAnimation from '../components/_AdminLoadingAnimation'
import selectedOrgContext from '../components/_AdminOrganisationContext'
import Scrollbar from "react-scrollbars-custom";
import queryString from "query-string";
import Joyride from 'react-joyride';
import FileTable from '../components/_AdminArtworkTable'
import ApprovedFileTable from '../components/_AdminApprovedArtworkTable'
import DisapprovedFileTable from '../components/_AdminDisapprovedArtworkTable'
import BoardTable from '../components/_AdminBoardTable'
import CampaignTable from '../components/_AdminCampaignTable'
import "../styles/style.css"

// import RecentCampaigns from '../components/RecentCampaigns'

class BoardScreen extends Component {
    static contextType = selectedOrgContext
    constructor(props, context) {
        super(props, context) 
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {
            // Artwork approval params
            shouldTablesUpdate: false,

            isLoaded: true,

          }   // end this.state
      }   // end constructor



    // ------------------------------------------ UTILITY FUNCTIONS ----------------------------------------------------

    
    // TODO: Put this in Utilities.js
      checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                            return true;
                        }
                    })
                } else {return false}
        }
    }


     // ------------------------------------------- UTILITY FUNCTIONS ---------------------------------------------------

      componentDidMount = async () => {
        await this.checkPreviousStateAndRestore();

      }


    // ------------------------------------------ HANDLER FUNCTIONS --------------------------------------------------



    // On artwork update (callback for the tables - when they change the files)
    onCampaignTableUpdate = () => {
        // Force state update/re-render of the tables
        this.setState({
            shouldTablesUpdate: true
        }, () => {
            // Force get new data -> sets shouldTablesUpdate = false
            console.log('shouldtablesupdate: ' + this.state.shouldTablesUpdate)
        })

        
    }




    // ------------------------------------------ HANDLER FUNCTIONS --------------------------------------------------

    // ------------------------------------------ RENDER FUNCTIONS ---------------------------------------------------


    // Render boards as a table
    renderCampaignTable= () => {
        if (this.state.isLoaded){
            return (
                <Container fluid className='mb-0'>
                    <CampaignTable onUpdate = {this.onCampaignTableUpdate} shouldUpdate = {this.state.shouldTablesUpdate}/>
                </Container>
            
            )
        } else {
            return (<LoadingAnimation />)
        }
    }



      render() {
        // Page content (set as a variable so that it can be the child of the sidebar)
        var content = (
            <Scrollbar className='customScroll' noScrollX>
                <NavBar />
      
                <Jumbotron fluid>
                    <Container fluid className='px-5'>
                        <h1 className='white-text'>Campaigns on <b>CAAS</b>ie.</h1> 
                        <h3 className='white-text'>
                        This is a list of all the campaigns on CAASie.
                        </h3>
                    </Container>
                </Jumbotron>
                
                    
                    {/* <Row className = 'spacer my-5'></Row> */}

                    <Container fluid className = "px-0 my-5 stepOne pale-background">
                        <Row className='px-5 '>
                        {this.renderCampaignTable()}
                        </Row>
                    </Container>


                </Scrollbar>)

          return(
            <>
                {content}
            </>
          )
      }




     // ------------------------------------------ RENDER FUNCTIONS ---------------------------------------------------     
     

}// end class

export default withRouter(BoardScreen)

