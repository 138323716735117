import React from "react";

 // Set the authorisation context
 const AuthContext = React.createContext({
     authToken: "Not set",
     setAuthToken: () => {},             // Empty function
     shouldRefreshToken: "",             // Should refresh flag.
     setAuthTokenRefresh: () => {}
 });   // Context so all child components can access this


export default AuthContext;