// Board owner's board table component

// Uses react-table to display a table of boards given an array of teams

// Usage: <BoardTable teamList=teamList parentOrg=parentOrg />

// Imports
// Import dependencies
import React, { Component, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import ReactDOM from "react-dom";
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import fetch from './_AdminCustomFetch'

import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';


import "react-datepicker/dist/react-datepicker.css";
import ReactTable from 'react-table'
import ManageBoard from './_AdminManageBoard'
// import NewBoard from '../components/NewBoard'
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, Modal, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar} from 'react-bootstrap';

import ModalPopup from './_AdminModalPopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert'; 
import LoadingAnimation from './_AdminLoadingAnimation'
import 'react-confirm-alert/src/react-confirm-alert.css';
import FetchFailHandler from './_AdminFetchFailHandler'
import AdminAddBoard from './_AdminAddBoard'
import {debounce, throttle} from 'lodash'
import "../styles/style.css"



// Styles
const Styles = {
    thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
},
    thumb: {
    display: 'inline-flex',
    borderRadius: 50,
    border: '2px solid #33ea6f',
    marginBottom: 8,
    marginRight: 8,
    padding: 4,
    boxSizing: 'border-box'
},
img: {
    display: 'block',
    width: 'auto',
    height: '100%'
}
}

class BoardOwnerBoardTable extends Component {
    constructor(props) {
        super(props)  
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {

            boards: [null],  // board IDs
            boardDataArray: [],  // board data (objects)
            boardImageURLs: [],     // Images of the boards (if they exist)

            // Checkboxes for management
            boardCheckboxes: [],

            // bulkActionsVisible
            bulkActionsVisible: false,

            // Modals
            newBoardContent: '',
            newBoardModal: {
                show: false
            },

            // Table
            boardTablePage: 1,
            boardTablePageSize: 10,
            cursorLocation: (10 * 1) - 10,        //(boardTablePage * boardTablePageSize) - boardTablePageSize,
            totalPages: -1,
            
            boardManagerContent: '',
            boardManagerModal: {
                show: false
            },

            isFetching: false,
            isLoaded: false,

            }   // end this.state

            this.throttledGetBoardByID =  throttle(this.getBoardByID, 100);  // end throttle


            this.getData();
    }   // end constructor

    // Restores previous state if it exists (passed in as props through react router)
    checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                            return true;
                        }
                    })
                } else {return false}
        }
    }

    // componentdidmount() function
    componentDidMount = () => {
        this.checkPreviousStateAndRestore();
        // Get the board data
        this.getData()
        
    }

    // COmponent did update
    componentDidUpdate = (prevProps, prevState) => {
        // If our input props change then re-load component
        if ( (this.props.shouldUpdate !== prevProps.shouldUpdate) && (this.props.shouldUpdate === true) ) {
            this.setState({isFetching: true}, () => {
                var fetchParams = {
                    pageSize: this.state.boardTablePageSize,
                    page: this.state.boardTablePage,
                    sorted: this.state.sorted ? this.state.sorted : [{'id' : 'boardname', 'desc' : true}],
                    filtered: []
                }
                this.fetchData(fetchParams, null)
            })
        }
    }
    // Populates the state
    getData = async () => {
        //console.log('teamList: ' + teamList)

        // Fetch the artworks from the database
        var fetchStatus = 0;
        await fetch('/api/boards/idlist', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(async res => {
            fetchStatus = res.status;
            return await res.json();
        }).then(async res => {
            // Manage all the boards
            var boards = res.boards;
            this.setState({
                boards: boards,
                isLoaded: true
            }, () => {

            })

        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

    }   // end getData


    // Get the table data page by page
    getPaginatedBoardsContent = async () => {
        var perPage = this.state.boardTablePageSize + 1;        
        var page = this.state.boardTablePage || 1
        var boardArray = this.state.boardDataArray;
        var fetchedBoardIDs = []
        var totalPages = Math.ceil(this.state.boards.length / perPage)

        console.log('Getting paginated content...' + perPage + ' ' + page)

        var cursorLocation = this.state.cursorLocation
        var endLimit = Math.min((perPage * page), this.state.boards.length);
        console.log('Getting end limit ...' + endLimit)

        // Fetch until boardArray is full

        
        var self = this;

        await this.setState({
            totalPages: totalPages
        })

        // Fetch boards
        await this.fetchBoards(cursorLocation, endLimit, boardArray).then(async (res) => {
            // DOES NOT WORK -> Recursively fill the board array if some of the boards do not meet conditions to be displayed.
            if (res.boardArray.length < perPage) {
                // Increment cursor and try this function again
                await this.setState({
                    cursorLocation: cursorLocation + res.boardArray.length,
                    totalPages: totalPages
                }, async () => {
                    return await this.getPaginatedBoardsContent()
                })
                
            } else {
                fetchedBoardIDs = res.fetchedBoardIDs
            }
        })



        // Now that we've awaited...
        // All boards have been pushed into array. Update state
        console.log('all boards pushed...' + boardArray.length)

        // If boardArray.length is lower than the number of slots available, load more boards.


        this.setState({
            boardDataArray: boardArray,
            isLoaded: true
        }, () => {
            console.log('boardDataArray: ' + JSON.stringify(this.state.boardDataArray))
            this.setState({
                boardCheckboxes: fetchedBoardIDs.reduce(
                    (options, option) => ({
                        ...options,
                        [option]: false
                    }),
                    {}
                    )
                })
        })  // end self.setstate

        
    }


    // Perform fetching of boards per react-table examples
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ isFetching: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.fetchBoards(
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
            console.log(res)
            this.setState({
            boardDataArray: res.rows,
            totalPages: Math.ceil(res.pages),
            isFetching: false
            });
        });
        }

    
        // Fetch boards given pageSize, page, sorted and filtered data
    fetchBoards = async  (pageSize, page, sorted, filtered) => {

        console.log('pageSize', JSON.stringify(pageSize))
        console.log('page', JSON.stringify(page))
        console.log('sorted', JSON.stringify(sorted[0]))
        console.log('filtered', JSON.stringify(filtered))

        console.log('sorted[0].desc: ' + sorted[0].desc)

        // Update the state with the table details
        this.setState({
            boardTablePage: page,
            boardTablePageSize: pageSize,
            sorted: sorted,
            filtered: filtered
        })

        return await fetch('/api/boards/uncached/?pageSize=' + pageSize + '&page=' + page + '&sortID=' + sorted[0].id + '&sortDesc=' + sorted[0].desc, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            // Res will contain unsorted boards ...?
            return res.json()
        }).then( res => {
            var rows = res.boards;
            var pages = res.totalBoards / pageSize;
            

            // Filter the data
            // TODO: Implement this (https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-server-side-data)
            var filteredData = rows;

            var response = {
                rows: rows,
                pages: pages
            }

            return response
        })
    }

    // Get boards (specifically for obtaining boards using throttle)
    getBoardByID = (board) => {
        console.log('board: ' + board)
        let boardFetchStatus = 0;
        return fetch('/api/boards/' + board.toString(), {
            method: 'GET'
        }).then(boardRes => {
            boardFetchStatus = boardRes.status
            return boardRes.json();
        }).catch(err => {
            console.log('fetch error: ' + err)
            //FetchFailHandler(self.props, self.state, boardFetchStatus)
        })  // end then
    }


    // Populate the board manager
    getBoardManagerModalContent = (board) => {
        // Load the board manager component
        return (
            <Container>
                <h2 className="white-text" style={{fontSize: 3 + 'rem'}}><b>Manage a board.</b></h2>
                <p className="white-text">Managing board: <b>{board._id.toString()}</b></p>
                <ManageBoard board={board._id.toString()} team={this.state.teamID}/>
            </Container>
        )
    }

    // To populate the new board modal
    getNewBoardModalContent = () => {
        // New board content for creating a new board
        return (
            <Container>
                <AdminAddBoard />
            </Container>
        );
    }

    


    // --------------------------- HANDLER FUNCTIONS -------------------------------- //

    // Handle the board quick toggles
    handleBoardActiveToggle = (event) => {
        event.preventDefault();
        const { checked, name } = event.target;
        console.log('event name: ' + name + ' checked: ' + checked)
        //var boardIndex = 


        let boardID = name;
        var fetchStatus = 0;
        fetch('/api/boards/modify/' + boardID,{
            method: 'POST',
            body: JSON.stringify({
                boardPaused: !checked   // TODO: introduce pausing of boards
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                console.log('toggle message: ' + res.message)
                // Toggle the checkbox/switch in the state
                var newBoardDataArray = this.state.boardDataArray;
                newBoardDataArray[ (this.state.boards.findIndex((elem) => {
                    return elem === boardID;
                })) ].boardPaused = Boolean(!checked);
                this.setState({
                    boardDataArray: newBoardDataArray
                })
            }).catch(err => {
                console.log('fetch error: ' + err)
                FetchFailHandler(this.props, this.state, fetchStatus)
            })

    }

// Handle board selection
    deleteSelectedBoards = () => {
        // Get the chosen boards and mark them as deleted?
    }


    // Handle table page change
    onPageChange = (newPageIndex) => {
        this.setState({
            boardTablePage: newPageIndex,
            cursorLocation: (this.state.boardTablePageSize * newPageIndex) - this.state.boardTablePageSize,
        }, () => {
            this.getPaginatedBoardsContent();
        })
    }

    // Handle page size change
    onPageSizeChange = (newPageSize, newPageIndex) => {
        this.setState({
            boardTablePage: newPageIndex,
            boardTablePageSize: newPageSize,
            cursorLocation: (newPageSize * newPageIndex) - newPageSize,
            totalPages: Math.ceil(this.state.boards.length / newPageSize)
        }, () => {
            this.getPaginatedBoardsContent();
        })
    }

    // Handle bulk actions on table
    handleBulkAction = (bulkActionEvent) => {
        //const { eventKey } = bulkActionEvent.target;
        console.log('bulkAction: ' + bulkActionEvent)
        console.log('selected: ' + JSON.stringify(this.state.boardCheckboxes))

        if (bulkActionEvent === 'delete'){
            // Make an API call to delete the campaign after the user confirms.
            confirmAlert({
                closeOnClickOutside: true,
                    customUI: ({ onClose }) => {
                    return (
                        <div className='modal-dialog modal-lg modal-dialog-centered _45vw'>
                            <Container className='modal-content my-5'>
                            <Container className='table-background-dark'>
                                <Modal.Header closeButton closeLabel="close" onHide={onClose} className="white-text no-border" />
                                <Container className='p-5 mt-3 mb-5 modal-body '> 
                                
                                    <h2 className='white-text' style={{"fontSize" : '3' + 'rem'}}><b>Delete</b> this board?</h2>
                                    <h4 className='white-text mb-5'>It cannot be undone. So, that's a bold move. But you do you.</h4>
                                    <Row>
                                        <Col sm={4} className='ml-auto text-right'>
                                            <Button className='text-left' onClick={onClose}>No, I'll keep it.</Button>
                                        </Col>
                                        <Col sm={3} className='text-left' className='text-right'>
                                            <Button className='text-left' variant="secondary"
                                                onClick={() => {
                                                    Object.keys(this.state.boardCheckboxes).forEach(board => {
                                                        console.log('board ' + board + ' selected?: ' + this.state.boardCheckboxes[board])
                                                            if (this.state.boardCheckboxes[board] === true)  {     // I.e. if that board is selected...
                                                            // delete the board by calling delete API
                                                            this.performDeletion(board)
                                                        }
                                                    })
                                                    onClose();
                                                }}
                                                >
                                                Yes, I'm sure.
                                            </Button>
                                        </Col>
                                    
                                    </Row>
                                </Container>
                            </Container>
                        </Container>
                        </div>
                    );
                    }
                })




        }
        
    }


    // Perform the fetch call to delete
    performDeletion = (boardIDToDelete) => {
        // Fetch api to delete board and then redirect to team dashboard
        var fetchStatus = 0;
        cogoToast.loading('Talking with my inner self...Don\'t judge me.').then(() => {
            fetch('/api/boards/delete/' + boardIDToDelete, {
                method: 'POST',
                body:  JSON.stringify({}),  // empty body
                headers: {
                    'Content-Type': 'application/json'
                    }
            }).then(res => {
                fetchStatus = 0;
                return res.json();
            }).then(res => {
                console.log('deleting campaign...');
                cogoToast.success('Successfully deleted campaign. Refresh this page.');
                this.getData();

            }).catch(err => {
                //console.log('update campaign error: ' + err)
                cogoToast.error('Failed to delete campaign. Error: ' + JSON.stringify(err));
                //FetchFailHandler(this.props, this.state, fetchStatus)
            })
        })
        
    
    }


        // Modals
        newBoardModalClose = () => {
            this.setState({ newBoardModal: {show: false }})
            // Update the page...
            this.getData();
            
        };
    
        boardManageModalClose = () => {
            this.setState({ boardManagerModal: {show: false}});
            // Update the page...
            this.getData();
        }
    

    // Handle checkboxes
    handleBoardCheckboxChange = changeEvent => {
        const { checked, name } = changeEvent.target;
        console.log('name: ' + JSON.stringify(name))
        console.log('checked: ' + JSON.stringify(checked))
    
        this.setState(prevState => ({
          boardCheckboxes: {
            ...prevState.boardCheckboxes,
            [name]: !prevState.boardCheckboxes[name]
          }
        }), () => {
            // Count checkboxes and set a variable to make bulk actions buttons visible
            var checkboxesSelected = 0;
            var loopIterator = 0;
            Object.keys(this.state.boardCheckboxes).forEach(board => {
                //console.log('checkbox: ' + JSON.stringify( this.state.boardCheckboxes[board]))
                if(this.state.boardCheckboxes[board]) { // Selected
                //console.log('at least 1 checkbox selected')
                checkboxesSelected = checkboxesSelected + 1;
                }
                loopIterator = loopIterator + 1;
                //console.log('loopIterator: ' + loopIterator)
                //console.log('this.state.boardCheckboxes.length: ' + Object.keys(this.state.boardCheckboxes).length)
                
                if (loopIterator === Object.keys(this.state.boardCheckboxes).length){
                    // done iterating. Count number of checkboxes
                    console.log('done iterating. CheckboxesSelected:' + checkboxesSelected)
                    if (checkboxesSelected > 0){
                        // At least 1 checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                            bulkActionsVisible: true
                        }, () => {
                        console.log('bulk actions visible')
                    })
                    } else {
                        // No checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                        bulkActionsVisible: false
                    }, () => {
                        console.log('bulk actions invisible')
                    })
                    }
                }
            });
        });
      };




    // --------------------------- HANDLER FUNCTIONS -------------------------------- //


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //

    // Display bulk actions at the top of the table (e.g. delete all, pause all etc.)
    displayBulkActions = () => {
        if (this.state.bulkActionsVisible){
            return (
                <ButtonGroup aria-label="Basic example" className='float-right'>
                <DropdownButton onSelect={(e) => this.handleBulkAction(e)} as={ButtonGroup} title="Bulk Actions " id="bg-nested-dropdown" className='ml-auto mr-2'>
                    <Dropdown.Item eventKey="delete">Delete Selected</Dropdown.Item>
                    <Dropdown.Item eventKey="pause">Pause Selected</Dropdown.Item>
                </DropdownButton>
                <Button key = 'new board'
                    className="float-right align-self-end ml-auto"
                    onClick={(e) => {
                        //e.preventDefault();
                        var newContent = this.getNewBoardModalContent()
                        this.setState({ 
                            //isLoaded: false,
                            newBoardContent: newContent,
                            newBoardModal: {show: true}
                        }, () => {
                            console.log('set new content...' + JSON.stringify(newContent))
                            //this.setState({isLoaded: true})
                        })
                     }}>
                    Create New Board <FontAwesomeIcon className="ml-3" icon={faPlus} />
                </Button> 
                </ButtonGroup>
            )
        } else {
            return (
                <Button className='float-right' onClick={(e) => {
                    //e.preventDefault();
                    var newContent = this.getNewBoardModalContent()
                    this.setState({ 
                        //isLoaded: false,
                        newBoardContent: newContent,
                        newBoardModal: {show: true}
                    }, () => {
                        console.log('set new content...' + JSON.stringify(newContent))
                        //this.setState({isLoaded: true})
                    })
                 }}>Add a new board <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} /></Button>
                )
        }
    }

    // Render boards as a table
    renderBoardTableData = () => {
        if (this.state.isLoaded){
            // Use react-table to render boardTableData
            // Tables to render with reactTable
            console.log('boardDataArray: ' + JSON.stringify(this.state.boardDataArray))
            const boardTableRows = this.state.boardDataArray;
            const boardTableCols = [
                {
                    Header: 'Select',
                    id: 'select',
                    Cell: (row) => (
                        <Form.Check 
                            custom = 'true'
                            type='checkbox'
                            checked={this.state.boardCheckboxes[row.original._id]}
                            onChange={this.handleBoardCheckboxChange}
                            name = {row.original._id}
                            key = {row.original._id}
                            id={row.original._id}
                            label=''
                        />
                    )
                },
                {
                    Header: 'Board Name',
                    accessor: 'boardname'
                },
                {
                    Header: 'Country',
                    accessor: 'boardloc.country'
                },
                {
                    Header: 'City',
                    accessor: 'boardloc.city'
                },
                {
                    Header: 'Media Owner',
                    accessor: 'publisher_name'

                },
                {
                    Header: 'Type',
                    accessor: 'venueType'
                    
                },
                {
                    Header: 'Image',
                    accessor: 'boardImg',
                    Cell: (row, index) => (
                        <>
                        {row.original.boardFile ? <BoardImage fileID = {row.original.boardFile} /> : '' || row.original.boardImgURL ? <BoardImage boardImgURL = {row.original.boardImgURL} /> : ''}

                        </>
                    )
                },
                {
                    Header: 'Is Deleted',
                    accessor: 'isDeleted',
                    Cell: (row, index) => (
                        <div>
                            <p className={row.original.isDeleted ? 'foreground-error bold-text' : ''}>{row.original.isDeleted ? 'Deleted' : 'Normal'}</p>
                        </div>
                    )
                    
                },
                {
                    Header: 'Manage',
                    accessor: 'manage',
                    Cell: (row, index) =>(   // Put the manage button in here.
                        <div>
                           <Button className = 'text-button' key = {row.original._id} onClick={(e) => {
                                        //e.preventDefault();
                                        var newContent = this.getBoardManagerModalContent(row.original)
                                        this.setState({ 
                                            //isLoaded: false,
                                            boardManagerContent: newContent,
                                            boardManagerModal: {show: true}
                                        }, () => {
                                            console.log('set new content...' + JSON.stringify(newContent))
                                            //this.setState({isLoaded: true})
                                        })
                                    }}>Manage Board <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} /></Button>
                        </div>
                    )
                }
            ]

            return (
                <Container fluid>
                    <ReactTable
                        manual
                        data={boardTableRows}
                        pages={this.state.totalPages}
                        columns={boardTableCols}
                        defaultPageSize={this.state.boardTablePageSize}
                        loading={this.state.isFetching}
                        // onPageChange= {this.onPageChange}
                        // onPageSizeChange= {this.onPageSizeChange}
                        onFetchData = {this.fetchData}
                        minRows = {this.state.boardDataArray.length > 0 ? 0 : 3}
                        noDataText = "There are no boards to show."
                        defaultSorted={[
                            {
                              id: "boardname",
                              desc: true
                            }
                          ]}
                    />
                    <ModalPopup
                        show={this.state.newBoardModal.show}
                        onHide={this.newBoardModalClose}
                        content = {this.state.newBoardContent}
                    />
                    <ModalPopup
                        show={this.state.boardManagerModal.show}
                        onHide={this.boardManageModalClose}
                        content = {this.state.boardManagerContent}
                    />
                </Container>
            
            )
        } else {
            return (<LoadingAnimation />)
        }
    }

        // Render board table 
        render = () => {
            return(
                <Container fluid className='mb-0 mx-0 px-0'>
                <Row className='align-items-center mb-5 ml-auto'>
                    <Col md={6} lg={8}>
                        <h3 className="grey-text noto-sans my-auto"><b>ALL THE BOARDS ON CAASIE</b></h3>
                        <p>Manage the settings of all the boards here.</p>
                    </Col>
                    <Col>
                        {this.displayBulkActions()}
                    </Col>
                </Row>
                <Row>
                    {this.renderBoardTableData()}
                </Row>
                <Row className='align-items-center pb-3 ml-auto'>
                <Col className='float-right'>
                <Button className='float-right' disabled onClick={(e) => {
                                        //e.preventDefault();
                                        var newContent = this.getNewBoardModalContent()
                                        this.setState({ 
                                            //isLoaded: false,
                                            newBoardContent: newContent,
                                            newBoardModal: {show: true}
                                        }, () => {
                                            console.log('set new content...' + JSON.stringify(newContent))
                                            //this.setState({isLoaded: true})
                                        })
                                     }}>Add a new board <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                </Button>
                </Col>
                <Row className='spacer my-5'></Row>
                </Row>
                </Container>
                
            )
        }


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //    
}   // end class



// Board image component. Takes props of fileID
class BoardImage extends Component {
    constructor(props) {
        super(props)  
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {
            fileID: this.props.fileID,
            boardImgURL: ''
        }
    }

    // On mount
    componentDidMount = async () => {
        if (this.props.fileID) {
            var fetchStatus = 0;
            await fetch('/api/file/' + this.props.fileID, {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json'
                }
            }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                if (res.file) {
                    this.setState({
                        boardImgURL: res.file.fileURL
                    })
                }
            })
        } else if (this.props.boardImgURL) {            // Image was provided directly. No need to fetch.
            this.setState({
                boardImgURL: this.props.boardImgURL
            })
        }
    }


    // Render
    render = () => {
        if (this.state.boardImgURL) {
            return (
                <Container>
                    <Col className="align-self-center magnify-hover mx-auto">
                        <div style={Styles.thumb}>
                            <img src={this.state.boardImgURL} roundedCircle style={{width: '50px', height:'50px', objectFit: 'cover', borderRadius: '50px'}}/>
                        </div>
                    </Col>
                </Container>
            )  
        } else {
            return <LoadingAnimation />
        }
        
    }
}

export default withRouter(BoardOwnerBoardTable)