// Navbar
// User interface component for navigation (includes push back and search)

// 
// Import dependencies
import React, { Component } from 'react';
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import FetchFailHandler from './_AdminFetchFailHandler'
import fetch from './_AdminCustomFetch'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {Container, Navbar, Nav, FormControl, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, InputGroup, DropdownButton, Dropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faChevronCircleRight, faUser, faBell, faBars, faDoorOpen, faSearch} from '@fortawesome/free-solid-svg-icons'
import LoadingAnimation from './_AdminLoadingAnimation'
import cogoToast from 'cogo-toast'
import selectedOrgContext from './_AdminOrganisationContext'
import { Transition, Spring,config, animated } from 'react-spring/renderprops'
import sidebarCollapseContext from './_AdminSidebarCollapseContext'
import "../styles/style.css"



class NavBarComponent extends Component {
    static contextType = sidebarCollapseContext
    constructor(props){
        super(props)

        this.state = {
            // State variables
            username: '',

            // Other variables
            isLoaded: false,
            

        }   // end this.state
    }

    // Restore previous state if exists
    checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    //console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        ////console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        //console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            this.setState(newState, () => {
                                //console.log('After setting state: ' + JSON.stringify(this.state))
                            ;});
                            return true;
                        }
                    })
                } else {return false}
        }
    } 

    componentDidMount = () => {
        // Get previously held state
        this.checkPreviousStateAndRestore();
        this.getData();

        console.log('sidebarCollapseContext: ' + JSON.stringify(this.context))

    }

    getData = () => {
        // Get user's data
         // This will work if the user is logged in.
         var fetchStatus = 0;
         fetch('/api/user/',      // probably should be renamed 'user'
         {
             method: 'GET',
         })
         .then(res => {
             fetchStatus = res.status;
             return res.json();
         })        // From express /profile GET route
         .then(res => {
             this.setState({
                 username: res.username,
                 firstName: res.firstName,
                 lastName: res.lastName,
                 isLoaded: true
             }, () => {
                 //console.log('loaded user info');
             }) // end setState
         }).catch(err => {
            //console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })
    }


    // Render the navbar
    render () {
        // Links to the profile
        const profileLinks = (
            <a className="white-text" href={'/profile/'}><FontAwesomeIcon className="foreground-primary"  style={{fontSize: 1.0 + 'rem'}} icon={faUser} /></a>
        )
        if (this.state.isLoaded){
        return (
            
                    <Spring
                        from={{opacity:0, background:"#012f38"}}
                        to={{ opacity:1}}
                        config = { config.gentle }
                        // reset = {this.state.isLoaded}
                        native
                        >
                        {props => 
                        <animated.div style={props}>
                            <Container fluid  className= "px-2 py-0 pt-2 dark-background">
                                <Navbar className="dark-background" variant="dark">
                                <Navbar.Brand href="#home"></Navbar.Brand>
                                <Form inline>
                                {/* <FormControl type="text" placeholder="Search (Coming soon)" className="mr-sm-2 inputField text-left square-corners d-inline-block" /> */}
                                <sidebarCollapseContext.Consumer>
                                { (sidebarCollapsed, toggleSidebar) => (
                                    <Button className='tab ml-0' active = {this.context.sidebarCollapsed} onClick={(e) => this.context.toggleSidebar(e)}><FontAwesomeIcon className="foreground-primary" icon={faBars} /> </Button>
                                    )
                                }
                                </sidebarCollapseContext.Consumer>
                                </Form>
                                <Nav className="mr-auto">

                                </Nav>
                                <Form inline className='p-2'>
                                <Button className="text-button-white-small" disabled onClick={event => {/* Do nothing */}}>COMING SOON <FontAwesomeIcon className="ml-3 foreground-primary"  style={{fontSize: 1.0 + 'rem'}} icon={faBell} /> </Button>
                                
                                <Dropdown >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className='text-button-white'>
                                        {this.state.firstName + ' ' + (this.state.lastName ? this.state.lastName : 'Nameless' )} <FontAwesomeIcon className="ml-3 foreground-primary"  style={{fontSize: 1.0 + 'rem'}} icon={faUser} />
                                    </Dropdown.Toggle>

                                
                                    <Dropdown.Menu className='w-100 no-border darkest-background'>
                                        <Dropdown.Item className='white-link' as={Link} to={'/profile'}>Your Profile</Dropdown.Item>
                                        <Dropdown.Item  className='white-link' onClick={event => {
                                            var fetchStatus = 0;
                                            cogoToast.loading('Logging you out and forgetting about you...').then(() => {
                                                fetch('/api/login/logout', {
                                                    method: 'GET',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    }
                                                }).then(res => {
                                                    fetchStatus = res.status;
                                                    return res.json();
                                                }).then(res => {
                                                    if (fetchStatus === 200) {
                                                        cogoToast.success('Done. Goodbye.')
                                                        return window.location.assign('/');     // forces reload
                                                    } else {
                                                        // If not 200 (could be 400), try pushing to login anyway
                                                        return window.location.assign('/');
                                                    }
                                                }).catch(err => {
                                                    //console.log('fetch error: ' + err)
                                                    FetchFailHandler(this.props, this.state, fetchStatus)
                                                })
                                            })
                                        }}>Log out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                </Form>
                
                                </Navbar>
                            </Container>
                        </animated.div>
                }
                </Spring>
                
        )
        } else {
            return (
                <Container fluid  className= "px-2 py-2 dark-background">
                    <Navbar className="dark-background" variant="dark">
                        <Col className='mx-auto'>
                            <LoadingAnimation className='mx-auto'/>
                        </Col>
                    </Navbar>
                </Container>)
        }
    }

}

export default withRouter(NavBarComponent)
