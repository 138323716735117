// WALLET SUNDIAL

// Shows the current funds available in the wallet. For use in the sidebar component
// Usage: <ImpressionsGraph wallet=wallet totalFunds = totalFunds>

import React from 'react';
import fetch from './_AdminCustomFetch'
import {curveCatmullRom} from 'd3-shape';
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import LoadingAnimation from './_AdminLoadingAnimation'


import "../styles/style.css"


import {
  XYPlot,
  XAxis,
  YAxis,
  ArcSeries,
  ChartLabel
} from 'react-vis';

const PI = Math.PI;

const MARGIN = {
    left: 0, right: 0, top: 0, bottom: 0
  };
  

class WalletSundial extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        // ReactVis variables
        useCanvas: false,
  
        // CAASie variables
        wallet: [],
        barColor: '#3bd62b',
        totalFunds: 0, // The funds added previously, of which wallet.fundsAvailable is remaining

  
        isLoaded: false
  
      
      };
    }

    componentDidMount = () => {
        // Set the state
        this.setState({
            wallet: this.props.wallet,
            totalFunds: this.props.totalFunds,
            isLoaded: true
        }, ()=> {
            //console.log('totalFunds: ' + JSON.stringify(this.state.totalFunds))
            //console.log('angle: ' + (Number(this.state.wallet.fundsAvailable) / Number(this.state.totalFunds) ) * 2 * PI)

            // Set bar color based on % of funds left
            if (Number(this.state.wallet.fundsAvailable)/Number(this.state.totalFunds) < 0.25) {
                this.setState({
                    barColor: '#fd6567'
                })
            }
        })
    }

    render = () => {
        if (this.state.isLoaded) {
            return (
                <XYPlot
                xDomain={[-3, 3]}
                yDomain={[-3, 3]}
                width={50}
                getAngle={d => d.fundsAvailable}
                getAngle0={d => 0}
                height={50}
                margin={MARGIN}
                >

                <ArcSeries
                    animation={{
                    damping: 9,
                    stiffness: 300
                    }}
                    colorType="literal"
                    radiusDomain={[0, 3]}
                    data={[
                    {fundsAvailable: (Number(this.state.wallet.fundsAvailable) / Number(this.state.totalFunds) ) * 2 * PI, radius0: 2.5, radius: 3, color: this.state.barColor},
                    ]}
                />
                </XYPlot>
            );
        } else {
            return (<LoadingAnimation />)
        }
    }
}   // end component


export default withRouter(WalletSundial)