// ARTWORK TABLE FOR ADMINS

// Uses react-table to display a table of files (artworks), sorted by unapproved, etc.

// Usage: <ArtworkTable onUpdate = function() shouldUpdate = bool/>

// Imports
// Import dependencies
import React, { Component, PureComponent, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import fetch from './_AdminCustomFetch'
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import selectedOrgContext from '../components/_AdminOrganisationContext'
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DatePicker from "react-datepicker";
import moment from 'moment'
import Cookies from 'js-cookie';
import NavBar from "../components/_AdminNavBar"
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle, faTrash, faCheckCircle, faEnvelope, faEnvelopeOpen, faExternalLinkAlt, faFilter } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import FetchFailHandler from '../components/_AdminFetchFailHandler'
import LoadingAnimation from './_AdminLoadingAnimation'
import ArtworkDisapprovalForm from './_AdminArtworkDisapprovalForm'
import ModalPopup from './_AdminModalPopup'
import {confirmAlert} from 'react-confirm-alert'
import _ from 'lodash'
import {OrgNameFromID, TeamNameFromID, removeDuplicateObjects} from '../components/_AdminUtils'
import { Transition, Spring, animated, template } from 'react-spring/renderprops'


import "../styles/style.css"
import { minWidth } from '@material-ui/system';
import {
    XYPlot,
    XAxis,
    YAxis,
    ArcSeries,
    ChartLabel
  } from 'react-vis';
import { min } from 'date-fns';
  
  const PI = Math.PI;
  
  const MARGIN = {
      left: 1, right: 1, top: 1, bottom: 1
    };
    

class FileTable extends Component {
    static contextType = selectedOrgContext;    // Using context outside render -> not working yet
    constructor(props) {
        super(props)  
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {
            files: [],
            fileDataArray: [],
            totalFiles: 0,

            // Checkboxes for bulk select
            fileCheckboxes: [],

            // Table
            fileTablePage: 1,
            fileTablePageSize: 5,
            cursorLocation: (5 * 1) - 5,        //(artworkTablePage * artworkTablePageSize) - artworkTablePageSize,
            totalPages: -1,
            sorted: [{}],
            filtered: [],
            isFetching: false,


            // Filters
            filters: {
                hasAdID: {
                    active: false,
                },
                isApproved: {
                    active: false
                },
                isRejected: {
                    active: false,
                },
                hasSSPID: {
                    active: false
                },
                missingSSPID: {
                    active: false
                }
            },

            // Modals
            disapprovalModal: {show: false},
            disapprovalModalContent: '',

            // bulkActionsVisible
            bulkActionsVisible: false,

            isLoaded: false,

            }   // end this.state


    }   // end constructor


    // ----------------------------------- DEFAULT FUNCTIONS -------------------------------------- //


    // componentdidmount() function
    componentDidMount = () => {
        // this.checkPreviousStateAndRestore();
        // Get the team data
        this.getData()
        
    }

    // COmponent did update
    componentDidUpdate = (prevProps, prevState) => {
        // If our input props change then re-load component
        if ( (this.props.shouldUpdate !== prevProps.shouldUpdate) && (this.props.shouldUpdate === true) && (prevProps !== this.props) ) {
            console.log('component updating')
            this.setState({isFetching: true}, () => {
                var fetchParams = {
                    pageSize: this.state.fileTablePageSize,
                    page: this.state.fileTablePage,
                    sorted: this.state.sorted ? this.state.sorted : [{'id' : 'fileName', 'desc' : true}],
                    filtered: []
                }
                this.fetchData(fetchParams, null)
            })
        }

        if (this.state.filters !== prevState.filters) {
            this.setState({isFetching: true}, () => {
                var fetchParams = {
                    pageSize: this.state.fileTablePageSize,
                    page: 0,
                    sorted: this.state.sorted ? this.state.sorted : [{'id' : 'fileName', 'desc' : true}],
                    filtered: []
                }
                this.fetchData(fetchParams, null)
            })
        }
    }


    // ----------------------------------- DEFAULT FUNCTIONS -------------------------------------- //


    // ----------------------------------- UTILITY FUNCTIONS -------------------------------------- //

    // Restores previous state if it exists (passed in as props through react router)
    checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            //this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                            return true;
                        }
                    })
                } else {return false}
        }
    }


    // ----------------------------------- UTILITY FUNCTIONS -------------------------------------- //




    // ----------------------------------- GET FUNCTIONS -------------------------------------- //




    // Fetch files given pageSize, page, sorted and filtered data
    fetchFiles = async  (pageSize, page, sorted, filtered) => {

        console.log('pageSize', JSON.stringify(pageSize))
        console.log('page', JSON.stringify(page))
        console.log('sorted', JSON.stringify(sorted[0]))
        console.log('filtered', JSON.stringify(filtered))

        console.log('sorted[0].desc: ' + sorted[0].desc)

        // Update the state with the table details
        this.setState({
            fileTablePage: page,
            fileTablePageSize: pageSize,
            sorted: sorted,
            filtered: filtered
        })

        var fetchStatus = 0;
        return await fetch('/api/file/artworks/?pageSize=' + pageSize + 
        '&page=' + page + '&sortID=' + sorted[0].id + 
        '&sortDesc=' + sorted[0].desc + 
        '&approvedOnly=' + this.state.filters.isApproved.active +
        '&disapprovedOnly=' + this.state.filters.isRejected.active +
        '&hasSSPID=' + this.state.filters.hasSSPID.active +
        '&missingSSPID=' + this.state.filters.missingSSPID.active +
        '&hasAdID=' + this.state.filters.hasAdID.active
        , {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            // Res will contain unsorted files ...?
            fetchStatus = res.status;
            return res.json()
        }).then( res => {
            var rows = res.files;
            var pages = res.totalFiles / pageSize;

            console.log('got files', rows)
            

            // Filter the data
            // TODO: Implement this (https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-server-side-data)
            var filteredData = rows;

            var response = {
                rows: rows,
                pages: pages
            }

            return response
        }).catch(err => {
            console.log('failed to fetch artworks:' , err) 
            FetchFailHandler(this.props, this.state, fetchStatus)
        })
    }


    // Perform fetching of files per react-table examples
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ isFetching: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.fetchFiles(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered
        ).then(res => {
          // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
          console.log(res)
          this.setState({
            fileDataArray: res ? res.rows : [],
            totalPages: res ? Math.ceil(res.pages) : 0,
            isFetching: false
          });
        });
      }


    // Get file (specifically for obtaining file using throttle)
    getFileByID = (file) => {
        console.log('file: ' + file)
        let fileFetchStatus = 0;
        return fetch('/api/file/' + file.toString(), {
            method: 'GET'
        }).then(fileRes => {
            fileFetchStatus = fileRes.status
            return fileRes.json();
        }).catch(err => {
            console.log('fetch error: ' + err)
        })  // end then
    }


    // Populates the state
    getData = async () => {
        //console.log('teamList: ' + teamList)

        // Fetch the artworks from the database
        var fetchStatus = 0;
        await fetch('/api/file/artworks/idlist', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(async res => {
            fetchStatus = res.status;
            return await res.json();
        }).then(async res => {
            // Manage all the files
            var files = res.files;
            this.setState({
                files: files,
                isLoaded: true
            }, () => {
                // this.getPaginatedFilesContent();
            })

        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

    }   // end getData


    // Get team name from ID
    getTeamNameFromID = (teamID) => {
        //console.log('looking for: ' + teamID)
        //console.log('teamDataArray: ' + JSON.stringify(this.state.teamDataArray))
        var team = this.state.teamDataArray.find(elem => {
            return (elem._id === teamID)
        })

        if (typeof (team) !== 'undefined'){
            return team.teamName;
        }
    }

    // Populate the board manager
    getDisapprovalModalContent = (file) => {
        // Load the board manager component
        return (
            <Container>
            <ArtworkDisapprovalForm file={file._id.toString()} onComplete={this.disapprovalModalClose}/>
            </Container>
        )
    }

    // ----------------------------------- GET FUNCTIONS -------------------------------------- //

    // --------------------------- HANDLER FUNCTIONS -------------------------------- //


    // Handle the campaign quick toggles
    handleUnreadToggle = async(event) => {
        event.preventDefault();
        const { name } = event.currentTarget;
        console.log('event name: ' + name)
        //var campaignIndex = 
        
        // Toggle the campaign's paused status in the state, then perform fetch to toggle campaign paused in DB
        let fileID = name;
        
        var newFileDataArray = this.state.fileDataArray;
        var index = await (this.state.fileDataArray.findIndex((elem) => {
            return elem._id === fileID;
        }))

        if (index !== -1)  {
            newFileDataArray[index].isReviewed = !this.state.fileDataArray[index].isReviewed
        } else {
            return
        }

        
        this.setState({
            fileDataArray: newFileDataArray
        }, () => {       
            var fetchStatus = 0;
            fetch('/api/file/modify/' + fileID,{
                method: 'POST',
                body: JSON.stringify({
                    isReviewed: newFileDataArray[index].isReviewed
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                console.log('toggle message: ' + res.message)
                cogoToast.success('Successfully toggled file read status.')
                // Update the state
                this.setState({state: this.state})

            }).catch(err => {
                console.log('fetch error: ' + err)
                cogoToast.error('Failed to toggle file read status.')
                FetchFailHandler(this.props, this.state, fetchStatus)
            })

        })
    }


    // Handle bulk actions on table
    handleBulkAction = (bulkActionEvent) => {
        //const { eventKey } = bulkActionEvent.target;
        //console.log('bulkAction: ' + bulkActionEvent)
        //console.log('selected: ' + JSON.stringify(this.state.campaignCheckboxes))

        if (bulkActionEvent === 'delete'){
            cogoToast.loading('Removing the selected campaigns...').then(() => {
                Object.keys(this.state.fileCheckboxes).forEach(file => {
                    console.log('file ' + file + ' selected?: ' + this.state.fileCheckboxes[file])
                        if (this.state.fileCheckboxes[file] === true)  {     // I.e. if that campaign is selected...
                        // delete the campaign by calling delete API
                        var fetchStatus = 0;
                        fetch('/api/file/delete/' + file, {   
                            method: 'POST',
                            body: JSON.stringify({ }), // empty body 
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            // return JSON format
                            fetchStatus = res.status;
                            return res.json();
                        }).then(res => {
                            console.log('deletion response: ' + JSON.stringify(res))
                            // Remove the listing from  the state
                            var fileIndex = this.state.files.findIndex(function (fileToCompare) {
                                if (fileToCompare === file){
                                    return file;
                                }
                            })
    
                            if (typeof fileIndex !== 'undefined') {           // If this variable exists, splice that variable in campaignBoards
                                var newFiles = this.state.files;
                                newFiles.splice(fileIndex, 1);
                                this.setState({
                                    files: newFiles
                                }, () => {
                                    this.getData();
                                    console.log('removed selected files. New: ' + JSON.stringify(this.state.files))
                                    cogoToast.success('Removed your files! Refresh the page if nothing has happened.')
                                })
                            }
    
                            
                        }).catch(err => {
                            console.log('fetch error: ' + err)
                            FetchFailHandler(this.props, this.state, fetchStatus)
                        })
                    }
                })
            })
            
        }
        
    }

    // Handle checkboxes
    handleFileCheckboxChange = changeEvent => {
        const { checked, name } = changeEvent.target;
        //console.log('name: ' + JSON.stringify(name))
        //console.log('checked: ' + JSON.stringify(checked))
    
        this.setState(prevState => ({
          fileCheckboxes: {
            ...prevState.fileCheckboxes,
            [name]: !prevState.fileCheckboxes[name]
          }
        }), () => {
            // Count checkboxes and set a variable to make bulk actions buttons visible
            var checkboxesSelected = 0;
            var loopIterator = 0;
            Object.keys(this.state.fileCheckboxes).forEach(file => {
                //console.log('checkbox: ' + JSON.stringify( this.state.boardCheckboxes[board]))
                if(this.state.fileCheckboxes[file]) { // Selected
                //console.log('at least 1 checkbox selected')
                checkboxesSelected = checkboxesSelected + 1;
                }
                loopIterator = loopIterator + 1;
                //console.log('loopIterator: ' + loopIterator)
                //console.log('this.state.boardCheckboxes.length: ' + Object.keys(this.state.boardCheckboxes).length)
                
                if (loopIterator === Object.keys(this.state.fileCheckboxes).length){
                    // done iterating. Count number of checkboxes
                    //console.log('done iterating. CheckboxesSelected:' + checkboxesSelected)
                    if (checkboxesSelected > 0){
                        // At least 1 checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                            bulkActionsVisible: true
                        }, () => {
                        console.log('bulk actions visible')
                    })
                    } else {
                        // No checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                        bulkActionsVisible: false
                    }, () => {
                        console.log('bulk actions invisible')
                    })
                    }
                }
            });
        });
      };



    // Handle artwork approval (confirm the action)
    handleArtworkApprovalButton = (file) => {
        // Make an API call to delete the user after the user confirms.
        confirmAlert({
        closeOnClickOutside: true,
            customUI: ({ onClose }) => {
            return (
                <div className='modal-dialog modal-lg modal-dialog-centered _45vw'>
                    <Container className='modal-content my-5'>
                    <Container className='table-background-dark'>
                        <Modal.Header closeButton closeLabel="close" onHide={onClose} className="white-text no-border" />
                        <Container className='p-5 mt-3 mb-5 modal-body '> 
                        
                            <h2 className='white-text' style={{"fontSize" : '3' + 'rem'}}><b>Approve</b> this artwork?</h2>
                            <h4 className='white-text mb-5'>Are you sure it's not a dickpic?</h4>
                            <Row>
                                <Col sm={4} className='ml-auto text-right'>
                                    <Button className='text-left' onClick={onClose}>No, stop.</Button>
                                </Col>
                                <Col sm={3} className='text-left'>
                                    <Button className='text-left' variant="secondary"
                                        onClick={() => {
                                            this.performArtworkApproval(file._id);
                                            onClose();
                                        }}
                                        >
                                        Yes, do it.
                                    </Button>
                                </Col>
                            
                            </Row>
                        </Container>
                    </Container>
                </Container>
                </div>
            );
            }
        })
    }   // end handleDeleteProfileButton


    // Perform the actual artwork approval
    performArtworkApproval = async(fileID) => {
        // Perform fetch
        var fetchStatus = 0;
        console.log('Submitting... ' + fileID)
        await fetch('/api/file/modify/' + fileID, {
            method: 'POST',
            body: JSON.stringify({
                isPreApproved: true,
                isReviewed: true
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            fetchStatus = res.status
            return await res.json();
        }).then (async res => {
            if (fetchStatus === 200){
                cogoToast.success('Successfully saved message to file.')
                console.log('file after save: ' + JSON.stringify(res.file))
                // Update the page...
                this.getData();

                var file = res.file;
                // New files (post creation of admin panel) have adID if they are an ad
                // var adID = file.adID ? file.adID : ''

                // New files no longer have ad IDs as they can be associated to multiple ads.
                var ads =  await fetch('/api/ads/byartwork/' + file._id, {
                    method: 'GET',
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                }).then(res => {
                    return res.json()
                }).then(res => {
                    return res.ads
                }).catch(err => {
                    console.log('Failed to get ads...' + err)
                })

                // Call api to perform submission of artwork to SSP/Reach/media owner
                if (ads) { //&& !file.reachCreativeID
                    // var uniqueAdIDs = await Promise.all(ads.map(ad => ad._id));     // All ad IDs
                    // uniqueAdIDs = [...new Set(uniqueAdIDs)] // Remove duplicates using sets.

                    // await Promise.all(uniqueAdIDs.map(async adID => {
                        await fetch('/api/ads/submittossp/', {
                            method: 'POST',
                            body: JSON.stringify({
                                fileID: file._id
                            }),
                        headers: {
                            'Content-Type' : 'application/json'
                        }
                        }).then(res => {
                            return res.json()
                        }).then(res => {
                            // Get the response code
                            console.log('response after submittossp: ' + res.message)
                        })
                    // }))
                }
                

                // This should cause the parent to update
                this.props.onUpdate()
                return
            } else {
                cogoToast.error('Failed to save file.')
            }
        }).catch(err => {
            cogoToast.error("Couldn't save the file. Please refresh and try again. Error: " + err)
        })
    }


    // Modals
    // Callback for when the disapproval modal is closed
    disapprovalModalClose = () => {
        this.setState({ disapprovalModal: {show: false }})
        // Update the page...
        this.getData();

        // This should cause the parent to update
        this.props.onUpdate()
    
    };


    // --------------------------- HANDLER FUNCTIONS -------------------------------- //


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //

    // Display bulk actions at the top of the table (e.g. delete all, pause all etc.)
    displayBulkActions = () => {
        if (this.state.bulkActionsVisible){
            return (
                <selectedOrgContext.Consumer>
                    {({ selectedOrg, setSelectedOrg }) => (
                        <ButtonGroup aria-label="Basic example" className='float-right'>
                        <DropdownButton onSelect={(e) => this.handleBulkAction(e)} as={ButtonGroup} title="Bulk Actions " id="bg-nested-dropdown" className='ml-auto mr-2'>
                            <Dropdown.Item eventKey="delete">Delete Selected</Dropdown.Item>
                            {/*<Dropdown.Item eventKey="pause">Pause Selected</Dropdown.Item>*/}
                        </DropdownButton>
                        </ButtonGroup>
                    )}
                    
                </selectedOrgContext.Consumer>
            )
        } else {
            return (<></>)
        }
    }


    renderCampaignDetails = (fileID, index) => {
        console.log('rendering campaign details')
        return  <CampaignDetailsFromArtwork key={fileID + 'campaignDetails_' + index} fileID={fileID} />
    }


    // REnder the filter menu
    renderFilterMenu = () => {
        return (
            <>
                <h2 className=" my-auto mx-4 d-inline"><FontAwesomeIcon icon = {faFilter} className='mb-n1' /></h2>
                <Button className='tab-light tab-small mx-3' active={this.state.filters.hasAdID.active} onClick={() => {this.setState({filters: {...this.state.filters, hasAdID : {active: !this.state.filters.hasAdID.active } }})} }><p className="noto-sans px-4 bold-text blue-grey-text uppercase my-0">IS IN CAMPAIGN</p></Button>

                <Button className=' tab-light tab-small mx-3' active={this.state.filters.isApproved.active} onClick={() => {this.setState({filters: {...this.state.filters, isApproved : {active : !this.state.filters.isApproved.active } }})} }><p className="noto-sans blue-grey-text px-4 bold-text uppercase my-0">APPROVED ONLY</p></Button>
                <Button className='tab-light tab-small mx-3' active={this.state.filters.isRejected.active} onClick={() => {this.setState({filters: {...this.state.filters, isRejected : {active: !this.state.filters.isRejected.active } }})} }><p className="noto-sans blue-grey-text px-4 bold-text uppercase my-0">REJECTED ONLY</p></Button>
                <Button className='tab-light tab-small mx-3' active={this.state.filters.hasSSPID.active} onClick={() => {this.setState({filters: {...this.state.filters, hasSSPID : {active: !this.state.filters.hasSSPID.active } }})} }><p className="noto-sans px-4 bold-text blue-grey-text uppercase my-0">HAS EXTERNAL ID</p></Button>
                <Button className='tab-light tab-small mx-3' active={this.state.filters.missingSSPID.active} onClick={() => {this.setState({filters: {...this.state.filters, missingSSPID : {active: !this.state.filters.missingSSPID.active } }})} }><p className="noto-sans px-4 bold-text blue-grey-text uppercase my-0">NO EXTERNAL ID</p></Button>

            </>
        )
    }

    // Render campaigns as a table
    renderFileTableData = () => {
        if (this.state.isLoaded){
            // Use react-table to render campaignTableData
            // Tables to render with reactTable
            //console.log('campaignDataArray: ' + JSON.stringify(this.state.campaignDataArray))
            const fileTableRows = this.state.fileDataArray;
            const fileTableCols = [
                
                {
                    Header: 'Read Status',
                    maxWidth: 150,
                    accessor: 'isReviewed',
                    Cell: (row, index) => (

                        <Button
                        name = {row.original._id}
                        key = {row.original._id}
                        id={row.original._id}
                        className={'text-button-no-background px-3' + (row.original.isReviewed ? ' grey-link' : ' primary-link')} 
                        onClick = {(e) => this.handleUnreadToggle(e)} >
                            <FontAwesomeIcon style={{fontSize: '1.2rem'}} icon={(row.original.isReviewed) ? faEnvelopeOpen : faEnvelope} />
                        </Button>

                        //  <div className="custom-control custom-switch">
                        //      <input type="checkbox" className="custom-control-input" name = {row.original._id} id={row.original._id + 'Toggle'} onChange= {((e) => this.handleFileApprovalToggle(e))} checked={!row.original.isPreApproved || false}/>
                        //      <label className="custom-control-label" htmlFor={row.original._id + 'Toggle'}>{ row.original.isPreApproved ? 'Approved' : 'Unapproved'}</label>
                        //  </div>
                    )
                },
                {
                    Header: () => (
                        <Container className = 'text-left pl-3'>
                            <span>File Name</span>
                        </Container>
                      ),
                    accessor: 'fileName',
                    minWidth: 100,
                    Cell: (row) => (
                        <Container >
                            <Row>
                                <Col className='text-left'>
                                    <p className='caption-smaller'>{row.original._id}</p>
                                    <a href={row.original.fileURL} target="_blank" rel="noopener noreferrer"  style={{fontSize: '1.0rem'}} className="primary-link" >{row.original.fileName || 'Loading...'}</a>
                                    <Row className='my-2'>
                                        <Col>
                                        <a href={row.original.fileURL} target="_blank" rel="noopener noreferrer" key = {row.original._id}
                                            className="noto-sans bold-text uppercase caption-small primary-link"
                                            as={Link}
                                            >
                                            VIEW <FontAwesomeIcon className="ml-3" icon={faExternalLinkAlt} />
                                        </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                        </Container>
                    )
                },
                {
                    Header: () => (
                        <Container className = 'text-left pl-3'>
                            <span>Board</span>
                        </Container>
                      ),
                    accessor: 'boardName',
                    Cell: row => (
                        
                        <Container >
                            <Row>
                                <Col className='text-left my-auto'>
                                    <p><BoardFromFile fileID = {row.original._id}/></p>

                                </Col>
                            </Row>
                            
                        </Container>
                        )
                },
                
                {
                    Header: () => (
                        <Container className = 'text-left pl-3'>
                            <span>Organisation</span>
                        </Container>
                      ),
                    accessor: 'orgID',
                    Cell: row => (
                        
                        <Container >
                            <Row>
                                <Col className='text-left my-auto'>
                                    {row.original.orgID ? <OrgNameFromID key={row.original.orgID + 'table component'} orgID = {row.original.orgID} /> : '' }
                                    <p className='caption-small'>{row.original.orgID}</p>
                                    <a key = {row.original.orgID}
                                            className="noto-sans bold-text uppercase caption-small primary-link"
                                            href={'https://ui.caasie.co/organisation/' + row.original.orgID}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            VIEW ORG <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                                        </a>
                                </Col>
                            </Row>
                            
                        </Container>
                        )
                },
                {
                    Header: () => (
                        <Container className='text-left pl-3'>
                            <span>Campaign</span>
                        </Container>
                    ),
                    accessor: 'campaign',
                    Cell: (row, index) => {
                        return (
                            <Container >
                                <Row>
                                    <Col className='text-left my-auto'>
                                        {this.renderCampaignDetails(row.original._id, index)}
                                    </Col>
                                </Row>
                                
                            </Container>
                        )
                    }
                        
                },
                {
                    Header: 'REACH ID',
                    accessor: 'reachCreativeID',
                },
                {
                    Header: 'MW ID',
                    accessor: 'mwCreativeID',
                },
                {
                    Header: 'Creation Date',
                    accessor: 'dateCreated',
                    maxWidth: 170,
                    Cell: row => (
                        
                        <Container >
                            <Row>
                                <Col className='text-center my-auto'>
                                    <p>{moment(row.original.dateCreated).format("D MMM YYYY")}</p>
                                    <p className='caption-small blue-grey-text'>{moment(row.original.dateCreated).from(moment())}</p>
                                </Col>
                            </Row>
                            
                        </Container>
                        )
                },
                {
                    Header: 'Manage',
                    accessor: 'manage',
                    Cell: row => (
                        <Container>
                            <Col>
                                <Row className='mb-3'>
                                    <p className={`noto-sans bold-text ${row.original && row.original.isPreApproved === true ? 'green-text' : row.original.isPreApproved === false ? 'error-text' : 'blue-grey-text'} caption-small`}>
                                        {row.original && row.original.isPreApproved === true ? 'APPROVED' : row.original.isPreApproved === false ? 'REJECTED' : 'NOT SET'}
                                    </p>

                                    <Link key = 'disapprove'
                                        className='pointer primary-link noto-sans bold-text uppercase caption-smaller' 
                                        onClick={(e) => {
                                            //e.preventDefault();
                                            var newContent = this.getDisapprovalModalContent(row.original)
                                            this.setState({ 
                                                //isLoaded: false,
                                                disapprovalModalContent: newContent,
                                                disapprovalModal: {show: true}
                                            }, () => {
                                                console.log('set new content...' + JSON.stringify(newContent))
                                            })
                                        }}>Disapprove <FontAwesomeIcon icon={faTrash} className='ml-3'/>
                                    </Link>
                                </Row>
                                <Row>
                                    <Link key = 'approve'
                                        className='pointer primary-link noto-sans bold-text uppercase caption-smaller' 
                                        onClick={() => {
                                            // Approve the artwork
                                            this.handleArtworkApprovalButton(row.original)

                                        }}
                                        >
                                        Approve <FontAwesomeIcon className="ml-3" icon={faCheckCircle} />
                                    </Link>
                                </Row>
                                
                                

                            </Col>
                            
                        </Container>
                    )
                }
                
            ]

            return (
                <selectedOrgContext.Consumer>
                    {(selectedOrg) => (
                        <Container fluid>
                            <Row className='pb-4'> 
                                {this.renderFilterMenu()}
                            </Row>
                            <ReactTable
                                manual
                                data={fileTableRows}
                                pages={this.state.totalPages}
                                columns={fileTableCols}
                                defaultPageSize={this.state.fileTablePageSize}
                                loading={this.state.isFetching}
                                // onPageChange= {this.onPageChange}
                                // onPageSizeChange= {this.onPageSizeChange}
                                onFetchData = {this.fetchData}
                                minRows = {this.state.fileDataArray.length > 0 ? 0 : 3}
                                noDataText = "There are no files to show."
                                defaultSorted={[
                                    {
                                    id: "dateCreated",
                                    desc: true
                                    }
                                ]}
                            />
                        </Container>
                    )} 
                </selectedOrgContext.Consumer>
            
            )
        } else {
            return (<LoadingAnimation />)
        }
    }


    



    // ---------------------------------------------- RENDER FUNCTIONS ---------------------------------------------//


    // Render campaign table 
    render = () => {
        console.log('rendering campaign table')
        return(
            
            <selectedOrgContext.Consumer>
            {({ selectedOrg, setSelectedOrg }) => (
                    <Spring
                    from={{ transform: 'translate3d(0,-40px,0)' }}
                    to={{ transform: 'translate3d(0,0px,0)' }}>
                        {props => 
                            <Container style={props} fluid className='mb-0 mx-0 px-0'>
                            <Row className='align-items-center mb-5 ml-auto'>
                                <Col md={6} lg={8}>
                                    {(!this.props.hideTitle) ? 
                                        <div>
                                        <h3 className="grey-text noto-sans my-auto"><b>LATEST ARTWORKS</b></h3>
                                        <p>The latest artworks that have been uploaded.</p>
                                        </div>
                                            : '' }
                                    
                                </Col>
                                <Col>
                                    {this.displayBulkActions()}
                                </Col>
                            </Row>
                            <Row style={props} className='my-5'>
                                {this.renderFileTableData()}
                            </Row>

                            {/* Modals */}
                            <ModalPopup
                                show={this.state.disapprovalModal.show}
                                onHide={this.disapprovalModalClose}
                                content = {this.state.disapprovalModalContent}
                            />

                            </Container>

                            
                        }
                    </Spring>
                    )}
            </selectedOrgContext.Consumer>
            
        )
    }


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //    
}   // end class







// Helper class to find board ID given a file
class BoardFromFile extends PureComponent {
    constructor(props) {
        super(props)  
        this.state = {
            fileID: this.props.fileID,
            boardIDArray: [],
            boardDataArray: [],
        }
    }

    // On mount
    componentDidMount = async() => {
        await this.getBoardData();
    }

    // On update
    componentDidUpdate = async (prevProps, prevState ) => {
        if (this.props.fileID !== prevProps.fileID) {
            this.getBoardData()
        }
    }

    // fetch
    getBoardData = async() => {
        var fetchStatus = 0;

        await fetch('/api/file/boardfromfile/' + this.props.fileID, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status
            return res.json();
        }).then(res => {
            return this.setState({
                boardIDArray: res.boardIDArray,
                boardDataArray: res.boardDataArray
            })
        })
    }


    // Render
    render = () => {
        if (this.state.boardDataArray){
            return this.state.boardDataArray.map(board => {
                return (
                    <>
                        <p>{board.boardname}</p>
                    </>
                )
            })
        } else {
            return 'loading...'
        }
        
    }
}



// Displays the org name given an ID
class CampaignDetailsFromArtwork extends Component {
    constructor(props) {
        super(props)
        this.mounted = true;
        this.state = {
            fileID: this.props.fileID,
            campaignDataArray: [],
            isLoaded: false,
        }
        
    }

    // On mount
    componentDidMount = async () => {
        this.getCampaignDetails();
    }

    // On update
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.fileID !== this.props.fileID) {
            this.getCampaignDetails()
        }
        // Update 
      }
 

    // GET function
    getCampaignDetails = async () => {
        var fetchStatus = 0;
        var campaignArray = await fetch('/api/file/campaignfromfile/' + this.props.fileID, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            return res.campaignArray
        }).catch(err => {
            console.log('Failed to fetch campaign from file: ', err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

        // console.log('got campaign details')
        // Now set the state
        await this.setState({
            campaignDataArray: await removeDuplicateObjects(campaignArray, '_id'),
            isLoaded: true
        })

    }


    // Render
    render = () => {
        if (this.state.isLoaded) {
            console.log('rendering campaignDataArray', this.props.fileID, this.state.campaignDataArray.length)
            return this.state.campaignDataArray.length > 0 ? this.state.campaignDataArray.map(campaign => (
                <Container key = {campaign._id}>
                    <p>{campaign.campaignName}</p>
                    <p className='caption-small'>{campaign._id}</p>
                    <a key = {campaign._id}
                        className="noto-sans bold-text uppercase caption-small primary-link"
                        href={'https://ui.caasie.co/campaign/' + campaign._id}
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        VIEW CAMPAIGN <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                    </a>
                </Container> 
            )): <Container><p>Not in use.</p></Container>

        } else if (!this.state.isLoaded) {
            return <LoadingAnimation />
        } else {
            return <p>Not in use.</p>
        }
        
    }
}




BoardFromFile.propTypes = {
    fileID: PropTypes.string.isRequired,
}

export default FileTable