// The ImageUploader component
// Allows user to upload an image. Utilises api route to upload image to server.
// Tutorial: https://orinami.space/posts/uploading-images-to-cloudinary-using-react-dropzone/


// Usage: <ImageUploader uploaderCallback currentImage message/>


import React, { Component, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';    // Allows to link to different views?
import { withRouter } from 'react-router-dom';
import fetch from './_AdminCustomFetch'
import FetchFailHandler from './_AdminFetchFailHandler'
import {Container, Form, Jumbotron, Button, Row, Col, Card, CardGroup} from 'react-bootstrap';
import Moment from 'react-moment';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faMinus } from '@fortawesome/free-solid-svg-icons'
import LoadingAnimation from './_AdminLoadingAnimation'
import cogoToast from 'cogo-toast'
import Scrollbar from 'react-scrollbars-custom'
import axios from 'axios'


import "../styles/style.css"

// Constants
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '2px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };


class ImageUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
        files: [],

        fileVars: '',

        // Manage upload button
        uploadButtonActive: true,
        uploadText: 'Upload',
        uploadProgress: 0,        // Progress indicator

        filesBase64: [],

        imageOwnerID: '',     // The ID of the entity that owns this image uploader instance (i.e. an ad, a profile picture of a user, etc.)

        imageOwnerType: '',   // Team, User, Ad, Campaign, etc.

        isLoaded: false,
        };
  }


  getColor = (obj) => {
        if (obj.isDragAccept) {
            console.log('accepting drag')
            return '#00e676';

        }
        if (obj.isDragReject) {
            console.log('rejecting drag')
            return '#ff1744';
        }
        if (obj.isDragActive) {
            console.log('active drag')
            return '#2196f3';
        }
        console.log('not accepting, rejecting or active')
        return '#eeeeee';
  }

    // Get container style (dynamic)
    getContainerStyle = (obj) => {

        var newBorderColor = this.getColor(obj);


        var containerStyle = 
        {flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '5px',
        borderColor: newBorderColor,
        borderStyle: 'dashed',
        backgroundColor: '#00232d',
        color: '#f8f8f8',
        outline: 'none',
        transition: 'border .24s ease-in-out'
        };

        return containerStyle;
    }
    // Restore previous state if exists
    checkPreviousStateAndRestore = () => {
    // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
    if (typeof this.props.location.state !== 'undefined'){
        if (typeof this.props.location.state.prevState !== 'undefined'){
                //var newState = JSON.parse(this.props.location.state.prevState)
                console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                var stateVarIterator = 0;   

                // Get previous state passed as prop
                const prevState = this.props.location.state.prevState;
                var prevStateObj = Object.keys(prevState);
                //let self = this;
                prevStateObj.forEach((key) => {
                    stateVarIterator = stateVarIterator + 1;
                    //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                    console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                    newState[key] = prevState[key]

                    if (stateVarIterator ===  prevStateObj.length){
                        this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                        return true;
                    }
                })
            } else {return false}
        }
    } 

    componentDidMount = () => {
        // Get previously held state
        //this.checkPreviousStateAndRestore();

        

        console.log('currentImage: ' + JSON.stringify(this.props.currentImage))
        // Set current image from provided ID. Push file into files
        if (this.props.currentImage){
            
            // currentImage is a fileID
            var fetchStatus = 0;
            fetch('/api/file/' + this.props.currentImage, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                console.log('get currentImage res: ' + JSON.stringify(res))
                // Push the file into state
                var newFiles;

                // Fetch file from its url (in cloud)
                // Convert fileURL to https
                var secureFileURL = res.file.fileURL;
                secureFileURL = secureFileURL.includes('https://') ? secureFileURL : secureFileURL.replace('http://', 'https://')
                console.log('secureFileURL: ' + secureFileURL)
                fetch(secureFileURL)
                .then(res => {
                    return res.blob()   // Gets the response and returns it as a blob
                }).then(blob => {
                    newFiles = [];
                    newFiles.push(new File([blob], res.file.fileName))
                    newFiles.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                      }))
                    this.setState({
                        files: newFiles,
                        uploadButtonActive: false,
                        uploadText: 'Uploaded',
                        isLoaded: true
                    }, () => {
                        console.log('created new file from blob and set state.')
                        //this.onDrop(this.state.files)
                    })
                }).catch(err => {       // If fetch fails
                    console.log('error: ' + err)
                    this.setState({
                        isLoaded: true
                    })
                })
            }).catch(err => {
                console.log('fetch error: ' + err)
                FetchFailHandler(this.props, this.state, fetchStatus)
            })
        } else {
            // No current image. Open anyway
            this.setState({
                isLoaded: true
            })
        }

    }

    // On Drop behaviour
    onDrop = (files) => {
        this.setState({
            files: files,
            uploadButtonActive: true,
            uploadText: 'Upload'
        }, () => {
            // 
            console.log('dropped files' + JSON.stringify(files));
            // Create previews of the files

            var newFiles = this.state.files;        // Files with previews

            newFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }))

            this.setState({
                files: newFiles,
                uploadButtonActive: true,
            }, () => {
                // DOne setting state with new files (and previews)
                console.log('updated files with previews.')
                this.uploadFiles()
                // Send updated files to parent so that parent can save the relevant file...
                //this.props.uploaderCallback(this.state.files)
            })

            

        })
    };


    // Remove files behaviour
    removeFile = (event, fileIndex) => {
        event.preventDefault();
        event.stopPropagation();    // Prevents button below being clicked
        var newFiles = this.state.files;
        newFiles.splice(fileIndex, 1);

        this.setState({
            files: newFiles
        }, () => {
            console.log('Removed files')
            this.props.uploaderCallback(null)
        })
    }



    // Upload files to server
    uploadFiles = (event) => {
        if (event) {
            event.preventDefault(); 
            event.stopPropagation();
        }
            // PRevents button below being clicked
        
        // Change button text
        this.setState({
            uploadText: 'Uploading...'
        }, () => {
            // Perform actual uploading process
            this.state.files.forEach(file => {
                //reader.readAsDataURL(file);

                // const formData = new FormData();
                // // TODO: Put the protected variables in process.env
                // formData.append("tags", '{TAGS}'); // Add tags for the images - {Array}
                // formData.append("upload_preset", "rmko43ip"); // Replace the preset name with your own
                // formData.append("api_key", "672348976356214"); // Replace API key with your own Cloudinary API key
                // formData.append("timestamp", (Date.now() / 1000) | 0);
                // formData.append("file", file);

                // Upload the image to cloudinary https://orinami.space/posts/uploading-images-to-cloudinary-using-react-dropzone/

                cogoToast.loading('Sending your image to the cloud...').then(async () => {
                    var self = this;
                    
                    // Determine the filetype
                    var fileParts = await file.path.split('.');
                    var fileType = fileParts[fileParts.length-1]
                    var fileName = fileParts[0];        // First part. TODO: combine all fileParts if we have . in the filename.

                    // Clean up the fileName:
                    fileName = fileName.replace(/[^\w\d_\-.]+/ig, '')   // https://www.npmjs.com/package/react-s3-uploader
                    
                    // Obtain the credentials from the CAASie server


                    console.log('fileName: ' + JSON.stringify(fileName));
                    console.log('fileType: ' + JSON.stringify(fileType))


                    // ------------------ DOES NOT WORK -------------------------- //
                    // Using this only to obtain the URL of the bucket.
                    var fetchStatus = 0;
                    var signedObj = await fetch('/api/file/signs3', {
                        method: 'POST',
                        body: JSON.stringify({
                            fileName: fileName,
                            fileType: fileType
                        }),
                        headers: {
                            'Content-Type' : 'application/json'
                        }
                    }).then(res => {
                        fetchStatus = res.status;
                        return res.json();
                    }).catch(err => {
                        this.props.onFail()
                        cogoToast.error('Failed to get your authentication credentials...')
                    })

                    console.log('signedObj: ' + JSON.stringify(signedObj))
                    
                    // ------------------ DOES NOT WORK -------------------------- //


                    // -------------------------------TEMP--------------------------
                    // TODO: Find a way to secure this. SignedUrl approach does not work (403 error)

                    const config = {
                        bucketName: 'caasie-image-storage',
                        region: 'ap-southeast-1',
                        accessKeyId: 'AKIAR3I775UXWYG57UOL',
                        secretAccessKey: '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',
                    }
                    
                    // Date variables for s3 uploader (https://github.com/Fausto95/aws-s3/blob/master/src/Date.ts)
                    var dateISOString = new Date(+new Date() + 864e5).toISOString();
                    var dateYMD = dateISOString.split("T")[0].split("-").join("");
                    var xAmzDate =  dateISOString.split("-").join("").split(":").join("").split(".").join("");

                    
                    
                    // SignedObj has the upload URL as well as the signedRequest from AWS
                    // Perform a PUT request to AWS using axios.
                    // NOTE: SignedUrl approach does not work
                    
                    // Config details from s3 uploader https://www.npmjs.com/package/react-s3
                    const axiosConfig = {
                        headers: { 
                            'x-amz-acl': 'public-read',
                            'Content-Type': fileType,
                            "x-amz-meta-uuid" : "14365123651274",
                            "x-amz-server-side-encryption" : "AES256",
                            "X-Amz-Credential" : `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`,
                            "X-Amz-Date" : xAmzDate,
                            "X-Amz-Algorithm" : "AWS4-HMAC-SHA256"
                        },
                        onUploadProgress: function(progressEvent) {
                          // Do whatever you want with the native progress event
                            console.log('progressEvent', progressEvent);
                          var progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
                          //console.log('progress: ' + progress)
                          self.setState({
                              uploadProgress: progress,
                              uploadText: 'Uploading...' + progress + '%'
                          })
                        }
                    }

                    // -------------------------------TEMP--------------------------

                    await axios.put(signedObj.credentials.url, file, axiosConfig)
                    .then( res => {
                        // Res is already a JSON object (using axios)
                        console.log('response received from AWS...')
                        console.log(JSON.stringify(res));

                        console.log('selected Org: ' + this.context.selectedOrg)
                        // Force refresh of page if selectedOrgContext does not exist
                        if (this.context.selectedOrg === '') {
                            cogoToast.error("So uh, your organisation didn't load. Try refreshing the page.", {hideafter: 0})
                            return
                        }
                        // If upload is successful, create a file object in database
                        var fileVars = {
                            fileName: res.config.data.path,
                            fileURL: res.config.url,
                            fileFormat: res.format,
                            // TODO: Do not set orgID for profile images or other files that aren't bound to an organisation
                            // orgID: this.context.selectedOrg
                        }

                        // Once uploaded, perform call to files api in CAASie
                        console.log('stored data: ' + JSON.stringify(fileVars));

                        fetch('/api/file/create', {
                            method: 'POST',
                            body: JSON.stringify(
                                {
                                    fileName: fileVars.fileName,
                                    fileURL: fileVars.fileURL,      // File is located somewhere (e.g. on cloudinary)
                                    fileFormat: fileVars.fileFormat,
                                    orgID: fileVars.orgID

                                }
                            ),  // campaignName, campaignBoards, team
                            headers: {
                            'Content-Type': 'application/json'
                            } 

                        }).then(res => {
                            return res.json();
                        }).then(res => {
                            console.log('file creation response: ' + JSON.stringify(res))
                            cogoToast.success('Uploaded file successfully. You can now save the ad.')
                            this.setState({
                                uploadButtonActive: false,
                                uploadText: 'Uploaded',
                                fileVars: fileVars
                            }, () => {
                                // On creation return to parent component
                                console.log('uploadedButtonActive? ' + this.state.uploadButtonActive)
                                // Return the file object ID to the parent component
                                this.props.uploaderCallback(res.file._id)
                            })

                        })


                    }).catch(err => {       // If fetch fails
                        cogoToast.error('Failed to upload your file. Please try again.', {hideAfter:0})
                        // Failure callback (provided as prop)
                        this.props.onFail()
                        console.log('error: ' + err)
                    })
                })


            })
        })
    }

  render() {
      if(this.state.isLoaded){
            const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
            ));

            const thumbs = this.state.files.map((file, index) => (
                <Container fluid key={file.name}>
                    <Row >
                    <Col sm={1}>
                        <Button className='text-button-green' onClick = {e => this.removeFile(e, file)}><FontAwesomeIcon className="" icon={faMinusCircle}/></Button>
                    </Col>
                    <Col sm={2} >
                        <div style={thumb} >
                            <div className ='float-right' style={thumbInner}>
                                <img
                                src={file.preview}
                                style={img}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <p className='grey-text noto-sans bold-text caption-smaller'key={file.name}>
                        {file.name} - {file.size} bytes
                        </p>
                        {(this.state.uploadButtonActive) ? <Button className='caption-smaller' active onClick = {e => this.uploadFiles(e)}>{this.state.uploadText}</Button> : <Button className='caption-smaller' disabled onClick = {e => this.uploadFiles(e)}>{this.state.uploadText}</Button>}
                        
                    </Col>
                    </Row>
                </Container>
                
            ));

            return (
            
            <Container>
            <Dropzone accept={'image/*'} onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {

                    return (
                        <div> 
                            <div {...getRootProps()} style={this.getContainerStyle({isDragActive: isDragActive, isDragAccept: isDragAccept, isDragReject: isDragReject})}>

                                <input {...getInputProps()} />
                                <Container>
                                <p className="noto-sans grey-text caption-small bold-text">Drag 'n' drop some files here, or click to select files</p>
                                <p className="noto-sans blue-grey-text caption-smaller mt-0">{this.props.message ? this.props.message : ''}</p>
                                    {isDragAccept && (<p>All files will be accepted</p>)}
                                    {isDragReject && (<p>Some files will be rejected</p>)}
                                    {!isDragActive && (<p>Drop some files here ...</p>)}
                                    <aside style={thumbsContainer}>
                                    {thumbs}
                                    </aside>
                                </Container>
                            </div>
                        </div>

                    );
                }}
            </Dropzone>
        </Container>
        );
    } else {
        return (<LoadingAnimation />)
    }
  }
}

// Exports
export default withRouter(ImageUploader);


// Render the class
const CreateNewImageUploader = () => {
    return(
        <div>
            <ImageUploader />
        </div>
    )
}