// The Manage board component
// Allows board owner to manage the properties of a single board




// Usage: <BoardManager board=boardID team=teamID org=orgID/>

// Import dependencies
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';    // Allows to link to different views?
import Sidebar from '../components/_AdminSidebar'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select'
import { withRouter } from 'react-router-dom';
import "../styles/style.css"
import fetch from './_AdminCustomFetch'
import DatePicker from "react-datepicker";
import FetchFailHandler from './_AdminFetchFailHandler'
import MenuItem from '@material-ui/core/MenuItem';
import "react-datepicker/dist/react-datepicker.css";
import update from 'immutability-helper';
import { get } from 'http';
import {Container, Form, Jumbotron, Button, Row, Col, Modal, Card, CardGroup} from 'react-bootstrap';
import ImageUploader from '../components/_AdminImageUploader'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import cogoToast from 'cogo-toast';
import Moment from 'react-moment';
import LoadingAnimation from './_AdminLoadingAnimation'

import "../styles/style.css"


// Constants
const VENUETYPES = [
    "Transport Station", "Airport", "Sports and Recreation Area", "Entertainment", "Supermarket/Retail Centers", "Shopping Mall", "Fuel/Gas Station", "Amusement Park",
    "Point of Care", "Roadside", "Building Side", "Street Furniture"
];

const SCREENTYPES = ["Railway Station", "Digital Bus Shelter", "Digital Transit Shelter", "Airport", 
"Airport (luggage belt)", "Airport (gate)", "Airport (resturant)", "Airport (arrivals)", "Airport (retail)",  "Airport (check in)", "Airport (departures)",
"Amusement Park", "Sports Complex", "Sports Arena",
"Cinema",
"Media Store", "Supermarket", "Convenience Store",
"Mall (Food court)", "Mall", "Mall (Concourse)",
"Gas Station (Cash Register)", "Gas Station (Pump)", "Gas Station (Store Entry)", "Gas station (shop entrance)", "Gas Station",
"Amusement Park",
"Medical Center", "Veterinarian Clinic", "Doctors Office",
'Street Furniture', 'Digital Bus Shelter', 'Highway Billboard', "Roadside Billboard",
'Office Building', "Commercial Tower", "Residential Tower", "Parking garage",
"Digital Urban Panel", "Digital Poster", "Digital 30's", "Street Furniture", "Highway Billboard"]; // Reach inventory classifications

class ManageBoard extends Component {
    constructor(props) {
      super(props)
        this.state = {
            
            boardID: this.props.board ? this.props.board : '',
            boardname: '',
            boardcoords: {
                lat: '',
                long: '',
            },
            boardloc: {
                city: '',
                state: '',
                country: '',
            },   // Location name (city)
            boardsize: {
                height: '', 
                width: '',
            },
            boardminCPI: '',
            surfaceArea: '',
            boardOwner: this.props.org ? this.props.org : '',	    //board owner is an organisation
            team: this.props.team ? this.props.team : '',          // Team that manages board
            // Broadsign parameters (for Reach)
            device_id: '',
            opening_hours: '',
            screenType: '',
            venueType: '0',
            adTypes: [''],  // array of strings

            boardImgFileID: '', // The fileID of the document corresponding to the image of the board
            boardImgURL: '', // The URL of the file

            // When saved message
            onSave: '',
            saveText: 'Save Changes',
            isSaveButtonEnabled: true,

            // Other state vars
            isLoaded: false,

        }   // end this.state
    }   // end constructor


    // Called after component renders to screen
    componentDidMount = async () => {
        //
        await this.getData();
    }

    // Checks if previous state was passed in as prop and restores it
    checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            this.setState(newState, () => {
                                console.log('After setting state: ' + JSON.stringify(this.state));
                                // Force the state to be unloaded until new data has been obtained by the component (i.e. Previous data does not constitute an updated state)
                                this.setState({isLoaded: false})
                            });
                            return true;
                        }
                    })
                } else {return false}
        }
    } 

   
   
   
   
    // ------------------------------------------- GET FUNCTIONS ------------------------------------------- //
    
    
    // Get data of this board
    getData = async () => {
        var fetchStatus = 0;
        await fetch('/api/boards/uncached/' + this.state.boardID, {
            method: 'GET'
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            this.setState({
                boardname: res.board.boardname ? res.board.boardname : '',
                boardcoords: {
                    lat: res.board.boardcoords ? res.board.boardcoords.lat : '',
                    long: res.board.boardcoords ? res.board.boardcoords.long : '',
                },
                boardloc: {
                    city: res.board.boardloc ? res.board.boardloc.city : '',
                    state: res.board.boardloc ? res.board.boardloc.state : '',
                    country: res.board.boardloc ? res.board.boardloc.country : '',
                },   // Location name (city)
                boardsize: {
                    height: res.board.boardsize ? res.board.boardsize.height : '', 
                    width: res.board.boardsize ? res.board.boardsize.width : '',
                },
                boardminCPI: res.board.boardmincpi ? res.board.boardmincpi : '',         // TODO: Change to consistent case (boardmincpi)
                surfaceArea: res.board.surfaceArea ? res.board.surfaceArea : '',
                boardOwner: res.board.boardOwner ? res.board.boardOwner : '',	    //board owner is an organisation
                team: res.board.team ? res.board.team : '',          // Team that manages board
                // Broadsign parameters (for Reach)
                device_id: res.board.device_id ? res.board.device_id : '',
                opening_hours: res.board.opening_hours ? res.board.opening_hours : '',
                screenType: res.board.screenType ? res.board.screenType : '',
                venueType: res.board.venueType ? res.board.venueType : '',
                adTypes: res.board.venueType ? res.board.adTypes : '',  // array of strings

                boardImgFileID: res.board.boardFile ? res.board.boardFile : '',
                boardImgURL: res.board.boardImgURL ? res.board.boardImgURL : '',

                // Set state isLoaded
                isLoaded: true
            }, () => {

                console.log('boardImgFileID: ' + this.state.boardImgFileID)
            })
        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })
    }   // end get data


    // ------------------------------------------- GET FUNCTIONS ------------------------------------------- //

   
   
    
    
    
    
    
    // ------------------------------------------- HANDLER FUNCTIONS ------------------------------------------- //
    
    // modify the board
    modifyBoard = async (e) => {
        e.preventDefault();

        await this.setState({
            saveText: 'Saving...',
            isSaveButtonEnabled: false
        })
        // Firstly fetch the image file if we have a fileID but no boardImgURL (so that we can set the fileURL in the board document)
        var fileFetchStatus = 0;
        if (this.state.boardImgFileID && !this.state.boardImgURL){
            await fetch('/api/file/' + this.state.boardImgFileID, {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json'
                }
            }).then(res => {
                fileFetchStatus = res.status;
                return res.json()
            }).then(res => {
                this.setState({
                    boardImgURL: res.file.fileURL
                })
            })
        }

        // If image file is set to null (deleted)
        if (this.state.boardImgFileID === null) {
            await this.setState({
                boardImgFileID: null
            })
        }

        // Fetch the api route to modify the board on submit
        var fetchStatus = 0;
        await fetch('/api/boards/modify/' + this.state.boardID, {
            method: 'POST',
            body: JSON.stringify({
                boardname: this.state.boardname ? this.state.boardname : '',
                boardcoords: {
                    lat: this.state.boardcoords.lat ? this.state.boardcoords.lat : '',
                    long: this.state.boardcoords.long ? this.state.boardcoords.long : '',
                },
                boardloc: {
                    city: this.state.boardloc.city,
                    state: this.state.boardloc.state ? this.state.boardloc.state : '',
                    country: this.state.boardloc.country ? this.state.boardloc.country : '',
                },   // Location name (city)
                boardsize: {
                    height: this.state.boardsize.height ? this.state.boardsize.height : '', 
                    width: this.state.boardsize.width ? this.state.boardsize.width : '',
                },
                boardminCPI: this.state.boardminCPI ? this.state.boardminCPI : '',
                surfaceArea: this.state.surfaceArea ? this.state.surfaceArea : '',
                boardOwner: this.state.boardOwner ? this.state.boardOwner : '',	    //board owner is an organisation
                // Broadsign parameters (for Reach)
                device_id: this.state.device_id ? this.state.device_id : '',
                opening_hours: this.state.opening_hours ? this.state.opening_hours : '',
                screenType: this.state.screenType ? this.state.screenType : '',
                venueType: this.state.venueType ? this.state.venueType : '',
                adTypes: this.state.adTypes ? this.state.adTypes : '',  // array of strings

                // Handle case of setting images to null
                boardFile: typeof(this.state.boardImgFileID) !== 'undefined' ? this.state.boardImgFileID  : '',
                boardImgURL: typeof(this.state.boardImgURL) !== 'undefined'  ? this.state.boardImgURL : '',
            }),
            headers: {
                'Content-Type': 'application/json'
              }
            }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                // Acknowledge the response.
                console.log('res: ' + res.message)
                cogoToast.success('Successfully saved the board. You can go back now.')
                // Update the state with the latest information from the DB
                this.getData();

                this.setState({
                    saveText: 'Saved!',
                    isSaveButtonEnabled: false
                })
                
                
            }).catch(err => {
                console.log('fetch error: ' + err)
                FetchFailHandler(this.props, this.state, fetchStatus)
            })
            
        }   // end modify boards


    // Handle the input change
    handleFormInput = (event) => {
    event.preventDefault();
    const { value, name } = event.target;

    this.setState({
        isSaveButtonEnabled: true,
        saveText: 'Save Changes'
    }, () => {
        // Manually set nested variables...
        if (name === 'lat'){
            // Create a new state variable
            let newState = update(this.state, {
                boardcoords: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);
        } else if (name === 'long'){
            // Create a new state variable
            let newState = update(this.state, {
                boardcoords: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);

        } else if (name === 'city'){
            // Create a new state variable
            let newState = update(this.state, {
                boardloc: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);
        } else if (name === 'state'){
            // Create a new state variable
            let newState = update(this.state, {
                boardloc: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);
        } else if (name === 'country'){
            // Create a new state variable
            let newState = update(this.state, {
                boardloc: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);
        } else if (name === 'height') {
            // Create a new state variable
            let newState = update(this.state, {
                boardsize: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);
        } else if (name === 'width') {
            // Create a new state variable
            let newState = update(this.state, {
                boardsize: {
                    [name]: {$set: value}
                }
            });
            // Update the state
            this.setState(newState);
        } else {
            this.setState({
                [name]: value
            });
        }
    })


  }

  // Handle venue selection change
    handleVenueSelectionChange = (event) => {
        console.log('changed venue type selection')
        this.setState({venueType: event.target.value});
    }

    // Handle screen type selection change
    handleScreenTypeSelectionChange = (event) => {
        console.log('changed screen type selection')
        this.setState({screenType: event.target.value});
    }


    // When the board's image is uploaded, set it as the board's image
    handleImageUploaded = async (fileID) => {
        if(this.state.isLoaded){
            console.log('handleImageUploaded: ' + JSON.stringify(fileID));
            // associate file ID to board
            this.setState({
                boardImgFileID: fileID,
                isSaveButtonEnabled: true,
                saveText: 'Save Changes'
            });
        
        } else {
            return (<LoadingAnimation />)
        }
    } 

    // Handle the failure of an upload
    handleUploadFail = () => {
        console.log('Upload failed')
        cogoToast.error('Upload failed. Try refreshing the page.')
        // Clear the artwork (if any) and check validity of form (should be invalid)
        this.setState({
            boardImgFileID: '',
            saveText: 'Save Changes'
        })
    }

      // Delete the board
      handleDeleteButton = (event) => {
        // Make an API call to delete the campaign after the user confirms.
        confirmAlert({
        closeOnClickOutside: true,
          customUI: ({ onClose }) => {
            return (
              <div className='modal-dialog modal-lg modal-dialog-centered _45vw'>
                  <Container className='modal-content my-5'>
                    <Container className='table-background-dark'>
                        <Modal.Header closeButton closeLabel="close" onHide={onClose} className="white-text no-border" />
                        <Container className='p-5 mt-3 mb-5 modal-body '> 
                        
                            <h2 className='white-text' style={{"fontSize" : '3' + 'rem'}}><b>Delete</b> this board?</h2>
                            <h4 className='white-text mb-5'>It cannot be undone. So, that's a bold move. But you do you.</h4>
                            <Row>
                                <Col sm={4} className='ml-auto text-right'>
                                    <Button className='text-left' onClick={onClose}>No, I'll keep it.</Button>
                                </Col>
                                <Col sm={3} className='text-left' className='text-right'>
                                    <Button className='text-left' variant="secondary"
                                        onClick={() => {
                                            this.performBoardDelete();
                                            onClose();
                                        }}
                                        >
                                        Yes, I'm sure.
                                    </Button>
                                </Col>
                            
                            </Row>
                        </Container>
                    </Container>
                </Container>
              </div>
            );
          }
        })
    }   // end handleDeleteBoardButton

    

    // The function to delete the board
    performBoardDelete = () => {
        // Fetch api to delete board and then redirect to team dashboard
        var fetchStatus = 0;
        cogoToast.loading('Talking with my inner self...Don\'t judge me.').then(() => {
            fetch('/api/board/delete/' + this.state.boardID, {
                method: 'POST',
                body:  JSON.stringify({}),  // empty body
                headers: {
                    'Content-Type': 'application/json'
                    }
            }).then(res => {
                fetchStatus = 0;
                return res.json();
            }).then(res => {
                console.log('deleting campaign...');
                cogoToast.success('Successfully deleted campaign. Redirecting you to Management Screen.');
                this.props.history.push('/management/campaigns');

            }).catch(err => {
                //console.log('update campaign error: ' + err)
                cogoToast.error('Failed to delete campaign. Error: ' + JSON.stringify(err));
                //FetchFailHandler(this.props, this.state, fetchStatus)
            })
        })
    }

   // ------------------------------------------- HANDLER FUNCTIONS ------------------------------------------- //

   
   
   
   
   // ------------------------------------------- RENDER FUNCTIONS ------------------------------------------- //

    // Render functions
    // Render team options for the dropdown menu
    renderVenueOptions = () => {   
        if(this.state.isLoaded) {
            return (
                VENUETYPES.map((venue, index) => (
                    <MenuItem key = {venue} value = {venue}>
                        {venue}
                    </MenuItem>
                ))

            )
        } else {
            return (<LoadingAnimation />)
        }
    }

    // Render screen type options for the dropdown menu
    renderScreenTypeOptions = () => {   
        if(this.state.isLoaded) {
            return (
                SCREENTYPES.map((screenType, index) => (
                    <MenuItem key = {screenType} value = {screenType}>
                        {screenType}
                    </MenuItem>
                ))

            )
        } else {
            return (<LoadingAnimation />)
        }
    }


    // Render the image uploader
    renderImageUploader = () => {
        if (this.state.isLoaded){
           console.log('this.state.boardImgFileID: ' + this.state.boardImgFileID)
            return (
                <ImageUploader uploaderCallback = {this.handleImageUploaded} onFail={this.handleUploadFail} currentImage={this.state.boardImgFileID} />
            ) 
        } else {
            return (<LoadingAnimation />)
        }
        

    }


  render = () => {
    return (
            <Container fluid className ='my-5'>
                <h4 className="white-text mx-0">Some details about your <b>board</b>.</h4>
                <Form onSubmit={e => this.modifyBoard(e) } className ='mt-5'>
                    <Form.Row>
                        <Form.Group as={Col} className='my-3'>
                        <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                            type="text"
                            placeholder={"Your board's name. Make it unique."}
                            name="boardname"
                            onChange={this.handleFormInput}
                            value={this.state.boardname}
                        />
                        </Form.Group>

                    </Form.Row>

                    <h4 className="white-text my-5">About its <b>location.</b></h4>
                    <Form.Row className='mt-3'>
                        <Form.Group as={Col}>
                            <Form.Control className = 'inputField my-3 text-left square-corners'
                                required
                                type="number"
                                placeholder="Its latitude"
                                name="lat"
                                onChange={this.handleFormInput}
                                value={this.state.boardcoords ? this.state.boardcoords.lat : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className='my-3'>
                            <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                type="number"
                                placeholder={"Its longitude"}
                                name="long"
                                onChange={this.handleFormInput}
                                value={this.state.boardcoords ? this.state.boardcoords.long : ''}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className='mt-3'>
                        <Form.Group as={Col}>
                            <Form.Control className = 'inputField my-3 text-left square-corners'
                                required
                                type="text"
                                placeholder="The country it's in"
                                name="country"
                                onChange={this.handleFormInput}
                                value={this.state.boardloc ? this.state.boardloc.country : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className='my-3'>
                            <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                type="text"
                                placeholder={"The city"}
                                name="city"
                                onChange={this.handleFormInput}
                                value={this.state.boardloc ? this.state.boardloc.city : ''}
                            />
                        </Form.Group>

                    </Form.Row>
                    <h4 className="white-text mt-5">About the board and its venue.</h4>
                    <Form.Row className='mt-3'>
                        {/* <Form.Control as="select" className = 'custom-select-dropdown my-3 text-left square-corners' value={this.state.venueType} onChange={this.handleVenueSelectionChange}>
                                <option value='0'>Choose the type of venue this board is at</option>
                                {this.renderVenueOptions()}
                        </Form.Control> */}

                        <FormControl variant="filled" className='custom-select-dropdown px-2'>
                        <InputLabel htmlFor="venue">Venue Type</InputLabel>
                            <Select 
                                variant="filled"
                                className='custom-select-dropdown'
                                value={this.state.venueType}
                                onChange={this.handleVenueSelectionChange}
                                inputProps={{
                                    name: 'Choose Venue Type',
                                    id: 'venue',
                                }}
                                >
                                 {this.renderVenueOptions()}

                            </Select>
                        </FormControl>


                    </Form.Row>
                    <Form.Row className='mt-3'>
                        {/* <Form.Control as="select" className = 'custom-select-dropdown my-3 text-left square-corners' value={this.state.screenType} onChange={this.handleScreenTypeSelectionChange}>
                                <option value='0'>Choose the type of screen this is</option>
                                {this.renderScreenTypeOptions()}
                        </Form.Control> */}
                        <FormControl variant="filled" className='custom-select-dropdown px-2'>
                        <InputLabel htmlFor="screen">Screen Type</InputLabel>
                            <Select 
                                variant="filled"
                                className='custom-select-dropdown'
                                value={this.state.screenType}
                                onChange={this.handleScreenTypeSelectionChange}
                                inputProps={{
                                    name: 'Choose Screen Type',
                                    id: 'screen',
                                }}
                                >
                                 {this.renderScreenTypeOptions()}

                            </Select>
                        </FormControl>
                    </Form.Row>

                    <h4 className="white-text my-5">Some tech specs for <b>the buyers</b>.</h4>
                    <Form.Row className='mt-3'>
                        <Form.Group as={Col}>
                            <Form.Control className = 'inputField my-3 text-left square-corners'
                                required
                                type="number"
                                placeholder="Vertical Resolution"
                                name="height"
                                onChange={this.handleFormInput}
                                value={this.state.boardsize ? this.state.boardsize.height : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className='my-3'>
                            <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                type="text"
                                placeholder={"Horizontal Resolution"}
                                name="width"
                                onChange={this.handleFormInput}
                                value={this.state.boardsize ? this.state.boardsize.width : ''}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className='mt-3'>
                        <Form.Group as={Col}>
                            <Form.Control className = 'inputField my-3 text-left square-corners'
                                type="number"
                                placeholder="Its surface area (in square metres)"
                                name="surfaceArea"
                                onChange={this.handleFormInput}
                                value={this.state.surfaceArea ? this.state.surfaceArea : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className='my-3'>
                        </Form.Group>
                    </Form.Row>


                    <h4 className="white-text my-5">Lastly, <b>upload a pretty picture (or set its URL)</b>.</h4>
                    <Form.Row className ='pt-3'>
                        <Form.Group as={Col}>
                            <Form.Control className = 'inputField my-3 text-left square-corners'
                                type="string"
                                placeholder="External Image URL"
                                name="boardImgURL"
                                onChange={this.handleFormInput}
                                value={this.state.boardImgURL ? this.state.boardImgURL : ''}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className='my-3'>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className='mt-3'>
                        {this.renderImageUploader()}
                    </Form.Row>

                    <Row className='spacer mt-5' />

                    <Row >
                        <Button className="float-left my-auto tab mt-3" onClick = {e => this.handleDeleteButton(e)}>Delete Board</Button>
                        <Button className="float-right ml-auto" disabled={!this.state.isSaveButtonEnabled}  type="submit">{this.state.saveText}</Button>
                    </Row>

                
            </Form>
    </Container>
    )  // manual board loader
  }

   // ------------------------------------------- RENDER FUNCTIONS ------------------------------------------- //



}   // end component definition


// Exports
export default withRouter(ManageBoard);

const BoardManager = () => {
    return(
        <div>
            <ManageBoard/>
        </div>
    )
}
