// Authenticates user against database of IDs


import React, { Component } from 'react';
import { Link } from 'react-router-dom';    // Allows to link to different views?
import { Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {Container, Jumbotron, Form, Button, Row, Col, Card, CardGroup, CardColumns, Table} from 'react-bootstrap';
import "../styles/style.css"
import AuthContext from './_AdminAuthContext'
import fetch from './_AdminCustomFetch'
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'




class Login extends Component {
  static contextType = AuthContext;
  constructor(props, context) {
    super(props, context)
    this.state = {
      username : '',
      loginEmail : '',
      loginPass: '',
      token: '',

      // for rendering
      loginText: 'Log In',
      loginButtonEnabled : false,

      prevState: [], 
      prevURL: '/home'
    };
  }

  componentDidMount = async () => {
    if (this.props.location.state) {
      console.log('Previous state exists!' + JSON.stringify(this.props.location.state))
       this.setState({
          prevState: this.props.location.state.prevState, 
          prevURL: this.props.location.state.prevURL
       })
    }
  }

    // On component update
    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.token && prevState.token !== this.state.token) {
                console.log('setting auth token')
                // If the token has changed, set the context

                this.context.setAuthToken(this.state.token)
        }
    }

  // HAndle the change in input
  handleInputChange = (event) => {
    const { value, name } = event.target;
    // console.log('event.target.name: ' + event.target.name)
    // console.log('event.target.value: ' + event.target.value)
    this.setState({
      [name]: value
    }, () => {
      this.checkValidity()
    });
  }

  // Check input validity
  checkValidity = () => {
    if (this.state.loginEmail && this.state.loginPass) {
      this.setState({
        loginButtonEnabled: true
      })
    } else {
      this.setState({
        loginButtonEnabled: false
      })
    }
  }


  // Check if the user is logged in. Returns true if logged in, false if not.
  checkLoggedIn = async () => {
    var fetchStatus = 0;
    return await fetch('/api/super/logincheck', {
      method: 'GET',
      headers: {
            'Content-Type': 'application/json'
      }
    }).then(res => {
      fetchStatus = res.status;
      return fetchStatus === 200 ? true : false
    })
  }


  // submitLogin - when login form is submitted
  submitLogin = (event) => {
    event.preventDefault();       // Prevents refreshing of page
    // console.log(JSON.stringify(this.state));

    // Change the submit text
    this.setState({
      loginText: 'Logging in...',
      loginButtonEnabled: false,
    })

    var fetchStatus = 0;
    cogoToast.loading('Sending the smoke signal...').then(() => {
      fetch('/api/super', {
          method: 'POST',
          body: JSON.stringify(this.state),  // username and password
          headers: {
                'Content-Type': 'application/json'
          }
      }).then(res => {
        fetchStatus = res.status;
        return res.json()
      }).then(async res => {
        await this.context.setAuthToken(res.tokenToStore)
        cogoToast.success("Hold on tight, you're going for a ride.").then(async ()=> {
            
            this.setState({token: res.token}, () => {    
                this.props.history.push({
                pathname: (this.state.prevURL !== "/") ? this.state.prevURL : '/home',
                state: { prevState: this.state.prevState, prevURL: this.props.location.pathname }
                }); 
          })
        })
      }).catch(err => {
          if (err && (fetchStatus !== 401) && (fetchStatus !== 500)){
            // Payload issues fetch without payload
            console.log('Failed to fetch. Fetching without payload.')
            fetch('/api/super', {
              method: 'POST',
              body: JSON.stringify({
                username: this.state.username,
                loginEmail : this.state.loginEmail,
                loginPass: this.state.loginPass,
                //token: this.state.token,
              }),  // username and password
              headers: {
                    'Content-Type': 'application/json'
              }
              }).then(res => {
                return res.json()
              }).then(async res => {
                    await this.context.setAuthToken(res.tokenToStore)
                    cogoToast.success("It worked! Let's take you home now.").then(()=> {
                      this.setState({token: res.token}, () => {    
                        this.props.history.push({
                          // If we aren't pushing back to the login page, go to prevURL, else go /home
                        pathname: (this.state.prevURL !== "/") ? this.state.prevURL : '/home',
                        state: { prevState: undefined, prevURL: '/home' }
                        }); 
                      })
                    }) // end cogoToast
                    
              }).catch(err => {
                cogoToast.error('Error logging in. Error: ' + err);
                 // Change the submit text
                this.setState({
                  loginText: 'Log In',
                }, () => {this.checkValidity()})
              })
          } else if (fetchStatus === 401){ 
            cogoToast.error("Your credentials are either wrong, or you're not an admin.");
            // Change the submit text
            this.setState({
              loginText: 'Log In',
            }, () => {this.checkValidity()
            })
          } else if (fetchStatus === 500) {
            cogoToast.error('Hmm. The server appears to be down. I think you broke it.');
             // Change the submit text
             this.setState({
              loginText: 'Log In',
            }, () => {this.checkValidity()
            })
          } else {
              cogoToast.error("It's not working. I don't know why. Tell them this happened: " + err);
               // Change the submit text
              this.setState({
                loginText: 'Log In',
              }, () => {this.checkValidity()
              })
          }
        });
    })
    
    }
  




// Render the login page
    render = () => {
        return(
          <Container fluid className='mx-0 px-0'>
            <Col sm={8} className='px-0'>
              <Form className="mt-5 pr-5" onSubmit={this.submitLogin}>
                <Form.Row >
                  <Form.Group as ={Col} sm={6}>
                    {/* <Form.Label className="grey-text">1. Type your username...</Form.Label> */}
                    <Form.Control className="inputField my-3 text-left square-corners"
                      required
                      type="email"
                      name="loginEmail"
                      placeholder="Email Address"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as ={Col} sm={6}>
                  {/* <Form.Label className="grey-text">2. Then, your password...</Form.Label> */}
                    <Form.Control className="inputField my-3 text-left square-corners"
                      required
                      type="password"
                      name="loginPass"
                      placeholder="Your Password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    /> 
                  </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    {/* <Form.Label className="grey-text">3. Click!</Form.Label> */}
                    <Row className='ml-auto'>
                      <Link className="white-link my-auto mr-3" to={'/forgotpassword'}>I <b>forgot</b> my password.</Link>
                      <Button className=" my-3 ml-auto" disabled={!this.state.loginButtonEnabled} type="submit"><b>{this.state.loginText} <FontAwesomeIcon className='ml-3' icon={faChevronCircleRight} /></b></Button>
                      
                    </Row>
                    </Form.Row>
                  
                  
                  {/*<p className="white-text mt-1">Or, create an account...</p>*/}

              </Form>
            </Col>
          </Container>
        );
    }

}

export default withRouter(Login);