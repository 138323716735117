// NewBoard
//
// User interface component for creating a new board

// Usage: <NewBoard />



import React, { Component } from 'react';
import { Link } from 'react-router-dom';    // Allows to link to different views?
import { Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import FetchFailHandler from './_AdminFetchFailHandler'
import Dropzone from 'react-dropzone';
import {Container, Form, Jumbotron, Button, Row, Col, Card, CardGroup} from 'react-bootstrap';
import update from 'immutability-helper';
import Papa from 'papaparse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faMinus, faFileCsv} from '@fortawesome/free-solid-svg-icons'
import LoadingAnimation from './_AdminLoadingAnimation'
import Scrollbar from 'react-scrollbars-custom'
import AdminGetAPIBoards from './_AdminGetAPIBoards'
import AdminPublisherSelector from './_AdminPublisherSelector'
import fetch from './_AdminCustomFetch'
import "../styles/style.css"
import { is } from 'immutable';
import _AdminArtworkTable from './_AdminArtworkTable';



// Constants
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '2px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const PAPA_PARSE_CONFIG = {
	delimiter: "",	// auto-detect
	newline: "",	// auto-detect
	quoteChar: '"',
	escapeChar: '"',
	header: true,
	transformHeader: undefined,
	dynamicTyping: false,
	preview: 0,
	encoding: "",
	worker: false,
	comments: false,
	step: undefined,
	complete: undefined,
	error: undefined,
	download: false,
	downloadRequestHeaders: undefined,
	skipEmptyLines: false,
	chunk: undefined,
	fastMode: undefined,
	beforeFirstChunk: undefined,
	withCredentials: undefined,
	transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
  }


// NOTE:
// Remember to pass board owner organisation as prop to NewBoard i.e. <NewBoard org=BoardOwnerOrg />
// Also requires prop: team=teamID for allocating board to team

// Constants
const VENUETYPES = [
    'Retail', 'Transportation', 'Roadside', 'Point of care'
]

const SCREENTYPES = [
    'Media store', 'Railway Station', 'Highway billboard', 'Airport (parking)', 
    'Airport (lounge)', 'Airport', 'Airport (arrivals)', 'Airport (departures)', 'Airport (retail)', 'Airport (luggage belt)', 'Airport (check-in)',
    'Street furniture', 'Gas station (pump)', 'Gas station (shop entrance)',
    'Digital Bulletin', 'Digital bus shelter', 'Parking garage', 'Commercial Tower', 'Residential Tower',
    'Roadside', 'Doctors office', 'Medical center', 'Mall', 'Mall (Concourse)', 'Mall (Food court)', 
    'Supermarket', 'Office building', 'Convenience store', 'Veterinarian clinic', 'Cinema', 'Digital Poster',
    'Digital Urban Panels', 'Digital Transit Shelter', 'Sports complex'
]; // Reach inventory classifications


class NewBoard extends Component {
    constructor(props) {
      super(props)
        this.state = {
                

                // User variables
                teamlist: [],
                email: '',
                username: '',

                // The selected team
                managementTeam: this.props.team ? this.props.team : null,

               boardname: '',
                boardcoords: {
                    lat: '',
                    long: '',
                },
                boardloc: {
                    city: '',
                    state: '',
                    country: '',
                },   // Location name (city)
                boardsize: {
                    height: '', 
                    width: '',
                },
                boardminCPI: '',
                surfaceArea: '',
                boardOwner: this.props.org,	    //board owner is an organisation
                //team: this.props.team,          // Team that manages board
                // Broadsign parameters (for Reach)
                device_id: '',
                opening_hours: '',
                screenType: '',
                venueType: '0',
                // adTypes: [''],  // array of strings

                // Form validation
                latitudeValid: true,
                longitudeValid: true,


            // New board array (array of boards to add for when multiple boards are added)
            newBoardArray: [],

            // Files for CSV upload
            files: [],
            csvParseButtonActive: true,
            csvParseText: 'Load Boards',
            boardsJSON: '',
             // Response text


            // Params for load via Reach API
            selectedPublisher: {},

            // Current set of boards owned by org
            currentBoardIDs: [],      // Array of IDs
            currentBoardObjects: [],  // Array of objects 

            // Forms
            manualBoardLoaderFormVisible: false,
            csvBoardLoaderFormVisible: false,
            apiBoardLoaderFormVisible: false,

           

            // Other state vars
            isLoaded: true,

        }   // end this.state
    }   // end constructor


    


    // Called after component renders to screen
    componentDidMount = () => {
        //
        //this.getData();
        
    }

    // Get dropzone colours and styles
    getColor = (obj) => {
        if (obj.isDragAccept) {
            console.log('accepting drag')
            return '#00e676';

        }
        if (obj.isDragReject) {
            console.log('rejecting drag')
            return '#ff1744';
        }
        if (obj.isDragActive) {
            console.log('active drag')
            return '#2196f3';
        }
        console.log('not accepting, rejecting or active')
        return '#eeeeee';
  }

    // Get container style (dynamic) for dropzone
    getContainerStyle = (obj) => {

        var newBorderColor = this.getColor(obj);


        var containerStyle = 
        {flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '5px',
        borderColor: newBorderColor,
        borderStyle: 'dashed',
        backgroundColor: '#00232d',
        color: '#f8f8f8',
        outline: 'none',
        transition: 'border .24s ease-in-out'
        };

        return containerStyle;
    }

    // Get data 
    getData = () => {

    }

    // Perform the fetching for a board to be created
    fetchCreateBoard = (boardToCreate) => {
        var fetchStatus = 0;
        fetch('/api/boards/create', {
            method: 'POST',
            body: JSON.stringify({
                    boardname: boardToCreate.boardname ? boardToCreate.boardname : '',
                    boardcoords: {
                        lat: boardToCreate.boardcoords.lat ? boardToCreate.boardcoords.lat : '',
                        long: boardToCreate.boardcoords.long ? boardToCreate.boardcoords.long : '',
                    },
                    boardloc: {
                        city: boardToCreate.boardloc.city ? boardToCreate.boardloc.city : '',
                        state: boardToCreate.boardloc.state ? boardToCreate.boardloc.state : '',
                        country: boardToCreate.boardloc.country ? boardToCreate.boardloc.country : '',
                    },   // Location name (city)
                    boardsize: {
                        height: boardToCreate.boardsize.height ? boardToCreate.boardsize.height : '', 
                        width: boardToCreate.boardsize.width ? boardToCreate.boardsize.width : '',
                    },
                    boardminCPI: '',
                    surfaceArea: boardToCreate.surfaceArea ? boardToCreate.surfaceArea : '',
                    boardOwner: boardToCreate.boardOwner ? boardToCreate.boardOwner : '',	    //board owner is an organisation
                    // Broadsign parameters (for Reach)
                    reachBoardID: boardToCreate.reachBoardID ? boardToCreate.reachBoardID : '',
                    device_id: boardToCreate.device_id ? boardToCreate.device_id : '',
                    publisher_id: boardToCreate.publisher_id ? boardToCreate.publisher_id : '',
                    publisher_name: boardToCreate.publisher_name ? boardToCreate.publisher_name : '',
                    opening_hours: boardToCreate.opening_hours ? boardToCreate.opening_hours : '',
                    screenType: boardToCreate.screenType ? boardToCreate.screenType : '',
                    venueType: boardToCreate.venueType ? boardToCreate.venueType : '',
                    // adTypes: boardToCreate.adTypes ? boardToCreate.adTypes : '',  // array of strings

                    team: this.state.managementTeam
                }
            ),  // username and password
            headers: {
              'Content-Type': 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            // Now that we've created boards, get boards
            console.log('created a board... ' + res.board._id)
            
        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })
    }


    // Perform the fetching for a board to be updated
    fetchUpdateBoard = (boardToUpdate, boardToCreate) => {
        console.log('boardToUpdate: ' + JSON.stringify(boardToUpdate))
        console.log('boardToCreate: ' + JSON.stringify(boardToCreate))
        var fetchStatus = 0
        fetch('/api/boards/modify/' + boardToUpdate._id, {
            method: 'POST',
            body: JSON.stringify({
                    boardname: boardToCreate.boardname ? boardToCreate.boardname : '',
                    boardcoords: {
                        lat: boardToCreate.boardcoords ? boardToCreate.boardcoords.lat : '',
                        long: boardToCreate.boardcoords ? boardToCreate.boardcoords.long : '',
                    },
                    boardloc: {
                        city: boardToCreate.boardloc ? boardToCreate.boardloc.city : '',
                        state: boardToCreate.boardloc ? boardToCreate.boardloc.state : '',
                        country: boardToCreate.boardloc ? boardToCreate.boardloc.country : '',
                    },   // Location name (city)
                    boardsize: {
                        height: boardToCreate.boardsize ? boardToCreate.boardsize.height : '', 
                        width: boardToCreate.boardsize ? boardToCreate.boardsize.width : '',
                    },
                    boardminCPI: boardToCreate.boardminCPI ? boardToCreate.boardminCPI : '',
                    surfaceArea: boardToCreate.surfaceArea ? boardToCreate.surfaceArea : '',
                    //boardOwner: boardToCreate.boardOwner ? boardToCreate.boardOwner : '',	    //board owner cannot be edited through this route
                    // Broadsign parameters (for Reach)
                    reachBoardID: boardToCreate.reachBoardID ? boardToCreate.reachBoardID : '',
                    device_id: boardToCreate.device_id ? boardToCreate.device_id : '',
                    publisher_id: boardToCreate.publisher_id ? boardToCreate.publisher_id : '',
                    publisher_name: boardToCreate.publisher_name ? boardToCreate.publisher_name : '',
                    opening_hours: boardToCreate.opening_hours ? boardToCreate.opening_hours : '',
                    screenType: boardToCreate.screenType ? boardToCreate.screenType : '',
                    venueType: boardToCreate.venueType ? boardToCreate.venueType : '',
                    // adTypes: boardToCreate.adTypes ? boardToCreate.adTypes : '',  // array of strings

                    team: this.state.managementTeam
                }
            ),  // username and password
            headers: {
              'Content-Type': 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            // Now that we've updated boards, get boards
            
            console.log('updated a board... ' + res.board._id)
            
        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })
    }



    // Create a board based on the provided information
    createBoards = () => {
        // Organisation that created the board is available in this.props.org
        // Create a board...
    
        var boardArrayIterator = 0;
        this.state.newBoardArray.forEach(boardToCreate => {
            // Check if each board exists from its 'device_id' and if it does, reject creation and perform update instead.
            if (boardToCreate.device_id) {
                var fetchStatus = 0;
                fetch('/api/boards/findbydeviceid/' + boardToCreate.device_id, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                      }
                }).then(res => {
                    fetchStatus = res.status;
                    return res.json();
                }).then(res => {
                    // Response...
                    if (res.boards) {
                        console.log('Found board by deviceID. Message: ' + JSON.stringify(res.message))
                        // Board exists. Perform board update instead of board creation.
                        res.boards.forEach(boardToUpdate => {
                            this.fetchUpdateBoard(boardToUpdate, boardToCreate)        // boardToCreate is the same board with updated params as  boardToUpdate
                            boardArrayIterator = boardArrayIterator + 1;
                        })
                    } else {
                        // Board does not exist, create it.
                        this.fetchCreateBoard(boardToCreate)
                        boardArrayIterator = boardArrayIterator + 1;
                    }     
                }).catch(err => {
                    console.log('fetch error: ' + err)
                    FetchFailHandler(this.props, this.state, fetchStatus)
                })
            } else {
                // device_id does not exist... Create the board.
                this.fetchCreateBoard(boardToCreate)
                boardArrayIterator = boardArrayIterator + 1;
            }


              
              // When done iterating
              if (boardArrayIterator === this.state.newBoardArray.length){
                  // Done iterating. Update state.
                  this.getData();
              }
        })

    }
  
//  -------------------------------------- FOR SINGLE BOARD ------------------------------------------

// Create boards manually (i.e. push the board details into newBoardArray and pass it to createBoards)
    createBoardsManually = (event) => {
        event.preventDefault();
        // Use the current state variables, push into array
        var boardToPush = {
            boardname: this.state.boardname ? this.state.boardname : '',
            boardcoords: {
                lat: this.state.boardcoords ? this.state.boardcoords.lat : '',
                long: this.state.boardcoords ? this.state.boardcoords.long : '',
            },
            boardloc: {
                city: this.state.boardloc ? this.state.boardloc.city : '',
                state: this.state.boardloc ? this.state.boardloc.state : '',
                country: this.state.boardloc ? this.state.boardloc.country : '',
            },   // Location name (city)
            boardsize: {
                height: this.state.boardsize ? this.state.boardsize.height : '', 
                width: this.state.boardsize ? this.state.boardsize.width : '',
            },
            boardminCPI: this.state.boardminCPI ? this.state.boardminCPI : '',
            surfaceArea: this.state.surfaceArea ? this.state.surfaceArea : '',
            boardOwner: this.state.boardOwner ? this.state.boardOwner : '',	    //board owner is an organisation
            // Broadsign parameters (for Reach)
            device_id: this.state.device_id ? this.state.device_id : '',
            opening_hours: this.state.opening_hours ? this.state.opening_hours : '',
            screenType: this.state.screenType ? this.state.screenType : '',
            venueType: this.state.venueType ? this.state.venueType: '',
            // adTypes: this.state.adTypes ? this.state.adTypes : '',  // array of strings
        }

        var tempBoardArray = this.state.newBoardArray;
        tempBoardArray.push(boardToPush);
        this.setState({
            newBoardArray: tempBoardArray
        }, () => {
            // Created temp board array and pushed into state
            console.log('this.state.newBoardArray: ' + JSON.stringify(this.state.newBoardArray))
            this.createBoards();
        })
    }


//  -------------------------------------- FOR SINGLE BOARD ------------------------------------------


// HANDLER FUNCTIONS

// Check form validity (latitude, longitude, etc)
// TODO: make this a function that CSV uploaded passes all new boards through
checkValidity = () => {
    // Use the current state to see whether inputs are valid
    if (this.state.boardcoords.lat ){
      // Latitude must be between -90 and 90 degrees
      if (Number(this.state.boardcoords.lat) < 90 && Number(this.state.boardcoords.lat) > -90){
          //Latitude valid
          this.setState({
              latitudeValid: true
          })
      } else {
          //Latitude not valid
          this.setState({
            latitudeValid: false
        })
      }
    } // end check latitude

    if (this.state.boardcoords.long ){
        // Longitude must be between -180 and 180 degrees
        if (Number(this.state.boardcoords.lat) < 180 && Number(this.state.boardcoords.lat) > -180){
            //Latitude valid
            this.setState({
                longitudeValid: true
            })
        } else {
            //Latitude not valid
            this.setState({
              longitudeValid: false
          })
        }
      } // end check latitude
  
  

  
  }

// Handle JSON formatted boards
handleJSONBoards = (boardsJSON) => {
    //
    //console.log("boards parsed: " + JSON.stringify(boardsJSON))
    this.setState({
        boardsJSON: boardsJSON,
        csvParseText: 'Loaded',
        csvParseButtonActive: false
    }, () => {
        // Now create a board array (to add) using this data
        // Each elem of the JSON object is a row. Row 1 is the header.
        console.log('setState to boardsJSON: ' + JSON.stringify(this.state.boardsJSON))
        var tempBoardArray = []
        var boardIterator = 0;
        var tempJSONBoards = this.state.boardsJSON.data;
        console.log('before for loop: ' + JSON.stringify(tempJSONBoards))
        tempJSONBoards.forEach((row) => {
            boardIterator = boardIterator + 1;
            console.log('for loop entered...' + JSON.stringify(row))
            // Don't Ignore first row since we are excluding it using Papaparse
            // Create board in boardArray using JSON file...
            var tempBoard = {
                boardname: row['screen_name'], //row[2],
                boardcoords: {
                    lat: row['latitude'],
                    long: row['longitude'],
                },
                boardloc: {
                    city: row['City'],
                    state: row['Region'],
                    country: row['Country'],
                },   // Location name (city)
                boardsize: {
                    height: (row['Screen Resolution']).split('x')[0],   // For '1920x1080' format
                    width: (row['Screen Resolution']).split('x')[1],    // For '1920x1080' format
                },
                boardminCPI: '',
                surfaceArea: '',
                boardOwner: this.props.org,	    //board owner is an organisation
                team: this.props.team,          // Team that manages board
                // Broadsign parameters (for Reach)
                reachBoardID: row['id'],
                device_id: row['device_id'],
                publisher_id: row['publisher_id'],
                publisher_name: row['publisher_name'],
                opening_hours: '',  // TODO: Handle this
                screenType: row['Screen Type'],
                venueType: row['Venue Type'],
                adTypes: row['Ad Types'],  // array of strings
            }

            console.log('tempBoard: ' + JSON.stringify(tempBoard))

            tempBoardArray.push(tempBoard);
            // Once all have been iterated over
            if (boardIterator === tempJSONBoards.length) {
                console.log('completed pushing...')
                this.setState({
                    newBoardArray: tempBoardArray
                }, () => {
                    console.log('newBoardArray: ' + JSON.stringify(this.state.newBoardArray))
                    // Create boards with newBoardArray (Check for existing boards before this goes through)
                    this.createBoards();
                })
            }
        })

    })
}


// React dropzone On Drop behaviour
onDrop = (files) => {

    /*const reader = new FileReader();
    reader.onload = () => {
        csv.parse(reader.result, (err, data) => {
            console.log('data' + data);
        });
        reader.readAsBinaryString(files[0]);
    }*/

    this.setState({
        files: files,
        csvParseButtonActive: true,
        csvParseText: 'Load Boards'
    }, () => {
        // 
        console.log('dropped files' + JSON.stringify(this.state.files));
        // Create previews of the files
    })
};

// Remove files behaviour
removeFile = (event, fileIndex) => {
    event.preventDefault();
    event.stopPropagation();    // Prevents button below being clicked
    var newFiles = this.state.files;
    newFiles.splice(fileIndex, 1);

    this.setState({
        files: newFiles
    }, () => {
        console.log('Removed files')
    })
}

// Handle venue selection change
handleVenueSelectionChange = (event) => {
    console.log('changed venue type selection')
    this.setState({venueType: event.target.value});
  }

// Handle screen type selection change
handleScreenTypeSelectionChange = (event) => {
    console.log('changed screen type selection')
    this.setState({screenType: event.target.value});
  }

// Handle the input change
  handleFormInput = (event) => {
    event.preventDefault();
    const { value, name } = event.target;

    console.log('value')
    // Manually set nested variables...
    if (name === 'lat'){
        // Create a new state variable
        let newState = update(this.state, {
            boardcoords: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState, () => {
            this.checkValidity();
        });
    } else if (name === 'long'){
        // Create a new state variable
        let newState = update(this.state, {
            boardcoords: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState);

    } else if (name === 'city'){
        // Create a new state variable
        let newState = update(this.state, {
            boardloc: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState);
    } else if (name === 'state'){
        // Create a new state variable
        let newState = update(this.state, {
            boardloc: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState);
    } else if (name === 'country'){
        // Create a new state variable
        let newState = update(this.state, {
            boardloc: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState);
    } else if (name === 'height') {
        // Create a new state variable
        let newState = update(this.state, {
            boardsize: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState);
    } else if (name === 'width') {
        // Create a new state variable
        let newState = update(this.state, {
            boardsize: {
                [name]: {$set: value}
            }
        });
        // Update the state
        this.setState(newState);
    } else {
        this.setState({
            [name]: value
          });
    }
  }


    
    // Handle manual upload selection (show manual upload form)
    handleManualUploadButton = (event) => {
        // Set manual = on
        this.setState({
            manualBoardLoaderFormVisible: !this.state.manualBoardLoaderFormVisible,
            csvBoardLoaderFormVisible: false,
            apiBoardLoaderFormVisible: false,
        })
    }

    // Handle manual upload selection (show manual upload form)
    handleCSVUploadButton = (event) => {
        // Set csv = on
        this.setState({
            csvBoardLoaderFormVisible: !this.state.csvBoardLoaderFormVisible,
            manualBoardLoaderFormVisible: false,
            apiBoardLoaderFormVisible: false,
        })
    }

    // Handle api call button
    handleAPIButton = () => {
    // Set api = on
        this.setState({
            apiBoardLoaderFormVisible: !this.state.apiBoardLoaderFormVisible,
            manualBoardLoaderFormVisible: false,
            csvBoardLoaderFormVisible: false
        })
    }



    // Parse the CSV and load new boards into newBoards
    handleCSVParse = (event) => {
        event.preventDefault();
        event.stopPropagation();      // Prevents button below from being clicked
            // Parse the csv

            // Change button text
            this.setState({
            csvParseText: 'Loading...'
            }, () => {
                // Parse the csv here, then set the csvParseText
                var parseConfig = PAPA_PARSE_CONFIG;
                
                // The callback
                parseConfig.complete = this.handleJSONBoards;
                
                // The loop
                this.state.files.forEach(file => {
                    Papa.parse(file, PAPA_PARSE_CONFIG);
                })
            }) // end setstate
    } // end handleCSVParse

    handleSelectionChange = (event) => {
        console.log('changed selection')
        this.setState({managementTeam: event.target.value});
      }


      // When the publisher has been chosen, pass this into the board table
    onPublisherSelection = (selectedPublisher) => {
        // set the selected publisher in state
        console.log('Publisher changed: ' + JSON.stringify(selectedPublisher))
        this.setState({
            selectedPublisher: selectedPublisher
        })
    }

    // Render team options for the dropdown menu
    renderVenueOptions = () => {   
        if(this.state.isLoaded) {
            return (
                VENUETYPES.map((venue, index) => (
                    <option key = {venue} value = {venue}>
                        {venue}
                    </option>
                ))

            )
        } else {
            return (<LoadingAnimation />)
        }
    }

    // Render screen type options for the dropdown menu
    renderScreenTypeOptions = () => {   
        if(this.state.isLoaded) {
            return (
                SCREENTYPES.map((screenType, index) => (
                    <option key = {screenType} value = {screenType}>
                        {screenType}
                    </option>
                ))

            )
        } else {
            return (<LoadingAnimation />)
        }
    }

    

  // Render the appropriate form
  renderForm = () => {
      console.log('this.state in renderForm: ' + JSON.stringify(this.state))
      if (this.state.isLoaded){
        if (this.state.manualBoardLoaderFormVisible && !this.state.csvBoardLoaderFormVisible){
            return (

                <Container fluid className ='my-5'>
                    <h4 className="white-text mx-0">Some details about your <b>board</b>.</h4>
                    <Form onSubmit={e => this.createBoardsManually(e) } className ='mt-5'>
                        <Form.Row>
                            <Form.Group as={Col} className='my-3'>
                            <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                type="text"
                                placeholder={"Your board's name. Make it unique."}
                                name="boardname"
                                onChange={this.handleFormInput}
                                value={this.state.boardname}
                            />
                            </Form.Group>

                        </Form.Row>

                        <h4 className="white-text my-5">About its <b>location.</b></h4>
                        <Form.Row className='mt-3'>
                            <Form.Group as={Col}>
                                <Form.Control className = 'inputField my-3 text-left square-corners'
                                    required
                                    type="number"
                                    placeholder="Its latitude"
                                    name="lat"
                                    onChange={this.handleFormInput}
                                    value={this.state.boardcoords ? this.state.boardcoords.lat : ''}
                                    isValid={this.state.latitudeValid}
                                    isInvalid={!this.state.latitudeValid}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-3'>
                                <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                    type="number"
                                    placeholder={"Its longitude"}
                                    name="long"
                                    onChange={this.handleFormInput}
                                    value={this.state.boardcoords ? this.state.boardcoords.long : ''}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className='mt-3'>
                            <Form.Group as={Col}>
                                <Form.Control className = 'inputField my-3 text-left square-corners'
                                    required
                                    type="text"
                                    placeholder="The country it's in"
                                    name="country"
                                    onChange={this.handleFormInput}
                                    value={this.state.boardloc ? this.state.boardloc.country : ''}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-3'>
                                <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                    type="text"
                                    placeholder={"The city"}
                                    name="city"
                                    onChange={this.handleFormInput}
                                    value={this.state.boardloc ? this.state.boardloc.city : ''}
                                />
                            </Form.Group>

                        </Form.Row>
                        <h4 className="white-text mt-5">About the board and its venue.</h4>
                        <Form.Row className='mt-3'>
                            <Form.Control as="select" className = 'custom-select-dropdown my-3 text-left square-corners' value={this.state.venueType} onChange={this.handleVenueSelectionChange}>
                                    <option value='0'>Choose the type of venue this board is at</option>
                                    {this.renderVenueOptions()}
                            </Form.Control>
                        </Form.Row>
                        <Form.Row className='mt-3'>
                            <Form.Control as="select" className = 'custom-select-dropdown my-3 text-left square-corners' value={this.state.screenType} onChange={this.handleScreenTypeSelectionChange}>
                                    <option value='0'>Choose the type of screen this is</option>
                                    {this.renderScreenTypeOptions()}
                            </Form.Control>
                        </Form.Row>

                        <h4 className="white-text my-5">Some tech specs for <b>the buyers</b>.</h4>
                        <Form.Row className='mt-3'>
                            <Form.Group as={Col}>
                                <Form.Control className = 'inputField my-3 text-left square-corners'
                                    required
                                    type="number"
                                    placeholder="Vertical Resolution"
                                    name="height"
                                    onChange={this.handleFormInput}
                                    value={this.state.boardsize ? this.state.boardsize.height : ''}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-3'>
                                <Form.Control className = 'inputField text-left square-corners blue-grey-text'
                                    type="text"
                                    placeholder={"Horizontal Resolution"}
                                    name="width"
                                    onChange={this.handleFormInput}
                                    value={this.state.boardsize ? this.state.boardsize.width : ''}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className='mt-3'>
                            <Form.Group as={Col}>
                                <Form.Control className = 'inputField my-3 text-left square-corners'
                                    required
                                    type="number"
                                    placeholder="Its surface area (in square metres)"
                                    name="surfaceArea"
                                    onChange={this.handleFormInput}
                                    value={this.state.surfaceArea ? this.state.surfaceArea : ''}
                                />
                            </Form.Group>
                            <Form.Group as={Col} className='my-3'>
                            </Form.Group>
                        </Form.Row>

                    <Button className="float-right mt-3"  type="submit">Save Changes</Button>
                    <p className="caption-small grey-text">{this.state.onSave}</p>
                </Form>
        </Container>
            )  // manual board loader
        } else if (this.state.csvBoardLoaderFormVisible && !this.state.manualBoardLoaderFormVisible) {
            const thumbs = this.state.files.map((file, index) => (
                <Container fluid key={file.name}>
                    <Row >
                    <Col sm={1}>
                        <Button className='text-button-green' onClick = {e => this.removeFile(e, file)}><FontAwesomeIcon className="" icon={faMinusCircle}/></Button>
                    </Col>
                    <Col sm={3} >
                        <div style={thumb} >
                            <div className ='float-right' style={thumbInner}>
                                <FontAwesomeIcon icon={faFileCsv}/>
                            </div>
                        </div>
                    </Col>
                    <Col sm={7}>
                        <p className='grey-text noto-sans bold-text caption-smaller'key={file.name}>
                        {file.name} - {file.size} bytes
                        </p>
                        {(this.state.csvParseButtonActive) ? <Button className='caption-smaller' active onClick = {e => this.handleCSVParse(e)}>{this.state.csvParseText}</Button> : <Button className='caption-smaller' disabled onClick = {e => this.handleCSVParse(e)}>{this.state.csvParseText}</Button>}
                        
                    </Col>
                    </Row>
                </Container>
                
                
            ));

            return (
            <Scrollbar className = 'customScroll' noScrollX noScrollY >
            <Container className='my-5'>
            <Dropzone accept={'text/csv'} onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {

                    return (
                        <div> 
                            <div {...getRootProps()} style={this.getContainerStyle({isDragActive: isDragActive, isDragAccept: isDragAccept, isDragReject: isDragReject})}>

                                <input {...getInputProps()} />
                                <Container>
                                <p className="noto-sans grey-text caption-small bold-text">Drag 'n' drop some files here, or click to select files</p>
                                    {isDragAccept && (<p>All files will be accepted</p>)}
                                    {isDragReject && (<p>Some files will be rejected</p>)}
                                    {!isDragActive && (<p>Drop some files here ...</p>)}
                                    <aside style={thumbsContainer}>
                                    {thumbs}
                                    </aside>
                                </Container>
                            </div>
                        </div>

                    );
                }}
            </Dropzone>
        </Container>
        </Scrollbar>);
        } else if (this.state.apiBoardLoaderFormVisible && !this.state.csvBoardLoaderFormVisible && !this.state.manualBoardLoaderFormVisible){
            return (
                <>
                <AdminPublisherSelector onSelect = {this.onPublisherSelection} />
                {this.state.selectedPublisher.id ? <AdminGetAPIBoards selectedPublisher = {this.state.selectedPublisher}/> : '' }
                </>
            )
        }
    }
}

  // Render function
    render = () => {
        if (this.state.isLoaded){
        return(
            // <Scrollbar translateContentSizeYToHolder >
            <Container fluid >
                        <Container fluid className="header-cards-content my-5">
                        <h2 className="white-text" style={{fontSize: 3 + 'rem'}}><b>Add a new board.</b></h2>
                        <p className="white-text">Setting up a board for team: <b>{this.state.managementTeam}</b></p>
                        
                        <h4 className="white-text mb-5">You can either upload a board manually or by uploading a CSV.</h4>
                            <Row>
                                <Col sm={4} md={3}>
                                    <Button className = "tab" onClick= {this.handleManualUploadButton} active={this.state.manualBoardLoaderFormVisible}>
                                            <Row>
                                                <Col className="px-5 pt-3">
                                                    <Row className="text-left"><h4 className="noto-sans grey-text"><b>MANUAL UPLOAD</b></h4></Row>
                                                    <Row className="text-left"><p className="fira-sans caption-small white-text">Upload a single board manually.</p></Row>
                                                </Col>                               
                                            </Row>
                                    </Button>
                                </Col>
                                <Col sm={4} md={3}>
                                    <Button className = "tab" onClick= {this.handleCSVUploadButton} active={this.state.csvBoardLoaderFormVisible}>
                                            <Row>
                                                <Col className="px-5 pt-3">
                                                    <Row className="text-left"><h4 className="noto-sans grey-text"><b>CSV UPLOADER</b></h4></Row>
                                                    <Row className="text-left"><p className="fira-sans caption-small white-text">Upload multiple boards from a CSV file.</p></Row>
                                                </Col>                               
                                            </Row>
                                    </Button>
                                </Col>

                                <Col sm={4} md={3}>
                                    <Button className = "tab" onClick= {this.handleAPIButton} active={this.state.apiBoardLoaderFormVisible}>
                                            <Row>
                                                <Col className="px-5 pt-3">
                                                    <Row className="text-left"><h4 className="noto-sans grey-text"><b>API CALL</b></h4></Row>
                                                    <Row className="text-left"><p className="fira-sans caption-small white-text">Get boards from a remote server (e.g. Reach).</p></Row>
                                                </Col>                               
                                            </Row>
                                    </Button>
                                </Col>
                            </Row>
                            <Container className='mt-5'>
                                {this.renderForm()}
                            </Container>


                        </Container>
                </Container>
                // </Scrollbar>
                    );
        }  else {
            return (<LoadingAnimation />)
        }
        
    }
} // end component

// Exports
export default withRouter(NewBoard);

const CreateNewBoard = () => {
    return(
        <div>
            <NewBoard/>
        </div>
    )
}


/*
<form onSubmit={this.createBoard}>
                <h1>Create a board</h1>
                <input
                    type="text"
                    name="boardname"
                    placeholder="Enter desired board name"
                    value={this.state.boardname}
                    onChange={this.handleInputChange}
                    required
                />
                <h2>Your board's location</h2>
                <input
                    type="text"
                    name="lat"
                    placeholder="Enter latitude of board"
                    value={this.state.boardcoords.lat}
                    onChange={this.handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="long"
                    placeholder="Enter longitude of board"
                    value={this.state.boardcoords.long}
                    onChange={this.handleInputChange}
                    required
                />
                <h2>Your board's size</h2>
                <input
                    type="text"
                    name="height"
                    placeholder="Enter height of board"
                    value={this.state.boardsize.height}
                    onChange={this.handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="width"
                    placeholder="Enter width of board"
                    value={this.state.boardsize.width}
                    onChange={this.handleInputChange}
                    required
                />
                <h2>Your board's minimum cost per impression</h2>
                <input
                    type="name"
                    name="boardminCPI"
                    placeholder="Enter minimum cost-per-impression (CPI)"
                    value={this.state.boardminCPI}
                    onChange={this.handleInputChange}
                    required
                />
                <input type="submit" value="Submit"/>
            </form>
            */