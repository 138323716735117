// The fetch fail handler function
// Handles fetch failures by passing the state and previous url to the login screen if there is an authentication failure

// Imports
// Import dependencies
import React, { Component, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import ReactDOM from "react-dom";
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import fetch from './_AdminCustomFetch'

export default function FetchFailHandler(props, state, fetchStatus) {
    
        
        // TODO: Remove any state variables that contain HTML before sending
        var stateVarIterator = 0
        var StateObj = Object.keys(state);
        console.log('stateobj: ' + JSON.stringify(StateObj))

        var stateToSend =  []
        if (props.history){
            // Iterate over state and only send objects that are not HTML objects
            StateObj.forEach(key => {
                stateVarIterator = stateVarIterator + 1;
                if (!isNode(state[key]) && !isElement(state[key])) {
                    // Is not a HTML DOM element/node. Add to StateToSend
                    stateToSend.push(state[key])
                }
                // If done iterating
                if (stateVarIterator === StateObj.length){
                    // Push state via history
                    if (fetchStatus === 401){
                        try {
                            props.history.push({
                                pathname: '/',
                                state: { prevState: stateToSend, prevURL: props.location.pathname }
                            })
                        } catch (err) {
                            console.log('failed to push state')
                            cogoToast.error('Something went wrong. Taking you back home.')
                        }
                        
                    }else if (fetchStatus === 500 || fetchStatus === 502){
                        try {
                            
                            let {hide} = cogoToast.error("Something went wrong. Try refreshing the page after some time.", {
                                hideAfter:0,
                                onClick: () => {
                                    hide();
                                    },
                                }, () => {
                                setTimeout(function(){ window.location.reload() } , 1000);
                            })
                            
                        } catch (err) {
                            console.log('failed to push state: ' + err)
                            // cogoToast.error('Something went wrong. Taking you back home.').then(() => {
                            //     //props.history.push('/home');
                            // })
                            
                        }
                    } else if (fetchStatus === 403) {
                        try {
                            
                            let {hide} = cogoToast.error("Hmm. It appears that you're not allowed to view this page. Try going back home.", {
                                hideAfter:0,
                                onClick: () => {
                                    hide();
                                    },
                            
                            }, () => {
                                // ..
                            })
                            
                            
                        } catch (err) {
                            console.log('failed to push state: ' + err)
                            // cogoToast.error('Something went wrong. Taking you back home.').then(() => {
                            //     //props.history.push('/home');
                            // })
                            
                        }
                    
                    }else {    // Not 500 or 401 errors. Try reloading on interval
                        try {
                            //setInterval( window.location.reload(), 5000 )
                            
                            
                        } catch (err) {
                            console.log('failed to push state')
                            cogoToast.error('Something went wrong. Taking you back home.').then(() => {
                                //props.history.push('/home');
                                props.history.push('/')
                            })
                            
                        }
                    }
                }
            })
        } else {
            // Redirect home if we fail to go back successfully
            //cogoToast.error('Something went wrong. Taking you back home.')
            //window.location.href = "https://app.caasie.co/home";
            return; // return nothing
        }
        //window.location.href = "https://app.caasie.co/home";
        //console.log('state before pushing: ' + JSON.stringify(state)) 
}


// Functins to check if state variables are nodes/elements
// https://stackoverflow.com/questions/384286/javascript-isdom-how-do-you-check-if-a-javascript-object-is-a-dom-object

//Returns true if it is a DOM node
function isNode(o){
    return (
      typeof Node === "object" ? o instanceof Node : 
      o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName==="string"
    );
  }
  
  //Returns true if it is a DOM element    
  function isElement(o){
    return (
      typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
      o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
  );
  }


