// TABLE OF APPROVED ARTWORK

// Uses react-table to display a table of files (artworks), sorted by unapproved, etc.

// Usage: <ApprovedArtwork onUpdate = function()/>

// Imports
// Import dependencies
import React, { Component, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import ReactDOM from "react-dom";
import fetch from './_AdminCustomFetch'
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import selectedOrgContext from '../components/_AdminOrganisationContext'
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import DatePicker from "react-datepicker";
import moment from 'moment'
import Cookies from 'js-cookie';
import NavBar from "../components/_AdminNavBar"
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle, faTrash, faCheckCircle, faEnvelope, faEnvelopeOpen, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import FetchFailHandler from '../components/_AdminFetchFailHandler'
import LoadingAnimation from './_AdminLoadingAnimation'
import ArtworkDisapprovalForm from './_AdminArtworkDisapprovalForm'
import ModalPopup from './_AdminModalPopup'
import _ from 'lodash'
import { Transition, Spring, animated, template } from 'react-spring/renderprops'


import "../styles/style.css"
import { minWidth } from '@material-ui/system';
import {
    XYPlot,
    XAxis,
    YAxis,
    ArcSeries,
    ChartLabel
  } from 'react-vis';
import { min } from 'date-fns';
  
  const PI = Math.PI;
  
  const MARGIN = {
      left: 1, right: 1, top: 1, bottom: 1
    };
    

class ApprovedFileTable extends Component {
    static contextType = selectedOrgContext;    // Using context outside render -> not working yet
    constructor(props) {
        super(props)  
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {
            files: [],
            fileDataArray: [],
            totalFiles: 0,

            // Checkboxes for bulk select
            fileCheckboxes: [],

            // Table
            fileTablePage: 1,
            fileTablePageSize: 5,
            cursorLocation: (5 * 1) - 5,        //(artworkTablePage * artworkTablePageSize) - artworkTablePageSize,
            totalPages: -1,
            sorted: [{}],
            filtered: [],
            isFetching: false,

            // Modals
            disapprovalModal: {show: false},
            disapprovalModalContent: '',

            // bulkActionsVisible
            bulkActionsVisible: false,

            isLoaded: false,

            }   // end this.state


    }   // end constructor


    // ----------------------------------- DEFAULT FUNCTIONS -------------------------------------- //


    // componentdidmount() function
    componentDidMount = () => {
        this.checkPreviousStateAndRestore();
        // Get the team data
        this.getData()
        
    }

    // COmponent did update
    componentDidUpdate = (prevProps, prevState) => {
        // If our input props change then re-load component
        if ((this.props.shouldUpdate) && (this.props.shouldUpdate !== prevProps.shouldUpdate)) {
            console.log('Updating disapproval table') 
            this.setState({isFetching: true}, () => {
                var fetchParams = {
                    pageSize: this.state.fileTablePageSize,
                    page: this.state.fileTablePage,
                    sorted: this.state.sorted ? this.state.sorted : [{'id' : 'fileName', 'desc' : true}],
                    filtered: []
                }
                this.fetchData(fetchParams, null)
            })
            // return await this.props.onUpdate()
               // Force re-render then mark update as complete
        }

    }


    // ----------------------------------- DEFAULT FUNCTIONS -------------------------------------- //


    // ----------------------------------- UTILITY FUNCTIONS -------------------------------------- //

    // Restores previous state if it exists (passed in as props through react router)
    checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            //this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                            return true;
                        }
                    })
                } else {return false}
        }
    }


    // ----------------------------------- UTILITY FUNCTIONS -------------------------------------- //




    // ----------------------------------- GET FUNCTIONS -------------------------------------- //




    // Fetch files given pageSize, page, sorted and filtered data
    fetchFiles = async  (pageSize, page, sorted, filtered) => {

        console.log('pageSize', JSON.stringify(pageSize))
        console.log('page', JSON.stringify(page))
        console.log('sorted', JSON.stringify(sorted[0]))
        console.log('filtered', JSON.stringify(filtered))

        console.log('sorted[0].desc: ' + sorted[0].desc)

        // Update the state with the table details
        this.setState({
            fileTablePage: page,
            fileTablePageSize: pageSize,
            sorted: sorted,
            filtered: filtered
        })

        return await fetch('/api/file/?pageSize=' + pageSize + '&page=' + page + '&sortID=' + sorted[0].id + '&sortDesc=' + sorted[0].desc + '&approvedOnly=true', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            // Res will contain unsorted files ...?
            return res.json()
        }).then( res => {
            var rows = res.files;
            var pages = res.totalFiles / pageSize;
            

            // Filter the data
            // TODO: Implement this (https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/archives/v6-examples/react-table-server-side-data)
            var filteredData = rows;

            var response = {
                rows: rows,
                pages: pages
            }

            return response
        })
    }


    // Perform fetching of files per react-table examples
    fetchData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ isFetching: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.fetchFiles(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered
        ).then(res => {
          // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
          console.log(res)
          this.setState({
            fileDataArray: res.rows,
            totalPages: Math.ceil(res.pages),
            isFetching: false
          });
        });
      }


    // Get file (specifically for obtaining file using throttle)
    getFileByID = (file) => {
        console.log('file: ' + file)
        let fileFetchStatus = 0;
        return fetch('/api/file/' + file.toString(), {
            method: 'GET'
        }).then(fileRes => {
            fileFetchStatus = fileRes.status
            return fileRes.json();
        }).catch(err => {
            console.log('fetch error: ' + err)
        })  // end then
    }


    // Populates the state
    getData = async () => {
        //console.log('teamList: ' + teamList)

        // Fetch the artworks from the database
        var fetchStatus = 0;
        await fetch('/api/file/idlist', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(async res => {
            fetchStatus = res.status;
            return await res.json();
        }).then(async res => {
            // Manage all the files
            var files = res.files;
            this.setState({
                files: files,
                isLoaded: true
            }, () => {
                // this.getPaginatedFilesContent();
            })

        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

    }   // end getData


    // Get team name from ID
    getTeamNameFromID = (teamID) => {
        //console.log('looking for: ' + teamID)
        //console.log('teamDataArray: ' + JSON.stringify(this.state.teamDataArray))
        var team = this.state.teamDataArray.find(elem => {
            return (elem._id === teamID)
        })

        if (typeof (team) !== 'undefined'){
            return team.teamName;
        }
    }

    // Populate the board manager
    getDisapprovalModalContent = (file) => {
        // Load the board manager component
        return (
            <Container>
            <ArtworkDisapprovalForm file={file._id.toString()} onComplete={this.disapprovalModalClose}/>
            </Container>
        )
    }

    // ----------------------------------- GET FUNCTIONS -------------------------------------- //

    // --------------------------- HANDLER FUNCTIONS -------------------------------- //




    // Handle bulk actions on table
    handleBulkAction = (bulkActionEvent) => {
        //const { eventKey } = bulkActionEvent.target;
        //console.log('bulkAction: ' + bulkActionEvent)
        //console.log('selected: ' + JSON.stringify(this.state.campaignCheckboxes))

        if (bulkActionEvent === 'delete'){
            cogoToast.loading('Removing the selected campaigns...').then(() => {
                Object.keys(this.state.fileCheckboxes).forEach(file => {
                    console.log('file ' + file + ' selected?: ' + this.state.fileCheckboxes[file])
                        if (this.state.fileCheckboxes[file] === true)  {     // I.e. if that campaign is selected...
                        // delete the campaign by calling delete API
                        var fetchStatus = 0;
                        fetch('/api/file/delete/' + file, {   
                            method: 'POST',
                            body: JSON.stringify({ }), // empty body 
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            // return JSON format
                            fetchStatus = res.status;
                            return res.json();
                        }).then(res => {
                            console.log('deletion response: ' + JSON.stringify(res))
                            // Remove the listing from  the state
                            var fileIndex = this.state.files.findIndex(function (fileToCompare) {
                                if (fileToCompare === file){
                                    return file;
                                }
                            })
    
                            if (typeof fileIndex !== 'undefined') {           // If this variable exists, splice that variable in campaignBoards
                                var newFiles = this.state.files;
                                newFiles.splice(fileIndex, 1);
                                this.setState({
                                    files: newFiles
                                }, () => {
                                    this.getData();
                                    console.log('removed selected files. New: ' + JSON.stringify(this.state.files))
                                    cogoToast.success('Removed your files! Refresh the page if nothing has happened.')
                                })
                            }
    
                            
                        }).catch(err => {
                            console.log('fetch error: ' + err)
                            FetchFailHandler(this.props, this.state, fetchStatus)
                        })
                    }
                })
            })
            
        }
        
    }

    // Handle checkboxes
    handleFileCheckboxChange = changeEvent => {
        const { checked, name } = changeEvent.target;
        //console.log('name: ' + JSON.stringify(name))
        //console.log('checked: ' + JSON.stringify(checked))
    
        this.setState(prevState => ({
          fileCheckboxes: {
            ...prevState.fileCheckboxes,
            [name]: !prevState.fileCheckboxes[name]
          }
        }), () => {
            // Count checkboxes and set a variable to make bulk actions buttons visible
            var checkboxesSelected = 0;
            var loopIterator = 0;
            Object.keys(this.state.fileCheckboxes).forEach(file => {
                //console.log('checkbox: ' + JSON.stringify( this.state.boardCheckboxes[board]))
                if(this.state.fileCheckboxes[file]) { // Selected
                //console.log('at least 1 checkbox selected')
                checkboxesSelected = checkboxesSelected + 1;
                }
                loopIterator = loopIterator + 1;
                //console.log('loopIterator: ' + loopIterator)
                //console.log('this.state.boardCheckboxes.length: ' + Object.keys(this.state.boardCheckboxes).length)
                
                if (loopIterator === Object.keys(this.state.fileCheckboxes).length){
                    // done iterating. Count number of checkboxes
                    //console.log('done iterating. CheckboxesSelected:' + checkboxesSelected)
                    if (checkboxesSelected > 0){
                        // At least 1 checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                            bulkActionsVisible: true
                        }, () => {
                        console.log('bulk actions visible')
                    })
                    } else {
                        // No checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                        bulkActionsVisible: false
                    }, () => {
                        console.log('bulk actions invisible')
                    })
                    }
                }
            });
        });
      };



    // Modals
    // Callback for when the disapproval modal is closed
    disapprovalModalClose = () => {
        this.setState({ disapprovalModal: {show: false }})
        // Update the page...
        this.getData();

        // Callback to update parent
        this.props.onUpdate();
    
    };


    // --------------------------- HANDLER FUNCTIONS -------------------------------- //


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //

    // Display bulk actions at the top of the table (e.g. delete all, pause all etc.)
    displayBulkActions = () => {
        if (this.state.bulkActionsVisible){
            return (
                <selectedOrgContext.Consumer>
                    {({ selectedOrg, setSelectedOrg }) => (
                        <ButtonGroup aria-label="Basic example" className='float-right'>
                        <DropdownButton onSelect={(e) => this.handleBulkAction(e)} as={ButtonGroup} title="Bulk Actions " id="bg-nested-dropdown" className='ml-auto mr-2'>
                            <Dropdown.Item eventKey="delete">Delete Selected</Dropdown.Item>
                            {/*<Dropdown.Item eventKey="pause">Pause Selected</Dropdown.Item>*/}
                        </DropdownButton>
                        <Button key = 'new campaign'
                            className="float-right align-self-end ml-auto"
                            disabled = {(selectedOrg === "") ? true : false}
                            onClick={() => {
                                let url = '/newcampaign/';
                                this.props.history.push({
                                    pathname: url,
                                     state: { org: selectedOrg, team: this.state.teamID }
                                })
                            }}>
                            Create New Campaign <FontAwesomeIcon className="ml-3" icon={faPlusCircle} />
                        </Button> 
                        </ButtonGroup>
                    )}
                    
                </selectedOrgContext.Consumer>
            )
        } else {
            return (
                <selectedOrgContext.Consumer>
                {({ selectedOrg, setSelectedOrg }) => (
                        <Button key = 'new campaign vertical-center'
                            className="float-right align-self-end"
                            disabled = {(selectedOrg === "") ? true : false}
                            onClick={() => {
                                let url = '/newcampaign/';
                                this.props.history.push({
                                    pathname: url,
                                    state: { org: selectedOrg, team: this.state.teamID }
                                })
                            }}>
                            Create New Campaign <FontAwesomeIcon className="ml-3" icon={faPlusCircle} />
                        </Button> 
                    )}
                    </selectedOrgContext.Consumer>
            )
        }
    }

    // Render the approved files
    renderApprovedFiles = () => {
        if (this.state.isLoaded){
            var fileTableRows = this.state.fileDataArray;

            var fileTableCols = [
                {
                    Header: () => (
                        <Container className = 'text-left pl-3'>
                            <span>File Name</span>
                        </Container>
                      ),
                    accessor: 'fileName',
                    minWidth: 100,
                    Cell: (row) => (
                        <Container >
                            <Row>
                                <Col className='text-left'>
                                    <a href={row.original.fileURL} target="_blank" rel="noopener noreferrer"  style={{fontSize: '1.0rem'}} className="primary-link" >{row.original.fileName || 'Loading...'}</a>
                                    <Row className='my-2'>
                                        <Col>
                                        <a href={row.original.fileURL} target="_blank" rel="noopener noreferrer" key = {row.original._id}
                                            className="noto-sans bold-text uppercase caption-small primary-link"
                                            as={Link}
                                            >
                                            VIEW <FontAwesomeIcon className="ml-3" icon={faExternalLinkAlt} />
                                        </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                        </Container>
                    )
                },
                {
                    Header: 'Last Edited',
                    accessor: 'lastEdited',
                    maxWidth: 170,
                    Cell: row => (
                        
                        <Container >
                            <Row>
                                <Col className='text-center my-auto'>
                                    <p>{moment(row.original.lastEdited).format("D MMM YYYY")}</p>
                                    <p className='caption-small blue-grey-text'>Reviewer: {moment(row.original.lastEdited).from(moment())}</p>
                                </Col>
                            </Row>
                            
                        </Container>
                        )
                },   
            ]   // end fileTableCols

            return (
                <selectedOrgContext.Consumer>
                    {(selectedOrg) => (
                        <Container fluid>
                        <ReactTable
                            manual
                            data={fileTableRows}
                            pages={this.state.totalPages}
                            columns={fileTableCols}
                            defaultPageSize={this.state.fileTablePageSize}
                            onFetchData = {this.fetchData}
                            loading={this.state.isFetching}
                            // onPageChange= {this.onPageChange}
                            // onPageSizeChange= {this.onPageSizeChange}
                            minRows = {this.state.fileDataArray.length > 0 ? 0 : 3}
                            noDataText = "There are no files to show."
                            defaultSorted={[
                                {
                                  id: "fileName",
                                  desc: true
                                }
                              ]}
                        />
                        </Container>
                    )} 
                </selectedOrgContext.Consumer>
            
            )

            
        }
    }

    



    // ---------------------------------------------- RENDER FUNCTIONS ---------------------------------------------//


    // Render campaign table 
    render = () => {
        console.log('rendering disapproval table')
        return(
            <selectedOrgContext.Consumer>
            {({ selectedOrg, setSelectedOrg }) => (
                    <Spring
                    from={{ transform: 'translate3d(0,-40px,0)' }}
                    to={{ transform: 'translate3d(0,0px,0)' }}>
                        {props => 
                            <Container style={props} fluid className='mb-0 mx-0 px-5'>
                            <Row className='align-items-center mb-5 ml-auto'>
                                <Col md={6} lg={8}>
                                    {(!this.props.hideTitle) ? 
                                        <div>
                                        <h3 className="grey-text noto-sans my-auto"><b>APPROVED ARTWORKS</b></h3>
                                        <p>The latest artworks that have been approved for review by the media owners.</p>
                                        </div>
                                            : '' }
                                    
                                </Col>
                            </Row>
                            <Row style={props} className='my-5'>
                                {this.renderApprovedFiles()}
                            </Row>
                            
                            </Container>

                            
                        }
                    </Spring>
                    )}
            </selectedOrgContext.Consumer>
            
        )
    }


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //    
}   // end class

export default withRouter(ApprovedFileTable)