// CAMPAIGN TABLE COMPONENT

// Uses react-table to display a table of campaigns given an array of team objects

// Usage: <AdminCampaignTable teamList=teamList hideTitle = false/>

// Imports
// Import dependencies
import React, { Component, forwardRef, useRef, createRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types'
import ReactDOM from "react-dom";
import { Link, Redirect} from 'react-router-dom';    // Allows to link to different views?
import selectedOrgContext from '../components/_AdminOrganisationContext'
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from 'react-table'
import fetch from './_AdminCustomFetch'
import DatePicker from "react-datepicker";
import moment from 'moment'
import Cookies from 'js-cookie';
import NavBar from "../components/_AdminNavBar"
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup, ButtonGroup, Dropdown, DropdownButton, ProgressBar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlus, faPlusCircle, faCircle, faExclamationTriangle, faTv } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast';
import FetchFailHandler from '../components/_AdminFetchFailHandler'
import LoadingAnimation from './_AdminLoadingAnimation'
import { Transition, Spring, animated, template } from 'react-spring/renderprops'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {TeamNameFromID} from '../components/_AdminUtils'




import "../styles/style.css"
import { minWidth } from '@material-ui/system';
import {
    XYPlot,
    XAxis,
    YAxis,
    ArcSeries,
    ChartLabel
  } from 'react-vis';
import { min } from 'date-fns';

  
const isMobile = !window.matchMedia("(min-width: 768px)").matches;

const PI = Math.PI;
  
const MARGIN = {
    left: 1, right: 1, top: 1, bottom: 1
};
  
    

class CampaignTable extends Component {
    static contextType = selectedOrgContext;    // Using context outside render -> not working yet
    constructor(props) {
        super(props)  
        // Make sure state variables are exactly the same as variables in schema if we are stringifying state to send to server.
        this.state = {
            campaignDataArray: [],  // Campaign data (objects)

            // Checkboxes for management
            campaignCheckboxes: [],

            // bulkActionsVisible
            bulkActionsVisible: false,

            isLoaded: false,

            }   // end this.state


    }   // end constructor

    // Restores previous state if it exists (passed in as props through react router)
    checkPreviousStateAndRestore = () => {
        // If we were previously on the page, restore the previous state (used for tokens exipring mid-form)
        if (typeof this.props.location.state !== 'undefined'){
            if (typeof this.props.location.state.prevState !== 'undefined'){
                    //var newState = JSON.parse(this.props.location.state.prevState)
                    console.log('Props passed through history: ' + JSON.stringify(this.props.location.state.prevState))
                    var newState = this.state; //this.props.location.state.prevState;  // Duplicate existing state
                    var stateVarIterator = 0;   

                    // Get previous state passed as prop
                    const prevState = this.props.location.state.prevState;
                    var prevStateObj = Object.keys(prevState);
                    //let self = this;
                    prevStateObj.forEach((key) => {
                        stateVarIterator = stateVarIterator + 1;
                        //console.log('key: ' + key + ' prevStateObj:' + prevState[key])
                        console.log('prevStateObj.length vs. this.state.length: ' + prevStateObj.length + ' vs. ' + this.state.length)
                        newState[key] = prevState[key]

                        if (stateVarIterator ===  prevStateObj.length){
                            //this.setState(newState, () => {console.log('After setting state: ' + JSON.stringify(this.state));});
                            return true;
                        }
                    })
                } else {return false}
        }
    }

    // componentdidmount() function
    componentDidMount = async () => {
        this.checkPreviousStateAndRestore();
        // Get the team data
        await this.getData()

    }



    // Populates the state
    getData = async () => {
        // Get the list of all Campaigns managed.
        var campaignArray = [];
        var fetchStatus = 0;
        campaignArray = await fetch('/api/campaign/', {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            return res.campaigns
        }).catch(err => {
            console.log('Failed to fetch campaigns. Error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })




        // Set the state as loaded (for when there are no campaigns)
        this.setState({
            campaignDataArray: campaignArray,
            isLoaded: true
        })
    }   // end getData


    // --------------------------- HANDLER FUNCTIONS -------------------------------- //

    // Handle the campaign quick toggles
    handleCampaignActiveToggle = (event) => {
        event.preventDefault();
        const { checked, name } = event.target;
        //console.log('event name: ' + name + ' checked: ' + checked)
        //var campaignIndex = 
        
        // Toggle the campaign's paused status in the state, then perform fetch to toggle campaign paused in DB
        let campaignID = name;
        
        var newCampaignDataArray = this.state.campaignDataArray;
        newCampaignDataArray[ (this.state.campaignDataArray.findIndex((elem) => {
            return elem._id === campaignID;
        })) ].campaignPaused = Boolean(!checked);
        this.setState({
            campaignDataArray: newCampaignDataArray
        }, () => {

        
        var fetchStatus = 0;
        fetch('/api/campaign/modify/' + campaignID,{
            method: 'POST',
            body: JSON.stringify({
                campaignPaused: !checked
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            console.log('toggle message: ' + res.message)
            // Update the state
            this.setState({state: this.state})

        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

    })

        //console.log('campaignDataArray: ' + this.state.campaignDataArray )
        //console.log('campaignIndex: ' + campaignIndex)

       // console.log('campaign toggled: ' + this.state.campaignDataArray[campaignIndex].campaignPaused)
    }

// Handle campaign selection
    deleteSelectedCampaigns = () => {
        // Get the chosen campaigns
    }


    // Handle bulk actions on table
    handleBulkAction = (bulkActionEvent) => {
        //const { eventKey } = bulkActionEvent.target;
        //console.log('bulkAction: ' + bulkActionEvent)
        //console.log('selected: ' + JSON.stringify(this.state.campaignCheckboxes))

        if (bulkActionEvent === 'delete'){
            cogoToast.loading('Removing the selected campaigns...').then(() => {
                Object.keys(this.state.campaignCheckboxes).forEach(campaign => {
                    console.log('campaign ' + campaign + ' selected?: ' + this.state.campaignCheckboxes[campaign])
                        if (this.state.campaignCheckboxes[campaign] === true)  {     // I.e. if that campaign is selected...
                        // delete the campaign by calling delete API
                        var fetchStatus = 0;
                        fetch('/api/campaign/delete/' + campaign, {   
                            method: 'POST',
                            body: JSON.stringify({ }), // empty body 
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            // return JSON format
                            fetchStatus = res.status;
                            return res.json();
                        }).then(res => {
                            console.log('deletion response: ' + JSON.stringify(res))
                            // Remove the listing from  the state
                            var campaignIndex = this.state.campaigns.findIndex(function (campaignToCompare) {
                                if (campaignToCompare === campaign){
                                    return campaign;
                                }
                            })
    
                            if (typeof campaignIndex !== 'undefined') {           // If this variable exists, splice that variable in campaignBoards
                                var newCampaigns = this.state.campaigns;
                                newCampaigns.splice(campaignIndex, 1);
                                this.setState({
                                    campaigns: newCampaigns
                                }, () => {
                                    this.getData();
                                    console.log('removed selected campaign. New: ' + JSON.stringify(this.state.campaigns))
                                    cogoToast.success('Removed your campaigns! Refresh the page if nothing has happened.')
                                })
                            }
    
                            
                        }).catch(err => {
                            console.log('fetch error: ' + err)
                            FetchFailHandler(this.props, this.state, fetchStatus)
                        })
                    }
                })
            })
            
        }
        
    }

    // Handle checkboxes
    handleCampaignCheckboxChange = changeEvent => {
        const { checked, name } = changeEvent.target;
        //console.log('name: ' + JSON.stringify(name))
        //console.log('checked: ' + JSON.stringify(checked))
    
        this.setState(prevState => ({
          campaignCheckboxes: {
            ...prevState.campaignCheckboxes,
            [name]: !prevState.campaignCheckboxes[name]
          }
        }), () => {
            // Count checkboxes and set a variable to make bulk actions buttons visible
            var checkboxesSelected = 0;
            var loopIterator = 0;
            Object.keys(this.state.campaignCheckboxes).forEach(campaign => {
                //console.log('checkbox: ' + JSON.stringify( this.state.boardCheckboxes[board]))
                if(this.state.campaignCheckboxes[campaign]) { // Selected
                //console.log('at least 1 checkbox selected')
                checkboxesSelected = checkboxesSelected + 1;
                }
                loopIterator = loopIterator + 1;
                //console.log('loopIterator: ' + loopIterator)
                //console.log('this.state.boardCheckboxes.length: ' + Object.keys(this.state.boardCheckboxes).length)
                
                if (loopIterator === Object.keys(this.state.campaignCheckboxes).length){
                    // done iterating. Count number of checkboxes
                    //console.log('done iterating. CheckboxesSelected:' + checkboxesSelected)
                    if (checkboxesSelected > 0){
                        // At least 1 checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                            bulkActionsVisible: true
                        }, () => {
                        console.log('bulk actions visible')
                    })
                    } else {
                        // No checkbox selected. Set bulkActionsVisible = true
                        this.setState({
                        bulkActionsVisible: false
                    }, () => {
                        console.log('bulk actions invisible')
                    })
                    }
                }
            });
        });
      };


    // --------------------------- HANDLER FUNCTIONS -------------------------------- //


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //



    // Sorting criteria for carousel
    displaySortingCriteria = () => {
        if (this.state.isLoaded) {
            return (
            <Carousel 
                emulateTouch 
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                showStatus={false}
                
                centerMode
                centerSlidePercentage={25}
            >
                <Button className='tab-small' active>ALL</Button>
                <Button className='tab-small'>ACTIVE</Button>
                <Button className='tab-small'>PAUSED</Button>
            </Carousel>

            )
        }
    }

    // Render campaigns as a table
    renderCampaignTableData = () => {
        if (this.state.isLoaded){
            // Use react-table to render campaignTableData
            // Tables to render with reactTable
            //console.log('campaignDataArray: ' + JSON.stringify(this.state.campaignDataArray))
            const campaignTableRows = this.state.campaignDataArray;
            const campaignTableCols = [
                {
                    Header: 'Select',
                    id: 'select',
                    maxWidth: 100,
                    Cell: (row) => (
                        <Form.Check 
                            custom = 'true'
                            type='checkbox'
                            checked={this.state.campaignCheckboxes[row.original._id]}
                            onChange={this.handleCampaignCheckboxChange}
                            name = {row.original._id}
                            key = {row.original._id}
                            id={row.original._id}
                            label=''
                        />
                    )
                },
                {
                    Header: 'Status',
                    maxWidth: 150,
                    accessor: 'campaignPaused',
                    Cell: (row, index) => (
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" name = {row.original._id} id={row.original._id + 'Toggle'} onChange= {((e) => this.handleCampaignActiveToggle(e))} checked={!row.original.campaignPaused || false}/>
                            <label className="custom-control-label" htmlFor={row.original._id + 'Toggle'}>{ row.original.campaignPaused ? 'Paused' : 'Active'}</label>
                        </div>
                    )
                },
                {
                    Header: () => (
                        <Container className = 'text-left pl-3'>
                            <span>Campaign Name</span>
                        </Container>
                      ),
                    accessor: 'campaignName',
                    minWidth: 100,
                    Cell: (row) => (
                        <Container >
                            <Row>
                                <Col className='text-left'>
                                    <a style={{fontSize: '1.5rem'}} className="primary-link" href={'https://ui.caasie.co/campaign/' + row.original._id} target="_blank"
                                            rel="noopener noreferrer" >{row.original.campaignName || 'Loading...'}</a>
                                    <Row className='my-2'>
                                        <Col>
                                        <a key = {row.original._id}
                                            className="noto-sans bold-text uppercase caption-small primary-link"
                                            href={'https://ui.caasie.co/campaign/' + row.original._id}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            MANAGE <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                                        </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                        </Container>
                    )
                },{
                    Header: () => (
                        <Container className = 'text-left pl-3'>
                            <span>Budget Status</span>
                        </Container>
                      ),
                    accessor: 'budget',
                    Cell: (row, index) =>(   // Put the manage button in here.
                        <Container >
                            <Row>
                                <Col className='text-left my-auto'>
                                    {/* Handle the case where no budget is enabled. I.e. give user overview of what has been spent so far. */}
                                    {
                                        ( (row.original.campaignBudget) && ( (typeof(row.original.campaignBudget.isDailyBudgetEnabled) === 'undefined') || (!row.original.campaignBudget.isDailyBudgetEnabled)) ) ? 
                                        <p className='caption-small blue-grey-text'>$<b>{row.original.campaignExpense.dailyExpense ? row.original.campaignExpense.dailyExpense.toFixed(2) : '0'}</b> spent in the last 24 hours.</p> : ''
                                    }

                                    {
                                        ( (row.original.campaignBudget) && ( (typeof(row.original.campaignBudget.isMonthlyBudgetEnabled) === 'undefined') || (!row.original.campaignBudget.isMonthlyBudgetEnabled)) ) ? 
                                        <p className='caption-small blue-grey-text'>$<b>{row.original.campaignExpense.monthlyExpense ? row.original.campaignExpense.monthlyExpense.toFixed(2) : '0'}</b> spent in the last 30 days.</p> : ''
                                    }
                                    
                                    {this.renderMonthlyBudgetSundial(row.original)}
                                    {this.renderDailyBudgetSundial(row.original)}
                                </Col>
                            </Row>
                            
                        </Container>
                    )
                },
                {
                    Header: 'Organisation',
                    accessor: 'organisation',
                    Cell: (row, index) => (
                        <Col>
                            <OrgNameFromID key={row.original.parentOrg + 'table component'} orgID = {row.original.parentOrg} />
                            <p className='caption-small'>{row.original.parentOrg}</p>
                            <a key = {row.original.parentOrg}
                                            className="noto-sans bold-text uppercase caption-small primary-link"
                                            href={'https://ui.caasie.co/organisation/' + row.original.parentOrg}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            VIEW ORG <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                                        </a>
                        </Col>
                    )
                },
                
                {
                    Header: 'Boards',
                    accessor: 'campaignBoards',
                    maxWidth: 130,
                    Cell: (row, index) => (
                        <p>{row.original.campaignBoards.length}</p>
                    )
                },
                {
                    Header: 'Start Date',
                    accessor: 'startDate',
                    maxWidth: 170,
                    Cell: row => (
                        
                        <Container >
                            <Row>
                                <Col className='text-center my-auto'>
                                    <p>{moment(row.original.startTime).format("D MMM YYYY")}</p>
                                    <p className='caption-small blue-grey-text'>{moment(row.original.startTime).from(moment())}</p>
                                </Col>
                            </Row>
                            
                        </Container>
                        )
                },
                {
                    Header: 'End Date',
                    accessor: 'endTime',
                    maxWidth: 170,
                    Cell: row => (
                        <Container >
                            <Row>
                                <Col className='text-center my-auto'>
                                    <p>{moment(row.original.endTime).format("D MMM YYYY")}</p>
                                    <p className={moment(row.original.endTime).isAfter(moment()) ? 'caption-small blue-grey-text' : 'caption-small foreground-error bold-text'}>{moment(row.original.endTime).from(moment())}</p>
                                </Col>
                            </Row>
                            
                        </Container>
                    )
                },
                
            ]

            return (
                <selectedOrgContext.Consumer>
                    {(selectedOrg) => (
                        <Container fluid>
                        <ReactTable
                            data={campaignTableRows}
                            columns={campaignTableCols}
                            defaultPageSize={ 5 }
                            minRows = {this.state.campaignDataArray.length > 0 ? 0 : 3}
                            noDataText = "You have no campaigns. Click the button above (or below) this table to create one."
                            defaultSorted={[
                                {
                                  id: "campaignName",
                                  desc: true
                                }
                              ]}
                        />
                        </Container>
                    )} 
                </selectedOrgContext.Consumer>
            
            )
        } else {
            return (<LoadingAnimation />)
        }
    }



    // Alternative for mobile
    renderCampaignCarousel = () => {
        // TODO: Sort campaignArray

        if (this.state.isLoaded) {
            return (
                <>
                <Carousel 
                className='mt-4'
                emulateTouch 
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                // selectedItem={null}
                centerMode
                // width={`${this.state.campaignDataArray.length*100}vw`}
                showStatus={false}

                 centerSlidePercentage={100}
                >
                    
                    {this.state.campaignDataArray.map(campaign => {
                        return (
                            <>
                            <Container key={campaign._id} >
                                <Card key={campaign._id} className = "general-info-cards pale-background no-border magnify-hover p-0 m-0 text-left" >
                                {/* <Card.Img className = 'p-0 m-0' style={{width:  isMobile ?  '80vw' : '30vw' , height: isMobile ? '30vh' : '15vw' , objectFit: 'cover'}} variant="top" src={this.state.arrayOfRecentsImageURLs[campaign._id] ? this.state.arrayOfRecentsImageURLs[campaign._id] : ''} /> */}
                                    <Card.Body className='p-4'>
                                    
                                        <Row className='mb-5 px-4'>
                                            <Col className=''>
                                                <Row className='float-right'>
                                                    <FontAwesomeIcon className={'mr-3 ' + (campaign.campaignPaused ? 'foreground-warning' : 'foreground-primary')} icon={faCircle} /> <p className='noto-sans blue-grey-text caption-smaller bold-text uppercase'>{campaign.campaignPaused ? 'Paused' : 'Active'}</p>
                                                </Row>
                                                
                                            </Col>
                                        </Row>
                                        <Card.Title className='pt-5'><h2><b>{campaign.campaignName}</b></h2></Card.Title>
                                        <Card.Text>
                                            Team: <b><TeamNameFromID teamID = {campaign.managementTeam} /></b> <br></br>
                                            Campaign Owner: <b>{campaign.parentOrg ? <OrgNameFromID key={campaign.parentOrg + 'carousel name'} orgID = {campaign.parentOrg} /> || campaign.parentOrg : 'Not set'}</b>
                                            <br/><br/>
                                            {campaign.configErrors ? <span className="noto-sans bold-text caption-small"><FontAwesomeIcon className = 'mx-2' icon={faExclamationTriangle} /> {campaign.configErrors.length} </span> : '' }
                                            {campaign.campaignBoards ? <span className="noto-sans bold-text caption-small"><FontAwesomeIcon className = 'mx-2' icon={faTv} /> {campaign.campaignBoards.length} </span> : '' }
                                        </Card.Text>
                                        
                                        <Row className='float-right'>
                                            <a className='text-button' href ={'https://ui.caasie.co/campaign/' + campaign._id} target='_blank' rel="noopener noreferrer">See details <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} /></a>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Container>
                            </>
                        )
                    })}

                    <Row className='h-100 mx-n5'>
                        <Col className='my-auto px-0'>
                            <IconButton onClick={e => { this.props.history.push('/newcampaign/')}} className='my-auto floatingActionBtnLarge' size="large">
                                    <AddIcon />
                            </IconButton>
                            <Col className='mt-4 px-0'>
                            <h4 >Create a <b>new campaign</b>.</h4>
                            <p className='caption-smaller blue-grey-text'>Just click this button to add a campaign.</p>
                            </Col>
                        </Col>
                    </Row>

                </Carousel>
                <Col className='mt-4 px-4'>
                    <h4 >Slide to <b>see more</b>.</h4>
                    <p className='caption-smaller blue-grey-text'>Pan from left to right to see a list of all the campaigns in your account.</p>
                </Col>
                </>
            )
        } else {
            return (<LoadingAnimation />)
        }
    }



    // ---------------------------------------------- RENDER FUNCTIONS ---------------------------------------------//


    // For a budget/expense sundial
    renderMonthlyBudgetSundial = (campaign) => {
        // console.log('campaign: ' + JSON.stringify(campaign))
        if (campaign.campaignExpense && campaign.campaignBudget && campaign.campaignBudget.isMonthlyBudgetEnabled) {
            return (
                    <Row className='my-2 pb-3'>
                        <Col sm={3} className='my-auto '>
                            <div className="ml-0 mr-0 align-self-center thumbnail-circle-border">
                                <XYPlot
                                    xDomain={[-3, 3]}
                                    yDomain={[-3, 3]}
                                    width={45}
                                    getAngle={d => d.budgetRemaining}
                                    getAngle0={d => 0}
                                    height={45}
                                    margin={MARGIN}
                                    >
                                    <ArcSeries
                                        animation={{
                                        damping: 9,
                                        stiffness: 300
                                        }}
                                        colorType="literal"
                                        radiusDomain={[0, 3]}
                                        data={[
                                        {budgetRemaining: (1 - Number(campaign.campaignExpense.monthlyExpense) / Number(campaign.campaignBudget.monthlyBudget)) * 2 * PI, radius0: 2.5, radius: 3, color: (1 - Number(campaign.campaignExpense.monthlyExpense) / Number(campaign.campaignBudget.monthlyBudget)) < 0.15 ? "#fd6567" : '#3bd62b' },
                                        ]}
                                    />
                                </XYPlot>
                            </div>
                        </Col>
                        <Col sm={9} className='mr-auto my-auto'>
                            <div  className="mb-0 ml-3 align-self-center" >
                                <p className ="blue-grey-text caption-small py-0 my-0">$<b>{Number(campaign.campaignBudget.monthlyBudget.toFixed(2) - campaign.campaignExpense.monthlyExpense.toFixed(2)).toFixed(2)}</b> Remaining this month</p>
                            </div>
                        </Col>
                    </Row>
                )
        } else {
            return ' '
        }

    }


        // For a budget/expense sundial
        renderDailyBudgetSundial = (campaign) => {
            // console.log('campaign: ' + JSON.stringify(campaign))
            if (campaign.campaignExpense && campaign.campaignBudget && campaign.campaignBudget.isDailyBudgetEnabled) {
                return (
                        <Row className='my-2 '>
                            <Col sm={3} className='my-auto'>
                                <div className="ml-0 mr-0 align-self-center thumbnail-circle-border">
                                    <XYPlot
                                        xDomain={[-3, 3]}
                                        yDomain={[-3, 3]}
                                        width={45}
                                        getAngle={d => d.budgetRemaining}
                                        getAngle0={d => 0}
                                        height={45}
                                        margin={MARGIN}
                                        >
                                        <ArcSeries
                                            animation
                                            colorType="literal"
                                            radiusDomain={[0, 3]}
                                            data={[
                                            {budgetRemaining: (1 - Number(campaign.campaignExpense.dailyExpense) / Number(campaign.campaignBudget.dailyBudget)) * 2 * PI, radius0: 2.5, radius: 3, color: (1 - Number(campaign.campaignExpense.dailyExpense) / Number(campaign.campaignBudget.dailyBudget)) < 0.15 ? "#fd6567" : '#3bd62b',},
                                            ]}
                                        />
                                    </XYPlot>
                                </div>
                            </Col>
                            <Col sm={9} className='mr-auto my-auto'>
                                <div  className="mb-0 ml-3 align-self-center" >
                                    <p className ="blue-grey-text caption-small py-0 my-0">$<b>{Number(campaign.campaignBudget.dailyBudget.toFixed(2) - campaign.campaignExpense.dailyExpense.toFixed(2)).toFixed(2)}</b> Remaining today</p>
                                </div>
                            </Col>
                        </Row>
                    )
            } else {
                return ' '
            }
    
        }

    // Render campaign table 
    render = () => {
        if (this.state.isLoaded && this.state.campaignDataArray) {
            return(
                <selectedOrgContext.Consumer>
                {({ selectedOrg, setSelectedOrg }) => (
                        <Spring
                        from={{ transform: 'translate3d(0,-40px,0)' }}
                        to={{ transform: 'translate3d(0,0px,0)' }}>
                            {props => 
                                <Container style={props} fluid className='mb-0 mx-0 px-0'>
                            <Row className={'align-items-center ' + (isMobile ? '' : 'mb-5')}>
                                    <Col md={6} lg={8}>
                                        {(!this.props.hideTitle) ? 
                                            <div>
                                            <h3 className="grey-text noto-sans my-auto"><b>YOUR CAMPAIGNS</b></h3>
                                            <p>Manage your campaigns quickly here.</p>
                                            </div>
                                                : '' }
                                        
                                    </Col>
                                    <Col>
                                        {isMobile ? this.displaySortingCriteria() : ''}
                                    </Col>
                                </Row>
                                <Row style={props} >
                                    {isMobile ? this.renderCampaignCarousel() : this.renderCampaignTableData()}
                                </Row>
                                <Row className='align-items-center pb-3 ml-auto'>
                                <Col className='float-right'>
                                
                                {isMobile ? '' : <Button key = 'new campaign vertical-center'
                                    className="float-right align-self-end"
                                    disabled = {(selectedOrg === "") ? true : false}
                                    onClick={() => {
                                        let url = '/newcampaign/';
                                        this.props.history.push({
                                            pathname: url,
                                            state: { org: selectedOrg, team: this.state.teamID }
                                        })
                                    }}>
                                    Create New Campaign <FontAwesomeIcon className="ml-3" icon={faPlusCircle} />
                                </Button> }
                                </Col>
                                {isMobile ? '' : <Row className='spacer my-5'></Row>}
                                </Row>
                                </Container>
                            }
                        </Spring>
                        )}
                </selectedOrgContext.Consumer>
                
            )
        } else {
            return <LoadingAnimation/>
        }
        
    } 


    
    // --------------------------- RENDER FUNCTIONS -------------------------------- //    
}   // end class




// HELPER CLASSES



// Displays the org name given an ID
export class OrgNameFromID extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orgID: this.props.orgID,
            orgData: {}
        }
    }

    // On mount
    componentDidMount = async () => {
        this.getOrgName();
    }

    // On update
    componentDidUpdate = async () => {

    }

    // GET function
    getOrgName = async () => {
        var fetchStatus = 0;
        var org = await fetch('/api/organisation/' + this.state.orgID, {
            method: 'GET',
            headers: {
                'Content-Type' : 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            return res.org
        }).catch(err => {
            console.log('Failed to fetch organisation details: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        })

        // Now set the state
        this.setState({
            orgData: org
        })
    }


    // Render
    render = () => {
        if (this.state.orgData && this.state.orgData.orgName) {
            return <p>{this.state.orgData.orgName}</p>
        } else if (!this.state.orgData && this.state.orgID) {
            return <LoadingAnimation />
        } else {
            return null
        }
        
    }
}

export default withRouter(CampaignTable)