// Overloading the native HTTP Fetch api to prefix it with the CAASie API URL
// import Cookies from 'js-cookie'

export default async function CustomFetch (url, obj) {
    
    // If the URL is for a non-CAASie api, do not interfere
    if (url.includes('http')){
        return await fetch(url, obj)
    }
    // return await fetch(url, obj)
    return await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '') + url, {
        ...obj,
        headers: {
            ...obj.headers,
            'Authorization' : localStorage.getItem('tokenToStore') ? "Bearer " + localStorage.getItem('tokenToStore') : ''
        }
    })
}

