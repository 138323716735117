import React, { useState, useContext, Component } from 'react';
import ReactDOM, {render} from 'react-dom';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Switch, Redirect,  __RouterContext } from 'react-router-dom';
import Sidebar from './components/_AdminSidebar'
import selectedOrgContext from './components/_AdminOrganisationContext'

// Views
import AuthContext from './components/_AdminAuthContext'
import AdminHome from './views/_AdminHome'
import AdminLogin from './views/_AdminLoginScreen'
import BoardScreen from './views/_AdminBoardsScreen'
import Campaigns from './views/_AdminCampaignScreen'

function Home () {
  return (
    // Use the router here to route requests to the respective views/js file
    // This SHOULD add a 'history' prop to each component
    // Pass on our props
    <>
      <Switch>
      <Route exact path= '/' component={AdminLogin}/>
        <Sidebar children = { (
          <>
            <Route path = '/home'  component={AdminHome}/>    
            <Route path = '/boards'  component={BoardScreen}/>   
            <Route path = '/campaigns' component={Campaigns} />
          </>
          )} />
      </Switch>
    </>
    )
}



export default function App() {
  // Use context
  useContext(selectedOrgContext);
      
  // Update the selected organisation context
  var setSelectedOrg = (selectedOrg) => {
    console.log('setting selected org (top level)')
    setState(state => ({
        ...state,
        selectedOrg: selectedOrg,
    }))
  }
  // State for selected organisation (since it is a top-level item)
  var [state, setState] = useState({
    selectedOrg: '',
    setSelectedOrg: setSelectedOrg,
    authToken: '',
    shouldRefreshToken: '',
    proSubscription: '',
    orgData: ''
  })


    // Update the authorisation token context
    var setAuthContext = (newToken) => {
      if ( JSON.stringify(newToken) === JSON.stringify(state.authToken)) return   console.log('token unchanged', newToken)         // If the authToken has not actually changed, don't set the state
      //
      setState(state => ({
          ...state,
          authToken: newToken,
      }))

      // Set the token in localstorage for cases where we cannot use the auth context
      if (newToken) {
        console.log('setting new token', newToken)
        localStorage.setItem('tokenToStore', newToken)
      }
      
  }


  // Set the flag for refreshing the auth token
  var setAuthTokenRefreshFlag = (newShouldRefreshToken) => {
      setState(state => ({
      ...state,
      shouldRefreshToken: newShouldRefreshToken
      }))
  }


  return (

    <AuthContext.Provider value = {{
      authToken: state.authToken,
      setAuthToken: setAuthContext,
      shouldRefreshToken: state.shouldRefreshToken,
      setAuthTokenRefresh: setAuthTokenRefreshFlag,
    }}>
        <selectedOrgContext.Provider value = {{
          selectedOrg: state.selectedOrg,
          setSelectedOrg: setSelectedOrg
        }}>
            <Router location="history">
              <Home/>
            </Router>
          
      </selectedOrgContext.Provider>
    </AuthContext.Provider>
  )
}

render(<App />, document.getElementById('root'))
