// SIDEBAR - Navigation menu that a user sees after logging in
// From https://codesandbox.io/s/nmy6x9wrj

// Usage: <Sidebar children={content} />

// Import dependencies
import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';    // Allows to link to different views?
import { Route, Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import fetch from './_AdminCustomFetch'
import { slide as Menu } from "react-burger-menu";
import WalletSundial from './_AdminWalletSundial'
import FetchFailHandler from './_AdminFetchFailHandler'
import Sidebar from 'react-sidebar'
import { isAbsolute } from 'path';
import Avatar from 'react-avatar';
import {Container, Form, Table, Jumbotron, Button, Row, Col, Card, CardGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faBell, faUser, faDoorOpen, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import LoadingAnimation from './_AdminLoadingAnimation'
import selectedOrgContext from './_AdminOrganisationContext'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Scrollbar from 'react-scrollbars-custom'
import ReactSVG from 'react-svg'
import { Transition, Spring, animated } from 'react-spring/renderprops'
import sidebarCollapseContext from './_AdminSidebarCollapseContext'
import "../styles/style.css"
import NavBar from './_AdminNavBar'
import cogoToast from 'cogo-toast';



const sidebarStyles = {
        sidebar: {
            width: 256,
            height: "100%"
        },
        sidebarLink: {
            display: "block",
            padding: "20px 0 0 0",
            //margin: "20px 0",
            color: "#ffffff",
            textDecoration: "none"
        },
        divider: {
            margin: "8px 0",
            height: 1,
            backgroundColor: "#757575"
        },
        content: {
            padding: "3vh 00px 0 00px",
            height: '',
        
        //backgroundColor: "#00232d"
        },
        container: {
            height: "100%",
            backgroundColor: "#00232d"
        },
        bottomPinned: {
            position: 'absolute',
            bottom: '0px',
            padding: '0px',
            margin: '0px',
            backgroundColor: "#00232d",
            width: '100%'
        },
        topPinned: {
            position: 'absolute',
            top: '1%',
            backgroundColor: "#00232d",
            height: ''
            
        },
        thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
        thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
        thumb: {
        display: 'inline-flex',
        borderRadius: 50,
        border: '2px solid #33ea6f',
        marginBottom: 8,
        marginRight: 8,
        padding: 4,
        boxSizing: 'border-box'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    }
}




// Sidebar class
    class CAASieSidebar extends Component{
    
    static contextType = selectedOrgContext

    // React calls constructor(), getDerivedStateFromProps(), render(), componentDidMount() in this order (when mounting)
        
    // Create the required properties to display
    constructor(props, context) {
        super(props, context);

            // Sidebar is a 2-piece object, top piece has logo, links. Bottom piece has new campaign button, organisation, and profile.
            this.sidebarTop = createRef();
            this.sidebarBottom = createRef();
            this.sidebarTopHeight = 0;
            this.sidebarBottomHeight = 0;

            this.state = ({
                viewLinks: [{
                        text: 'Admin Dash',
                        url: '/home/'
                    }
                ],

                managementLinks: [ {
                        text: 'Users',
                        url: '/users'
                    },
                    {
                        text: 'Organisations',
                        url: '/organisations/'
                    },
                    {
                        text: 'Campaigns',
                        url: '/campaigns'
                    },
                    {
                        text: 'Ads & Files',
                        url: '/ads'
                    },
                    {
                        text: 'Boards',
                        url: '/boards'
                    },
                ],


                internalLinks: [ {
                    text: 'Financial',
                    url: '/financial'
                },
                ],

                // User info
                firstName: '',
                lastName: '',
                

                
                // List of organisations the user is in
                orgList: [],    // Organisation objects
                orgLinks: [],   // Links corresponding to organisations
                
                // Selected organisation in dropdown
                selectedOrg: this.context.selectedOrg,            
                selectedOrgIndex: 0,     // The index of the selected org

                // To handle resizing of the window during sidebar collapse
                resizeEvent: new CustomEvent('resize', {bubbles: true}),

                profilePicURL: '',

                // Appearance & styles
                docked: true,
                open: false,
                transitions: false,
                touch: true,
                shadow: true,

                isLoaded: false
            })
            //this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
            // this.menuButtonClick = this.menuButtonClick.bind(this);
        }
    

        menuButtonClick = (ev) => {
            ev.preventDefault();
            this.setState({
                docked: !this.state.docked
            });

            // ResizeEvent (for when the sidebar is collapsed)
            
            window.dispatchEvent(this.state.resizeEvent)

        }


        // Called after component mounts?
        componentDidMount() {
            // Listen for window resizing
            window.addEventListener("resize", this.handleResize);
            this.getUserData(); 
        }



        // Get all the user's data
        getUserData = async () => {
            // This will work if the user is logged in.
            let fetchStatus = 0;
            await fetch('/api/user/',      // probably should be renamed 'user'
            {
                method: 'GET',
            })
            .then(res => {
                fetchStatus = res.status;
                return res.json();
            })        // From express /profile GET route
            .then(async (res) => {
                //console.log('user get: ' + JSON.stringify(res))
                // Find all the different organisations the user belongs to using /api/organisation/findbyuser
                // Each team has a parent organisation

                var orgArray = []
                var newOrgLinks = this.state.orgLinks;
                var teams = res.teams;
                
                this.setState({
                    firstName: res.firstName,
                    lastName: res.lastName,
                }, async () => {
                    var teamIterator = 0;
                    await Promise.all(teams.map(async teamID => {
                        //console.log('teamID: ' + teamID)
                        // Find the organisation from the teamID using fetch
                        let fetchStatus = 0;
                        await fetch('/api/organisation/byteam/' + teamID, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            fetchStatus = res.status;
                            return res.json();
                        }).then(async (res) => {
                            // Push the resultant organisation (ID alone) into the orgIDArray
                            //console.log('org push: ' + res.org._id)
                            await orgArray.push(res.org);
                            await newOrgLinks.push({
                                text: res.org.orgName,
                                url: '/organisation/' + res.org._id
                            })
                            return res;
                        }).then(async(res) => {
                            this.setState({
                                email: res.email,   // Email address of user as output by Express
                                username: res.username,
                                teamlist: res.teams,
                                orgList: orgArray,
                                orgLinks: newOrgLinks,
                                isLoaded: true
            
                            }, async () => {
                                // Set the context
                                if (!this.state.selectedOrg) {
                                    console.log('no selected org: ' + JSON.stringify(this.state.orgList[0]))
                                    await this.setSelectedOrg(this.state.orgList[0]._id)
                                }

                                // Notify the user if they are running out of money
                                if ( (Number(this.state.orgList[this.state.selectedOrgIndex].orgWallet.fundsAvailable) < 10)) {
                                    cogoToast.warn('Your organisation is running low on credit. Your campaigns may not run.', {hideAfter: 10});
                                }
                                
                                
                                if (this.state.orgList[this.state.selectedOrgIndex].orgType === 'Board Owner'){
                                    //console.log('org is board owner')
                                    // Set the management links
                                    var updatedManagementLinks = this.state.managementLinks;
                                    updatedManagementLinks[1].text = 'Boards'
                                    updatedManagementLinks[1].url = '/management/boards'
                                    await this.setState({
                                        managementLinks: updatedManagementLinks
                                    })
                                }
                                
                            }) // end setState
                        }).catch(err => {
                            //console.log('fetch error: ' + err)
                            FetchFailHandler(this.props, this.state, fetchStatus)
                        }) // end then
                    
                    }))// end forEach
                })

                


            }).catch(err => {
                //console.log('fetch error: ' + err)
                FetchFailHandler(this.props, this.state, fetchStatus)
            })
        }// end getUserData


        // Handle the resizing of the window
        handleResize = e => {
            if (this.sidebarBottom !== null) {
                console.dir('this.sidebarBottom height: ' + this.sidebarBottom.clientHeight)
                
                var newSidebarTopHeight = window.innerHeight - this.sidebarBottom.clientHeight;
                
                this.sidebarTopHeight = newSidebarTopHeight;

            }
        };

        // Add link to state link list
        addLinks = (linksToAdd) => {
            ////console.log('links length before concat: ' + this.state.links.length);
            
            const newlinks = this.state.links.slice();
            for (var i = 0; i < linksToAdd.length; i++){
                newlinks.push({
                    text: linksToAdd[i].text,
                    url: linksToAdd[i].url
                });
            }
            this.setState({
                links: newlinks 
            })
        }


        // set team dash URL
        teamDashURL(team){
            let teamID = team._id;
            return '/dashboard/' + teamID;
        }   // end teamDashURL


        // Handler functions
        // handle dropdown selection change (organisation)
        handleSelectionChange = (event, setSelectedOrg) => {
            //console.log('changed selection')
            // TODO: Redirect user to homepage on change due to changed organisation
            console.log('setting selected org: ' + event.target.value)

            setSelectedOrg(event.target.value)
        }

        // Update the selected organisation context
        setSelectedOrg = (selectedOrg) => {
            console.log('setting selected org: ' + selectedOrg)
            // Determine index of selectedOrg
            var selectedOrgIndex = this.state.orgList.findIndex(org => {
                return org._id === selectedOrg
            })

            this.setState({
                selectedOrg: selectedOrg,
                selectedOrgIndex: selectedOrgIndex
            }, () => {
                console.log('set selected org: ' + selectedOrg)
                
                // Force context to change here
                // TODO: is this valid?
                this.context.setSelectedOrg(this.state.selectedOrg)
            })
        }


        // Populate the dropdown menu for the organisation selector
       renderOrganisationDropdown = () => {
            // Render team options for the dropdown menu 
            if(this.state.isLoaded) {
                ////console.log('state loaded...mapping organisations: ' + JSON.stringify(this.state.orgList))
                return (
                    this.state.orgList.map(org => (
                        <MenuItem key = {org._id} value = {org._id}>
                            {org.orgName}
                        </MenuItem>
                    ))

                )
            } else {
                return ('Loading...')
            }
       }




    renderOrganisationInfo = () => {
        // Based on the chosen organisation
        if (this.state.isLoaded){
            var chosenOrg = this.state.selectedOrgIndex;
            console.log('chosenOrg: ' + chosenOrg)
            return (
                <Container fluid className= "my-0 px-0" key = {this.state.orgLinks[chosenOrg].text}>
                    
                    <Container fluid className=' p-0'>
                        <Row className="mx-0 align-self-center justify-content-center">
                            <div style={sidebarStyles.thumb} className="ml-0 mr-0 align-self-center">
                                <WalletSundial wallet={this.state.orgList[this.state.selectedOrgIndex].orgWallet} totalFunds = {this.state.orgList[this.state.selectedOrgIndex].orgWallet.fundsAvailable + 50} />
                                
                            </div>
                            <div  className="mb-0 ml-3 align-self-center" >
                                <p className ="blue-grey-text caption-small py-0 my-0">$<b className={ Number(this.state.orgList[this.state.selectedOrgIndex].orgWallet.fundsAvailable).toFixed(2) > 5 ? 'green-text' : 'foreground-error'}>{Number(this.state.orgList[this.state.selectedOrgIndex].orgWallet.fundsAvailable).toFixed(2) }</b> Remaining</p>
                                <p className ="blue-grey-text noto-sans uppercase bold-text caption-smaller py-0 my-0">in your wallet.</p>
                            </div>
                            
                        </Row>
                    </Container>
                    <Container fluid className = 'my-0 px-0 text-right'>
                    <Button key = {this.state.orgLinks[chosenOrg].text}
                                className="text-button-white-small"
                                as = {Link}
                                to = {this.state.orgLinks[chosenOrg].url}
                                >
                                Manage <FontAwesomeIcon className="ml-3" icon={faChevronCircleRight} />
                            </Button>
                    </Container>
                </Container>
            );
        } else {
            return (<LoadingAnimation />)
        }
    
    }


    render(){
        // Get all the links
        const renderedViewLinks =  this.state.viewLinks.map(b => {
            ////console.log('map b.text: ' + b.text)
            //console.log('b.url: ' + b.url + ' vs. window.location.pathname: ' + window.location.pathname)
            //console.log('window.location.pathname.toString().test(b.url): ' + window.location.pathname.includes(b.text.toLowerCase()))
            
            if (window.location.pathname.includes(b.text.toLowerCase())){
                return (
                    <div className= "mt-0" key = {b.text}>
                    <Link className="green-link mt-0 bold-text" style={sidebarStyles.sidebarLink} key= {b.text} to={b.url}>{b.text}</Link>
                    </div>
                );
            } else {
                return (
                    <div className= "mt-0" key = {b.text}>
                    <Link className="white-link mt-0" style={sidebarStyles.sidebarLink} key= {b.text} to={b.url}>{b.text}</Link>
                    </div>
                );
            }
        });

        const renderedManagementLinks = this.state.managementLinks.map(b => {
            ////console.log('map b.text: ' + b.text)
            //console.log('b.url: ' + b.url)
            //console.log('window.location.pathname.toString().test(b.url): ' + window.location.pathname.includes(b.text.toLowerCase()))
            if (window.location.pathname.includes(b.text.toLowerCase())){
                return (
                    <div className= "mt-0" key = {b.text}>
                    <Link className="green-link mt-0 bold-text" style={sidebarStyles.sidebarLink} key= {b.text} to={b.url}>{b.text}</Link>
                    </div>
                );
            } else {
                return (
                    <div className= "mt-0" key = {b.text}>
                    <Link className="white-link mt-0" style={sidebarStyles.sidebarLink} key= {b.text} to={b.url}>{b.text}</Link>
                    </div>
                );
            }
        });


        const thumbs = (
            <Button block disabled className='transparent-button' as = {Link} to = {'/profile'}>
                <Container fluid className=' p-3 my-0'>
                    <Row className="mx-2 align-self-center ">
                        <div style={sidebarStyles.thumb} className="mx-2 my-0">
                            <Avatar round={true} size="35" name = {this.state.firstName + ' ' + this.state.lastName} />
                        </div>
                        
                        <div className="mb-0 pl-2 pb-0 align-self-center text-left" >
                            <p className ="align-left noto-sans blue-grey-text caption-small bold-text py-0 my-0"><b>{this.state.firstName} {this.state.lastName}</b></p>
                            <p className ="blue-grey-text caption-smaller py-0 my-0">System Administrator</p>
                        </div>
                        
                    </Row>
                </Container>
            </Button>
        );

        // Set sidebar content to be a consumer of selectedOrgContext so that it can change the selected org.
        const sidebarContent = (
            <selectedOrgContext.Consumer>
                {({ selectedOrg, setSelectedOrg }) => (
                        <div style={sidebarStyles.container}>
                            <div ref={elem => {this.sidebarTop = elem}} style={sidebarStyles.content} className='px-5'>
                                <div>
                                    {/* <span className = "white-text noto-sans text-left" style={{fontSize: 2.5 + 'rem'}}><b>CAAS</b>ie</span> */}
                                    <Container className='pl-0 pb-5'>
                                    <img alt={"CAASie Logo"} style={{width: '100%', height: '100%'}}
                                        src="https://caasie.co/wp-content/uploads/2019/08/CAASie_White_Text_Only_190809.svg"
                                    />
                                    </Container>
                                </div>
                                <div className="mb-0 mt-3" >
                                    <p className ="noto-sans blue-grey-text caption-small bold-text"><b>QUICK LINKS</b></p>
                                    {renderedViewLinks}
                                </div>
                                <div className="mb-0 mt-5" >
                                    <p className ="noto-sans blue-grey-text caption-small bold-text"><b>MANAGEMENT</b></p>
                                    {renderedManagementLinks}
                                </div>

                            </div>
                            
                            <Container fluid ref={elem => {this.sidebarBottom = elem}} className='mt-auto fixed-bottom px-0 mx-0'>
                                <Container>
                                <Button className = 'my-3 py-2' block disabled onClick={() => {
                                                let url = '/newcampaign/';
                                                this.props.history.push({
                                                    pathname: url,
                                                    state: { org: this.state.orgList[this.state.selectedOrgIndex]._id, team: this.state.teamID }
                                                })
                                            }}>{this.state.orgLinks[this.state.selectedOrgIndex] ? 'NEW CAMPAIGN' : 'Loading...' }<FontAwesomeIcon className="ml-3" icon={faPlusCircle} /></Button>
                                </Container>
                                
                                <div  className="px-0 mx-0 my-0 py-0 darkest-background">
                                    <div className='mt-0'>
                                            {thumbs}
                                    </div>
                                </div>

                            </Container>
                        </div>
                )}
            </selectedOrgContext.Consumer>
        )



            const sidebarProps = {
            sidebar: sidebarContent,
            styles: sidebarStyles.sidebar,
            // children: this.props.children,
            docked: this.state.docked,
            sidebarClassName: "custom-sidebar-class",
            contentId: "custom-sidebar-content-id",
            open: this.state.open,
            touch: this.state.touch,
            shadow: this.state.shadow,
            pullRight: this.state.pullRight,
            touchHandleWidth: this.state.touchHandleWidth,
            dragToggleDistance: this.state.dragToggleDistance,
            transitions: this.state.transitions,
            onSetOpen: this.onSetOpen
            };


        return (
            <sidebarCollapseContext.Provider value={{
                sidebarCollapsed: this.state.docked,
                toggleSidebar: this.menuButtonClick
              }}>
            <Sidebar {...sidebarProps} props={{userFirstName: this.state.firstName, userLastName: this.state.lastName}}>
                <Spring
                    from={{ opacity: 0, transform: 'translate3d(0,-40px,0)' }}
                    to={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}>
                    {props => 
                        <animated.div style={{...props, position: "absolute", height: "100%", width: "100%"}}>
                            {this.props.children}
                        </animated.div>
                    }
                </Spring>
            </Sidebar>
            </sidebarCollapseContext.Provider>   
        )  
    }

}   // end Sidebar
CAASieSidebar.contextType = selectedOrgContext


// Export the sidebar
export default withRouter(CAASieSidebar)

