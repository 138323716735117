
// THIS COMPONENT USES HOOKS

// Component to get and display publisher list from Reach API

// Usage: <PublisherSelector onSelect = callback(selectedPublisher) />


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';    // Allows to link to different views?
import { Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import fetch from './_AdminCustomFetch'
import {Container, Jumbotron, Form, Button, Row, Col, Card, CardGroup, CardColumns, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import queryString from "query-string";
import InputLabel from '@material-ui/core/InputLabel';
import ReactTable from 'react-table'
import LoadingAnimation from './_AdminLoadingAnimation'
import FetchFailHandler from './_AdminFetchFailHandler'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios'
import Scrollbar from 'react-scrollbars-custom'
import "../styles/style.css"

import cogoToast from 'cogo-toast';

export default function GetPublishersFromAPI(props){

    // State variables
    // ..
    const [reachAuthToken, setReachAuthToken] = useState();
    const [publishers, setPublishers] = useState();
    const [isLoaded, setIsLoaded] = useState(false)
    const [nextURL, setNextURL] = useState()
    const [selectedPublisher, setSelectedPublisher] = useState({
        id: '',
        name: '',
    });
    
    
    // Effects
    useEffect(() => {
        // Get the token
        if (!reachAuthToken) {
            getToken();
        }
    }, []) // On mount


    // On token change
    useEffect(() => {
        if (reachAuthToken && !publishers) { 
            // get the publishers
            console.log('getting publishers')
            getPublishers();
        }
    }, [reachAuthToken])


    // Use effect when nextURL changes
    useEffect(() => {
        if (nextURL) {
            console.log('got next URL: ' + nextURL)
            getPublishers()
        }
    }, [nextURL])

    // When selected publisher changes
    useEffect(() => {
        // On change
        console.log('changed selected publisher: ' + JSON.stringify(selectedPublisher.name))

        // Now perform the callback
        if (props && props.onSelect) props.onSelect(selectedPublisher)
        
    }, [selectedPublisher.id])


    // --------------------------------- GET FUNCTIONS ---------------------------------- //

    // Get publishers from API
        var getPublishers = async () => {
            var fetchStatus = 0;
            await axios({
                method: 'get',
                url: nextURL ? nextURL : 'https://servssp.broadsign.com/api/publishers/?page_size=100', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + reachAuthToken
                }
            }).then(res => {
                fetchStatus = res.status
                return res.data;
            }).then(async res => {
                // Update our state with the new data points.
                var newPublishers = res.results

                await setPublishers(newPublishers)

                if (publishers) {
                    newPublishers = await newPublishers.concat(publishers)
                }

                console.log('newPublishers: ' + JSON.stringify(newPublishers))
               
                if (newPublishers !== publishers) {
                    console.log('set new publishers')
                    await setPublishers(newPublishers)
                }
                
                
                // If there are more boards to get, fetch them.
                if (res.next){
                    console.log('more publishers to fetch...')
                    return setNextURL(res.next)
                } else {
                    return setIsLoaded(true)
                }
                    
            }).catch(err => {
                console.error(err)
            })
        }

    // Get token from CAASie API
        var getToken = async () => {
            var fetchStatus = 0;
            await fetch('/api/boards/reach/authtoken', {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json'
                }
            }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                // Set the state
                if (res.token) {
                    console.log('Token received: ' + res.token)
                    setReachAuthToken(res.token)
                } else {
                    console.error('Failed to get a token')
                }
            })
        }

    // --------------------------------- GET FUNCTIONS ---------------------------------- //


    // --------------------------------- HANDLER FUNCTIONS ---------------------------------- //


    // Handle board checkbox change
    var handlePublisherSelectorChange = async (e) => {
        // ..
        var publisher = publishers.find(elem => {
            return elem.id === e.target.value
        })

        if (publisher) {
            await setSelectedPublisher({
                id: e.target.value,
                name: publisher.name
            }) 
        }
        
    }




    // --------------------------------- HANDLER FUNCTIONS ---------------------------------- //



    // --------------------------------- RENDER FUNCTIONS ---------------------------------- //
        var renderDropdown = () => {
            // Render a dropdown box
            if(isLoaded) {
                ////console.log('state loaded...mapping organisations: ' + JSON.stringify(this.state.orgList))
                return (
                    publishers.map(publisher => (
                        <MenuItem key = {publisher.id} value = {publisher.id}>
                            {publisher.name}
                        </MenuItem>
                    ))

                )
            } else {
                return <LoadingAnimation />
            }
        }
    // --------------------------------- RENDER FUNCTIONS ---------------------------------- //


    // Return/render
        return (
            <FormControl variant="filled" className='custom-select-dropdown px-2'>
                <InputLabel htmlFor="pub">Choose a Media Owner</InputLabel>
                <Select 
                    variant="filled"
                    className='custom-select-dropdown'
                    value={selectedPublisher.id}
                    onChange={(e) => handlePublisherSelectorChange(e)}
                    inputProps={{
                        name: 'Choose Publisher',
                        id: 'pub',
                    }}
                    >
                    {renderDropdown()}

                </Select>
            </FormControl>
        )

}