
// THIS COMPONENT USES HOOKS

// Component to get boards from a remote server via API call


import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';    // Allows to link to different views?
import { Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import fetch from './_AdminCustomFetch'
import {Container, Jumbotron, Form, Button, Row, Col, Card, CardGroup, CardColumns, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import queryString from "query-string";
import ReactTable from 'react-table'
import LoadingAnimation from './_AdminLoadingAnimation'
import FetchFailHandler from './_AdminFetchFailHandler'
import axios from 'axios'
import Scrollbar from 'react-scrollbars-custom'
import "../styles/style.css"

import cogoToast from 'cogo-toast';

export default function GetBoardsFromAPI(props){

    // State variables
    // ..
    const [reachAuthToken, setReachAuthToken] = useState();
    const [boards, setBoards] = useState();
    const [CAASieBoardDictionary, setCAASieBoardDictionary] = useState();
    const [selectedPublisher, setSelectedPublisher] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [boardCheckboxes, setBoardCheckboxes] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const [nextURL, setNextURL] = useState()
    
    
    // Effects
    useEffect(() => {
        // Get the token
        if (!reachAuthToken) {
            getToken();
        }
    }, []) // On mount


    // On props change
    useEffect(() => {
        if (props.selectedPublisher !== selectedPublisher) {
            setSelectedPublisher(props.selectedPublisher)
        }
    }, [props])

    // On CAASieBoardDictionary Change
    useEffect(() => {
        // Re render
        console.log('CAASieBoardDictionary updated')
    }, [CAASieBoardDictionary])


    // On token change
    useEffect(() => {
        if (reachAuthToken && !boards && props.selectedPublisher) { 
            // get the boards
            console.log('getting boards')
            getBoards();
        }
    }, [reachAuthToken, props])


    // On board change
    useEffect(() => {
        if (boards && boards.length > 0) { 
            // console.log('boards: ' + JSON.stringify(boards))
            console.log('CAASieBoardDictionary: ' + JSON.stringify(CAASieBoardDictionary))
            if (CAASieBoardDictionary) console.log('CAASieBoardDictionary length: ' + Object.keys(CAASieBoardDictionary).length)
            // Set the page count
            setPageCount(Math.ceil(boards.length/pageSize))
            // Generate the checkboxes
            generateCheckboxes(boards);
            // findMatchingCAASieBoards()
        }
    }, [boards])

    
    useEffect(() => {
        // If the checkboxes are set
        console.log('boardCheckboxes: ' + JSON.stringify(boardCheckboxes))
        if (boards) console.log('boards.length: ' + boards.length)
        if (boardCheckboxes) console.log('boardCheckboxes.length: ' + Object.keys(boardCheckboxes).length)
        
        if (boardCheckboxes && (Object.keys(boardCheckboxes).length === boards.length) ) {
            setIsLoaded(true)
        } else {
            setIsLoaded(false)
        }
    }, [boardCheckboxes])


    // Use effect when nextURL changes
    useEffect(() => {
        if (nextURL) {
            console.log('got next URL: ' + nextURL)
            getBoards()
        } else if (nextURL === null) {
            console.log('nextURL is null')
            findMatchingCAASieBoards()
        }
    }, [nextURL])


    // --------------------------------- GET FUNCTIONS ---------------------------------- //

    // Get boards from API
        var getBoards = async () => {
            var fetchStatus = 0;
            await axios({
                method: 'get',
                url: nextURL ? nextURL : `https://servssp.broadsign.com/api/screens/?page_size=100&query=${selectedPublisher.name}`, 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + reachAuthToken
                }
            }).then(res => {
                fetchStatus = res.status
                return res.data;
            }).then(async res => {
                // Update our state with the new data points.
                var newBoards = res.results
                
                if (boards) {
                    newBoards = await newBoards.concat(boards)
                }
                
                
               
                if (newBoards !== boards) {
                    console.log('set new boards')
                    await setBoards(newBoards)
                }
                
                
                // If there are more boards to get, fetch them.
                if (res.next){
                    console.log('more boards to fetch...')
                    return setNextURL(res.next)
                } else if (res.next === null) {
                    return setNextURL(null)
                }
            }).catch(err => {
                console.error(err)
            })
        }

    // Get token from CAASie API
        var getToken = async () => {
            var fetchStatus = 0;
            await fetch('/api/boards/reach/authtoken', {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json'
                }
            }).then(res => {
                fetchStatus = res.status;
                return res.json();
            }).then(res => {
                // Set the state
                if (res.token) {
                    console.log('Token received: ' + res.token)
                    setReachAuthToken(res.token)
                } else {
                    console.error('Failed to get a token')
                }
            })
        }


    // Check if board exists in the database already
        var findMatchingCAASieBoards = async (device_id) => {
            var NewCAASieBoardDictionary = CAASieBoardDictionary ? CAASieBoardDictionary : {}
            console.log('finding matching CAASie Boards')
            if (!device_id){
                await Promise.all(boards.map(async board => {
                    // If  the board does NOT have a match in CAASieBoardDictionary
                    if (!NewCAASieBoardDictionary[board._id]) {
                        return await fetch('/api/boards/findbydeviceid/' + board.device_id, {
                            method: 'GET',
                            headers: {
                                'Content-Type' : 'application/json'
                            }
                        }).then(res => {
                            return res.json();
                        }).then(res => {
                            if (res.boards) {
                                // The board exists
                                if (board.device_id === 'broadsign.com:120460829') console.log('board exists')
                                
                                // NewCAASieBoardDictionary[res.boards[0].device_id] = res.boards[0]._id;

                                NewCAASieBoardDictionary = {...NewCAASieBoardDictionary, [res.boards[0].device_id] : res.boards[0]._id}
                                
                            }
                        })   
                    } else {
                        // A record already exists for this board in CAASieBoardDictionary
                        return 
                    }
                    
                }))

                await setCAASieBoardDictionary(NewCAASieBoardDictionary)
            } else {
                // Device ID was provided, update the single board
                await fetch('/api/boards/findbydeviceid/' + device_id, {
                    method: 'GET',
                    headers: {
                        'Content-Type' : 'application/json'
                    }
                }).then(res => {
                    return res.json();
                }).then(res => {
                    if (res.boards) {
                        // The board exists
                        console.log('board exists')
                        // NewCAASieBoardDictionary[device_id] = res.boards[0]._id;

                        NewCAASieBoardDictionary = {...NewCAASieBoardDictionary, [device_id] : res.boards[0]._id}
                        setCAASieBoardDictionary(NewCAASieBoardDictionary)
                    }
                })
            }
            

            console.log('matching CAASie Boards: '+ JSON.stringify(NewCAASieBoardDictionary))
            // Now update the state
            return await setCAASieBoardDictionary(NewCAASieBoardDictionary)
            

        }
    // --------------------------------- GET FUNCTIONS ---------------------------------- //


    // --------------------------------- HANDLER FUNCTIONS ---------------------------------- //

    var generateCheckboxes = async (inputBoards) => {
         // Set the checkboxes
         let checkboxes = await inputBoards.reduce(
            (options, option) => ({
            ...options,
            [option.device_id]: false
            }),
            {}
        )
        // Now set the checkboxes and return
        return setBoardCheckboxes(checkboxes)
    }


    // Handle board checkbox change
    var handleBoardCheckboxChange = () => {
        // ..
    }


    // Handle adding to CAASie
    var handleAddToCAASie = async (deviceID) => {
        // Add a board to CAASie from the deviceID alone.
        // Determine the reach board ID
        var boardToAdd = boards.find(board => {
            return board.device_id === deviceID
        })

        if (boardToAdd) {
           var fetchStatus = 0;
        await fetch('/api/boards/create', {
            method: 'POST',
            body: JSON.stringify({
                    device_id: deviceID ? deviceID : '',
                    reachBoardID: boardToAdd ? boardToAdd.id : ''
                }
            ), 
            headers: {
              'Content-Type': 'application/json'
            }
        }).then(res => {
            fetchStatus = res.status;
            return res.json();
        }).then(res => {
            // Now that we've created boards, get boards
            console.log('created a board... ' + res.board._id)
            // Update dictionary
            findMatchingCAASieBoards(res.board.device_id)
            
        }).catch(err => {
            console.log('fetch error: ' + err)
            FetchFailHandler(this.props, this.state, fetchStatus)
        }) 
        }

        
    }

    // --------------------------------- HANDLER FUNCTIONS ---------------------------------- //



    // --------------------------------- RENDER FUNCTIONS ---------------------------------- //
        var renderTable = () => {
            // Render a table of boards
                        // Use react-table to render campaignTableData
            // Tables to render with reactTable
            //console.log('campaignDataArray: ' + JSON.stringify(this.state.campaignDataArray))
            if (isLoaded) {
                var boardTableRows = boards;
                var boardTableCols = [
                    {
                        Header: 'Select',
                        id: 'select',
                        maxWidth: 100,
                        Cell: (row) => (
                            <Form.Check 
                                custom = 'true'
                                type='checkbox'
                                checked={boardCheckboxes[row.original.device_id]}
                                onChange={handleBoardCheckboxChange}
                                name = {row.original.device_id}
                                key = {row.original.device_id}
                                id={row.original.device_id}
                                label=''
                            />
                        )
                    },
                    {
                        Header: () => (
                            <Container className = 'text-left pl-3'>
                                <span>Board Name</span>
                            </Container>
                          ),
                        accessor: 'name',
                        minWidth: 100,
                    },{
                        Header: () => (
                            <Container className = 'text-left pl-3'>
                                <span>Publisher</span>
                            </Container>
                          ),
                        accessor: 'publisher.name',
                    },
                    {
                        Header: 'Country',
                        accessor: 'geo.country.name',
                        maxWidth: 170,
                    },
                    {
                        Header: 'Manage',
                        id: 'manage', 
                        accessor: data => CAASieBoardDictionary && CAASieBoardDictionary[data.device_id] ? true : false,
                        Cell: row => (
                            <Container>
                                <Col>
                                    <Row className='mb-3'>
                                        {CAASieBoardDictionary ? 
                                            <Button disabled = { (CAASieBoardDictionary[row.original.device_id]) ? true : false} key = 'Add to CAASie'
                                                value={row.original.device_id}
                                                className='text-button-small noto-sans bold-text uppercase caption-smaller' 
                                                onClick={async (e) => {
                                                    // e.preventDefault();
                                                    console.log('adding to CAASie: ' + e.target.value)
                                                    await handleAddToCAASie(e.target.value)
                                                }}>{CAASieBoardDictionary[row.original.device_id] ? 
                                                    <>
                                                        Already on CAASie
                                                    </> : 
                                                    <>
                                                        Add to CAASie <FontAwesomeIcon icon={faPlusCircle} className='ml-3' />
                                                    </>}
                                                
                                            </Button>
                                       : 'Loading...' }
                                    </Row>
                                </Col>
                                
                            </Container>
                        )
                    }
                    
                ]
    
                return (
                    <Container fluid>
                        <ReactTable
                            data={boardTableRows}
                            columns={boardTableCols}
                            defaultPageSize={5}
                            minRows = {(boards.length > 0) ? 0 : 3 }
                            noDataText = "You haven't added any boards yet!"
                            defaultSorted={[
                                {
                                  id: "geo.country.name",
                                  desc: true
                                }
                              ]}
                        />
                    </Container>
                    )
            } else {
                return <LoadingAnimation />
            }

        
            


        }
    // --------------------------------- RENDER FUNCTIONS ---------------------------------- //


    // Return/render
        return renderTable()

}