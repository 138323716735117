// The loading animation component


// Usage: <LoadingAnimation variant='primary'/> // accepts primary or secondary

// Import dependencies
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';    // Allows to link to different views?
import Sidebar from '../components/_AdminSidebar'
import { withRouter } from 'react-router-dom';
import FetchFailHandler from './_AdminFetchFailHandler'
import "../styles/style.css"
import fetch from './_AdminCustomFetch'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get } from 'http';
import {Container, Form, Jumbotron, Button, Row, Col, Card, CardGroup, Spinner} from 'react-bootstrap';
import Moment from 'react-moment';
import cogoToast from 'cogo-toast'

import "../styles/style.css"

class LoadingAnimation extends Component {
    constructor(props){
        super(props)

        this.state = {
            variant: 'primary'
        }
    }   // end constructor

    // Component did mount
    componentDidMount = () => {
        // Get props
        this.setState({
            variant: this.props.variant ? this.props.variant : 'primary'
        })
    }

    // Render
    render = () => {
        return (
            <Container fluid>
                <Row>
                    <Spinner className={(this.state.variant === 'primary') ? 'mx-auto spinner-primary' : 'mx-auto spinner-secondary'} animation="grow" role="status" variant={this.state.variant}>
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                </Row>
            </Container>
        )
    }
}

export default LoadingAnimation